import axios from 'axios';
import { createAction } from 'redux-actions';
import { setLayoutAction, setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { fetchCurrentUserInfoAction } from 'modules/app';
import { appCurrentUserIdSelector, appLangSelector } from 'modules/app/selectors';
import { permissionsTenantsHashSelector } from 'modules/permissions/selectors';
import { _keyBy, _omit } from '@utiligize/shared/utils';
import { sortPermissions, getStorageItem } from 'utils';
import { PaginationType, DateFormats, StorageKeys, AssetLifeAPI } from 'constants/index';

const baseUrl = '/api/admin/v1/secure/permissions';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPermissionsGroupsAction: any = createAction(
  'permissions/FETCH_GROUPS',
  async (
    { skipPagination, skipFilters }: { skipPagination: boolean; skipFilters: boolean } = {
      skipPagination: false,
      skipFilters: false,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Permissions.Root, 'groupsCount' | 'groupsHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.PERMISSIONS_GROUPS)(
        state
      );
      return axios
        .get(`${baseUrl}/groups`, {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipFilters ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          groupsCount: res.data.count,
          groupsHash: _keyBy(res.data.rows, (item: Permissions.Group) => `_${item.id}_`),
        }));
    }
);

export const createPermissionsGroupAction = createAction(
  'permissions/CREATE_GROUP',
  (data: Omit<Permissions.Group, 'id'>) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios
        .post(`${baseUrl}/groups`, {
          name: data.name,
          groupPermissions: data.permissionsTypes,
          isDefault: data.isDefault,
        })
        .then(async () => {
          await dispatch(fetchPermissionsGroupsAction());
          dispatch(setSuccessToastAction('Group has been created'));
        });
  }
);

export const editPermissionsGroupAction = createAction(
  'permissions/EDIT_GROUP',
  (id: number, data: Omit<Permissions.Group, 'id'>) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios
        .put(`${baseUrl}/groups/${id}`, {
          name: data.name,
          groupPermissions: data.permissionsTypes,
          isDefault: data.isDefault,
        })
        .then(async () => {
          await dispatch(fetchPermissionsGroupsAction());
          dispatch(setSuccessToastAction('Group has been saved'));
        });
  }
);

export const patchPermissionsGroupAction: any = createAction(
  'permissions/PATCH_GROUP',
  (id: number, isDefault: boolean) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.patch(`${baseUrl}/groups/${id}`, { isDefault }).then(async () => {
        await dispatch(fetchPermissionsGroupsAction());
        dispatch(setSuccessToastAction('Default group has been updated'));
      });
    }
);

export const deletePermissionsGroupAction = createAction('permissions/DELETE_GROUP', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`${baseUrl}/groups/${id}`).then(async res => {
      await dispatch(fetchPermissionsGroupsAction());
      dispatch(setSuccessToastAction('Group has been deleted'));
    });
});

export const fetchUsersAction = createAction(
  'permissions/FETCH_USERS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Permissions.Root, 'usersCount' | 'usersHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.PERMISSIONS_USERS)(state);
      return axios
        .get('/api/admin/v2/secure/permissions/users', {
          params: {
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          usersCount: res.data.count,
          usersHash: _keyBy(res.data.rows, (item: Users.User) => `_${item.id}_`),
        }));
    }
);

export const createUserAction = createAction('permissions/CREATE_USER', (data: Users.CreateEditPayload) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.post('/api/admin/v2/secure/permissions/users', data).then(async res => {
      await dispatch(fetchUsersAction());
      dispatch(setSuccessToastAction('User has been created'));
      dispatch(setLayoutAction({ createUserOneTimePassword: res.data.password }));
    });
  };
});

export const editUserAction = createAction('permissions/EDIT_USER', (id: number, data: Users.CreateEditPayload) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
    const state: State.Root = getState();
    const appCurrentUserId = appCurrentUserIdSelector(state);
    return axios.put(`/api/admin/v2/secure/permissions/users/${id}`, data).then(async () => {
      if (appCurrentUserId === id) {
        await dispatch(fetchCurrentUserInfoAction());
      }
      await dispatch(fetchUsersAction());
      dispatch(setSuccessToastAction('User has been saved'));
    });
  };
});

export const deleteUserAction = createAction('permissions/DELETE_USER', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`/api/admin/v2/secure/permissions/users/${id}`).then(async res => {
      await dispatch(fetchUsersAction());
      dispatch(setSuccessToastAction('User has been deleted'));
    });
});

export const fetchPermissionsTypesAction = createAction('permissions/FETCH_TYPES', async (): Promise<any> => {
  return axios.get(`${baseUrl}/types`).then((res: any) => ({
    permissionsTypes: res.data
      .map((i: { id: number; key: string; description: string }) => i.key)
      .sort(sortPermissions),
  }));
});

export const fetchAccessLogsAction: any = createAction(
  'permissions/FETCH_ACCESS_LOGS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Permissions.Root, 'accessLogsCount' | 'accessLogs'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_ACCESS_LOGS
      )(state);
      return AssetLifeAPI.get('admin/access_stats', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          start_time: filters?.startTime!.format(DateFormats.SERVER),
          end_time: filters?.endTime!.format(DateFormats.SERVER),
        },
      }).then((res: any) => ({
        accessLogsCount: res.data.count,
        accessLogs: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchPermissionsTenantsAction: any = createAction(
  'permissions/FETCH_TENANTS',
  async (
    { skipPagination, skipFilters }: { skipPagination: boolean; skipFilters: boolean } = {
      skipPagination: false,
      skipFilters: false,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Permissions.Root, 'tenantsCount' | 'tenantsHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.PERMISSIONS_TENANTS)(
        state
      );
      return axios
        .get('/api/admin/v1/secure/supervisor/permissions/types/tenants', {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipFilters ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          tenantsCount: res.data.count,
          tenantsHash: _keyBy(res.data.rows, (item: Permissions.Tenant) => `_${item.id}_`),
        }));
    }
);

export const fetchPermissionsFeaturesAction: any = createAction(
  'permissions/FETCH_FEATURES',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Permissions.Root, 'featuresCount' | 'featuresHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.PERMISSIONS_FEATURES)(
        state
      );
      return axios
        .get('/api/admin/v1/secure/supervisor/permissions/types', {
          params: {
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          featuresCount: res.data.length,
          featuresHash: _keyBy(
            res.data.rows.sort((a: Permissions.Feature, b: Permissions.Feature) => sortPermissions(a.key, b.key)),
            (item: Permissions.Feature) => `_${item.id}_`
          ) as any,
        }));
    }
);

export const editPermissionsTenantAction = createAction(
  'permissions/EDIT_TENANT',
  (id: number, tenantName: string, permissions: Permissions.Permissions) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> => {
      const selectedTenant = getStorageItem(StorageKeys.SELECTED_TENANT);
      return axios
        .put(`/api/admin/v1/secure/supervisor/permissions/types/tenants/${id}`, permissions)
        .then(async () => {
          // Refresh types and user info if permissions have been changed for selected tenant
          if (selectedTenant === tenantName) {
            await dispatch(fetchPermissionsTypesAction());
            await dispatch(fetchCurrentUserInfoAction());
          }
          await dispatch(fetchPermissionsTenantsAction());
          dispatch(setSuccessToastAction('Tenant permissions has been saved'));
        });
    };
  }
);

export const editPermissionsFeatureAction = createAction(
  'permissions/EDIT_FEATURE',
  (id: number, data: { description: string; tenantsIds: number[] }) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const selectedTenant = (getStorageItem(StorageKeys.SELECTED_TENANT) as string) || '';
      const state: State.Root = getState();
      const tenantsHash = permissionsTenantsHashSelector(state);
      const tenants = data.tenantsIds.map(id => _omit(tenantsHash[`_${id}_`], 'permissionsTypes'));

      return axios
        .patch(`/api/admin/v1/secure/supervisor/permissions/types/${id}`, { description: data.description, tenants })
        .then(async () => {
          // Refresh types and user info if permissions have been changed for selected tenant
          if (tenants.map(tenant => tenant.tenantName).includes(selectedTenant) || !tenants.length) {
            await dispatch(fetchPermissionsTypesAction());
            await dispatch(fetchCurrentUserInfoAction());
          }
          await dispatch(fetchPermissionsFeaturesAction());
          dispatch(setSuccessToastAction('Permissions feature has been saved'));
        });
    };
  }
);
