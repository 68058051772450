import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';
import { Colors } from 'components/_charts/Chart';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchMaintenancePlansAction: any = createAction('maintenance/FETCH_PLANS', async () => {
  return (): Promise<
    Pick<Maintenance.Root, 'maintenancePlansCount' | 'maintenancePlansHash' | 'maintenancePlansFetched'>
  > => {
    return AssetLifeAPI.get('maintenance/plan_maintenance_settings').then((res: any) => ({
      maintenancePlansCount: res.data.length,
      maintenancePlansHash: _keyBy(res.data, (item: Maintenance.Plan) => `_${item.id}_`),
      maintenancePlansFetched: true,
    }));
  };
});

export const createMaintenancePlanAction: any = createAction(
  'maintenance/CREATE_MAINTENANCE_PLAN',
  (data: Omit<Maintenance.Plan, 'id'>) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.post('maintenance/plan_maintenance_settings', data).then(async () => {
        await dispatch(fetchMaintenancePlansAction());
        dispatch(setSuccessToastAction('Maintenance plan has been created'));
      });
    }
);

export const updateMaintenancePlanAction: any = createAction(
  'maintenance/UPDATE_MAINTENANCE_PLAN',
  (id: number, data: Maintenance.Plan) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put(`maintenance/plan_maintenance_settings/${id}`, data).then(async () => {
        await dispatch(fetchMaintenancePlansAction());
        dispatch(setSuccessToastAction('Maintenance plan has been updated'));
      });
    }
);

export const deleteMaintenancePlanAction: any = createAction(
  'maintenance/DELETE_MAINTENANCE_PLAN',
  async (id: string) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      AssetLifeAPI.delete(`maintenance/plan_maintenance_settings/${id}`).then(async () => {
        await dispatch(fetchMaintenancePlansAction());
        dispatch(setSuccessToastAction('Maintenance plan has been deleted'));
      });
  }
);

export const fetchMaintenanceSummaryAction: any = createAction(
  'maintenance/FETCH_SUMMARY',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Maintenance.Root, 'maintenanceSummaryCount' | 'maintenanceSummaryHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.MAINTENANCE_SUMMARY
      )(state);
      return AssetLifeAPI.get('maintenance/plan_maintenance_summary', {
        params: {
          limit,
          offset,
          sort,
          column,
          query,
          lang: appLangSelector(state).toLowerCase(),
          year: filters?.year,
        },
      }).then((res: any) => ({
        maintenanceSummaryCount: res.data.count,
        maintenanceSummaryHash: _keyBy(res.data.rows, (item: Maintenance.Summary) => `_${item.id}_`),
      }));
    }
);

export const fetchMaintenanceDetailedPlanAction: any = createAction(
  'maintenance/FETCH_DETAILED_PLAN',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Maintenance.Root, 'maintenanceDetailedPlanCount' | 'maintenanceDetailedPlanHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.MAINTENANCE_PLAN
      )(state);
      return AssetLifeAPI.get('maintenance/plan_maintenance', {
        params: {
          limit,
          offset,
          sort,
          column,
          query,
          lang: appLangSelector(state).toLowerCase(),
          year: filters?.year,
          asset_category: filters?.assetcategoryCode,
        },
      }).then((res: any) => ({
        maintenanceDetailedPlanCount: res.data.count,
        maintenanceDetailedPlanHash: _keyBy(res.data.rows, (item: Maintenance.DetailedPlan) => `_${item.id}_`),
      }));
    }
);

export const createMaintenanceDetailedPlanAction: any = createAction(
  'maintenance/POST_DETAILED_PLAN',
  (data: { planName: string; overrideYear: number }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.post(`maintenance/plan_maintenance`, data).then(async () => {
        await dispatch(fetchMaintenanceDetailedPlanAction());
        dispatch(setSuccessToastAction('Maintenance detailed plan has been created'));
      });
    }
);

export const updateMaintenanceDetailedPlanItemAction: any = createAction(
  'maintenance/PUT_DETAILED_PLAN',
  async (id: number, overrideYear: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put(`maintenance/plan_maintenance/${id}`, { overrideYear }).then(async () => {
        await dispatch(fetchMaintenanceDetailedPlanAction());
        dispatch(setSuccessToastAction('Maintenance detailed plan data has been saved'));
      });
    }
);

export const recalculateMaintenancePlanAction: any = createAction(
  'maintenance/RECALCULATE_PLAN',
  async () => (): Promise<void> => AssetLifeAPI.put('maintenance/plan_maintenance_recalculate')
);

export const fetchPlanCNAIMAction: any = createAction(
  'maintenance/FETCH_PLAN_CNAIM',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Maintenance.Root, 'maintenancePlanCNAIMCount' | 'maintenancePlanCNAIMHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.MAINTENANCE_PLAN_CNAIM
      )(state);
      return AssetLifeAPI.get('maintenance/maintenance_table', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          asset_category: filters?.reinvestmentAssetCategory,
          year: filters?.year,
        },
      }).then((res: any) => ({
        maintenancePlanCNAIMCount: res.data.count,
        maintenancePlanCNAIMHash: _keyBy(res.data.rows, (item: Maintenance.PlanCNAIM) => item.customer_id),
        skipStoreUpdate,
      }));
    }
);

export const getAverageTimeSpentByTransformersChartData = createAction(
  'maintenance/GET_AVERAGE_TIME_SPENT_BY_TRANSFORMERS_CHART_DATA',
  () => (): Promise<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null> => {
    return AssetLifeAPI.get('plots/average_time_spent_by_transformers').then(res => res.data);
  }
);

export const getAverageTimeSpentByCabinetsChartData = createAction(
  'maintenance/GET_AVERAGE_TIME_SPENT_BY_CABINETS_CHART_DATA',
  () => (): Promise<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null> => {
    return AssetLifeAPI.get('plots/average_time_spent_by_cabinets').then(res => res.data);
  }
);

export const fetchTimeByActionTypeChartDataAction = createAction(
  'maintenance/GET_TIME_BY_ACTION_TYPE_CHART_DATA',
  async () => (): Promise<Pick<Maintenance.Root, 'timeByActionTypeChartData'>> => {
    return AssetLifeAPI.get('plots/average_time_spent_by_action_type').then(
      (res: { data: Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> }) => {
        return {
          timeByActionTypeChartData: {
            title: res.data?.title || '',
            xAxisTitle: res.data?.xaxis_title || '',
            yAxisTitle: res.data?.yaxis_title || '',
            series:
              res.data?.series.map((i, index: number) => ({
                name: i.group || '',
                type: 'column' as any,
                data: i.data,
                color: Colors[index],
              })) || [],
            categories: res.data?.categories as Type.AssetCategories[],
          },
        };
      }
    );
  }
);

export const fetchConditionAssessmentChartDataAction = createAction(
  'maintenance/GET_CONDITION_ASSESSMENT_CHART_DATA',
  async () => (): Promise<Pick<Maintenance.Root, 'conditionAssessmentChartData'>> => {
    return AssetLifeAPI.get('plots/asset_condition_distribution').then(
      (res: { data: Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> }) => {
        return {
          conditionAssessmentChartData: {
            title: res.data?.title || '',
            xAxisTitle: res.data?.xaxis_title || '',
            yAxisTitle: res.data?.yaxis_title || '',
            series:
              res.data?.series.map((i, index: number) => ({
                name: i.group || '',
                type: 'column' as any,
                data: i.data,
                color: Colors[index],
              })) || [],
            categories: res.data?.categories as Type.AssetCategories[],
          },
        };
      }
    );
  }
);
