import React, { useMemo, useCallback, useEffect } from 'react';
import { FormSelect, FormSelectProps, FormSelectOption } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { manufacturersItemsSelector } from 'modules/manufacturers/selectors';
import { fetchManufacturersAction } from 'modules/manufacturers';

interface Props extends Omit<FormSelectProps, 'options' | 'labelKey'> {
  fullObject?: boolean;
  onChange: (e: React.SyntheticEvent, item?: Manufacturers.Item) => void;
}

const SelectFormManufacturers: React.FC<Props> = ({ fullObject = false, onChange, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const manufacturers = useSelector(manufacturersItemsSelector);

  useEffect(() => {
    dispatch(fetchManufacturersAction({ skipFilters: true, skipPagination: true }));
  }, [dispatch]);

  const manufacturersSelectOptions = useMemo(() => {
    return manufacturers.map(
      (manufacturer: Manufacturers.Item): FormSelectOption => ({ id: manufacturer.id, name: manufacturer.name })
    );
  }, [manufacturers]);

  const handleSelectChange = useCallback(
    (e: React.SyntheticEvent) => {
      if (!fullObject) return onChange(e);
      const manufacturer = manufacturers.find(
        manufacturer => manufacturer.id === Number((e.target as HTMLSelectElement).value)
      );
      if (!manufacturer) throw Error("FormManufacturersSelect can't find manufacturer object");
      return onChange(e, manufacturer);
    },
    [fullObject, manufacturers, onChange]
  );

  return (
    <FormSelect {...props} labelKey="Manufacturer" options={manufacturersSelectOptions} onChange={handleSelectChange} />
  );
};

export default SelectFormManufacturers;
