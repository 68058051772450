import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { TaskDeleteAlert } from 'components';
import { Button } from 'components/_common';
import { IconDelete } from '@utiligize/shared/resources';

interface Props {
  id: number;
  name: string;
  titleKey?: string;
  marginLeft?: boolean;
  action: (id: number) => Promise<void>;
}

const IconDeleteTableAction: React.FC<Props> = memo(({ id, name, action, marginLeft }) => {
  const dispatch = useDispatch();

  const handleDeleteBtnClick = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      dispatch(
        setModalConfirmationMetaAction({
          bodyComponent: <TaskDeleteAlert name={name} />,
          onConfirm: () => dispatch(action(id)),
        })
      );
      document.body.click(); // close TasksEmployeesGanttChart popover
    },
    [dispatch, id, name, action]
  );

  return (
    <Button
      icon={<IconDelete />}
      data-id={id}
      marginLeft={marginLeft}
      tooltipKey="Delete"
      onClick={handleDeleteBtnClick}
      size="small"
      aria-label="Delete"
    />
  );
});

export default IconDeleteTableAction;
