import axios from 'axios';
import { createAction } from 'redux-actions';
import { fetchEmployeesGanttChartAction, fetchTasksGanttChartAction } from 'modules/employees';
import { fetchUsersAction } from 'modules/users';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { appLangSelector, externalTimeSystemSelector } from 'modules/app/selectors';
import { tasksActiveTypeSelector } from 'modules/tasks/selectors';
import {
  isPageNewTasksTabActiveSelector,
  isPageTasksComplaintsTabActiveSelector,
  isPageTasksEmployeesGanttChartTabActiveSelector,
  isPageTasksTasksGanttChartTabActiveSelector,
  isRouteActiveSelectorFactory,
  isPageTaskDetailsActiveSelectorFactory,
} from 'modules/router/selectors';
import { fetchNewTasksAction } from './new-tasks.actions';
import { fetchTasksComplaintsAction } from './tasks-complaints.actions';
import { _keyBy } from '@utiligize/shared/utils';
import { getStaticFiltersMap, getGalleryImages } from 'utils';
import { PaginationType, TaskStatus, Routes, BuilderFormTypeNames } from 'constants/index';

export const fetchTasksAction: any = createAction(
  'tasks/FETCH_TASKS',
  async (
    {
      includeExternalTime,
      includeExpectedTimeFields,
      skipPagination,
      skipStoreUpdate,
    }: {
      includeExternalTime: boolean;
      includeExpectedTimeFields: boolean;
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = {
      includeExternalTime: true,
      includeExpectedTimeFields: true,
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<
      | Pick<Tasks.Root, 'mobileSubmittedTasksCount' | 'mobileSubmittedTasksHash'>
      | Pick<Tasks.Root, 'upcomingTasksCount' | 'upcomingTasksHash'>
      | Pick<Tasks.Root, 'tasksCount' | 'tasksHash'>
      | Pick<Tasks.Root, 'finishedTasksCount' | 'finishedTasksHash'>
    > => {
      const state = getState();
      const type = tasksActiveTypeSelector(state as never) as Type.PaginationType;
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(type)(state);
      const staticFilters = getStaticFiltersMap(type);
      return axios
        .get('api/admin/v2/secure/tasks', {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
            type: filters?.type && JSON.stringify(filters?.type),
            status: JSON.stringify(filters?.status ? [filters?.status] : staticFilters.status),
            year: filters?.year,
            users: filters?.userIds && JSON.stringify(filters?.userIds),
            appAction: filters?.appAction && JSON.stringify(filters?.appAction),
            onlyWithoutUsers: staticFilters.onlyWithoutUsers,
            includeExternalTime: externalTimeSystemSelector(state) && includeExternalTime,
            includeExpectedTimeFields,
          },
        })
        .then(res => {
          const tasksCount = res.data.count;
          const tasksHash = _keyBy(res.data.rows, (item: Tasks.Task) => `_${item.id}_`);
          switch (type) {
            case PaginationType.TASKS_MOBILE_SUBMITTED:
              return {
                mobileSubmittedTasksCount: tasksCount,
                mobileSubmittedTasksHash: tasksHash,
                skipStoreUpdate,
              };
            case PaginationType.UPCOMING_TASKS:
              return {
                upcomingTasksCount: tasksCount,
                upcomingTasksHash: tasksHash,
                skipStoreUpdate,
              };
            case PaginationType.TASKS_FINISHED:
              return {
                finishedTasksCount: tasksCount,
                finishedTasksHash: tasksHash,
                skipStoreUpdate,
              };
            default:
              return { tasksCount, tasksHash, skipStoreUpdate };
          }
        });
    }
);

export const fetchTasksByUUIDCodeAction: any = createAction(
  'tasks/FETCH_TASKS_BY_UUID_CODE',
  (
    { uuid, skipStoreUpdate, queryInput } = {
      uuid: '',
      skipStoreUpdate: undefined,
      queryInput: '',
    }
  ) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Tasks.Root, 'tasksByUUIDCode'>> => {
      const state = getState();
      const { sort, column } = paginationSelectorFactory(PaginationType.UNIFIED_ASSET_VIEW_TASKS)(state);
      return axios
        .get(`api/admin/v2/secure/assets/${uuid}/tasks`, {
          params: {
            limit: null,
            offset: 0,
            sort,
            column,
            query: queryInput || null,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then(res => ({
          tasksByUUIDCode: res.data.rows,
          skipStoreUpdate,
        }));
    }
);

export const fetchTaskDetailsAction = createAction(
  'tasks/FETCH_TASK',
  ({ id, portfolioId, versionId }: { id: number; portfolioId: number; versionId: number }) =>
    (): Promise<Pick<Tasks.Root, 'taskDetails'>> => {
      return axios.get(`api/admin/v2/secure/tasks/${id}`, { params: { portfolioId, versionId } }).then(res => {
        const init = {
          taskCompletionsFinalChecks: [],
          taskCompletionsInspections: [],
          taskCompletionsRelatedForms: [],
          taskCompletionsOther: [],
          images: [],
        };

        const taskCompletionsData = (res.data?.taskCompletions || []).reduce(
          (
            acc: {
              taskCompletionsFinalChecks: Tasks.TaskCompletion[];
              taskCompletionsInspections: Tasks.TaskCompletion[];
              taskCompletionsRelatedForms: Tasks.TaskCompletion[];
              taskCompletionsOther: Tasks.TaskCompletion[];
              images: Type.GalleryImage[];
            },
            item: Tasks.TaskCompletion
          ) => {
            if (item.answer?.answerAttachments?.length) {
              acc.images.push(...getGalleryImages(item.answer?.answerAttachments));
            }

            if ([BuilderFormTypeNames.FinalCheck].includes(item.answer?.formTypeName as Builder.FormTypeNames)) {
              acc.taskCompletionsFinalChecks.push(item);
            } else if (
              [BuilderFormTypeNames.TaskRelatedForm].includes(item.answer?.formTypeName as Builder.FormTypeNames)
            ) {
              acc.taskCompletionsRelatedForms.push(item);
            } else if ([BuilderFormTypeNames.Inspection].includes(item.answer?.formTypeName as Builder.FormTypeNames)) {
              acc.taskCompletionsInspections.push(item);
            } else {
              acc.taskCompletionsOther.push(item);
            }
            return acc;
          },
          init
        );

        const taskDetails = {
          ...res.data,
          usersAttachments: getGalleryImages(res.data.usersAttachments),
          ...taskCompletionsData,
        };

        return { taskDetails };
      });
    }
);

export const setTaskDetailsAction = createAction(
  'tasks/SET_TASK_DETAILS',
  (taskDetails: Tasks.Root['taskDetails']): Pick<Tasks.Root, 'taskDetails'> => ({ taskDetails })
);

export const createTaskAction: any = createAction('tasks/CREATE_TASK', (data: FormData) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> =>
    axios.post(`api/admin/v2/secure/tasks`, data).then(async () => {
      const state = getState();
      const isPageNewTasksTabActive = isPageNewTasksTabActiveSelector(state);
      const isPageTasksComplaintsTabActive = isPageTasksComplaintsTabActiveSelector(state);

      if (isPageNewTasksTabActive) {
        await dispatch(fetchNewTasksAction());
      } else if (isPageTasksComplaintsTabActive) {
        await dispatch(fetchTasksComplaintsAction());
      } else {
        const tasksPromise = dispatch(fetchTasksAction());
        // task user dropdown onlyIncludedInTask must be updated
        const usersPromise = dispatch(fetchUsersAction());
        await Promise.all([tasksPromise, usersPromise]);
      }
      dispatch(setSuccessToastAction('Task has been created'));
    });
});

export const editTaskAction: any = createAction('tasks/EDIT_TASK', (id: number, data: FormData) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
    const state = getState();
    const isPageTasksEmployeesGanttChartTabActive = isPageTasksEmployeesGanttChartTabActiveSelector(state);
    const isPageTasksTasksGanttChartTabActive = isPageTasksTasksGanttChartTabActiveSelector(state);
    const isPageTaskDetailsActive = isPageTaskDetailsActiveSelectorFactory(id)(state);
    return axios.put(`api/admin/v2/secure/tasks/${id}`, data).then(async () => {
      const tasksPromise = dispatch(
        (() => {
          switch (true) {
            case isPageTasksEmployeesGanttChartTabActive:
              return fetchEmployeesGanttChartAction;
            case isPageTasksTasksGanttChartTabActive:
              return fetchTasksGanttChartAction;
            case isPageTaskDetailsActive:
              return () => {
                const portfolioId = portfolioIdSelector(state);
                const versionId = simulationVersionIdSelector(state);
                if (!portfolioId || !versionId) return Promise.resolve();
                return fetchTaskDetailsAction({ id, portfolioId, versionId });
              };
            default:
              return fetchTasksAction;
          }
        })()()
      );
      // task user dropdown onlyIncludedInTask must be updated
      const usersPromise = dispatch(fetchUsersAction());
      await Promise.all([tasksPromise, usersPromise]);
      dispatch(setSuccessToastAction('Task has been saved'));
    });
  };
});

export const deleteTaskAction: any = createAction('tasks/DELETE_TASK', async (id: number) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
    const state = getState();
    const isPageTasksEmployeesGanttChartTabActive = isPageTasksEmployeesGanttChartTabActiveSelector(state);
    const isPageTasksTasksGanttChartTabActive = isPageTasksTasksGanttChartTabActiveSelector(state);
    const isPageMap = isRouteActiveSelectorFactory(Routes.Map, false)(state);
    return axios.delete(`api/admin/v2/secure/tasks/${id}`).then(async () => {
      const tasksPromise = dispatch(
        (() => {
          switch (true) {
            case isPageTasksEmployeesGanttChartTabActive:
              return fetchEmployeesGanttChartAction;
            case isPageTasksTasksGanttChartTabActive:
              return fetchTasksGanttChartAction;
            case isPageMap:
              return;
            default:
              return fetchTasksAction;
          }
        })()()
      );
      // task user dropdown onlyIncludedInTask must be updated
      const usersPromise = dispatch(fetchUsersAction());
      await Promise.all([tasksPromise, usersPromise].filter(Boolean));
      dispatch(setSuccessToastAction('Task has been deleted'));
    });
  };
});

export const reopenTaskAction: any = createAction('tasks/REOPEN_TASK', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.put(`api/admin/v2/secure/tasks/${id}/status`, { status: TaskStatus.Ongoing }).then(async () => {
      await dispatch(fetchTasksAction());
      dispatch(setSuccessToastAction('Task has been reopened'));
    });
});

export const deleteTaskAttachmentsAction: any = createAction(
  'tasks/DELETE_TASK_ATTACHMENT',
  async (id: number, ids: number[]) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.delete(`api/admin/v2/secure/tasks/${id}/attachments`, { params: { ids: ids.join() } }).then(async res => {
        dispatch(setSuccessToastAction('Selected photos has been deleted'));
      });
  }
);

type FetchTasksDepartmentsActionResponse = Pick<Tasks.Root, 'tasksDepartmentsHash' | 'tasksDepartmentsFetched'>;

export const fetchTasksDepartmentsAction = createAction(
  'tasks/FETCH_DEPARTMENTS',
  async () =>
    (dispatch: Shared.CustomDispatch): Promise<FetchTasksDepartmentsActionResponse> => {
      return axios.get(`api/admin/v1/secure/departments`).then(res => {
        return res.data.reduce(
          (
            acc: FetchTasksDepartmentsActionResponse,
            department: Omit<Tasks.Department, 'externalTasksHash'> & { externalTasks: Tasks.ExtRepeatedTask[] }
          ) => {
            acc.tasksDepartmentsHash[department.number] = {
              id: department.id,
              number: department.number,
              name: department.name,
              externalTasksHash: _keyBy(
                department.externalTasks,
                (item: Tasks.ExtRepeatedTask) => `_${item.taskNumber}_`
              ),
            };
            return acc;
          },
          {
            tasksDepartmentsHash: {},
            tasksDepartmentsFetched: true,
          }
        );
      });
    }
);
