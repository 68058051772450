import styled from 'styled-components';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { releaseNotificationModalVersionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';
import { currentAppReleaseVersionSelector } from 'modules/app/selectors';

const ModalReleaseNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const releaseNotificationModalVersion = useSelector(releaseNotificationModalVersionSelector);
  const currentAppReleaseVersion = useSelector(currentAppReleaseVersionSelector);
  const isShow = currentAppReleaseVersion > Number(releaseNotificationModalVersion);

  useEffect(() => {
    if (isShow) setShow(true);
  }, [isShow]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ releaseNotificationModalVersion: currentAppReleaseVersion })), 500);
  }, [dispatch, currentAppReleaseVersion]);

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      title={
        <span>
          &#127881; {getIntl("What's new in version {{version}}", { version: process.env.REACT_APP_VERSION! })}
        </span>
      }
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <>
        <p>{getIntl('Discover the latest enhancements and features in our web app')}:</p>
        <ReleaseNotesList>
          <figcaption>&#128640; {getIntl('Features')}:</figcaption>
          <ul>
            <li>
              "Analysis tool" - new page to compare data and results from multiple portfolios and scenarios for power
              users.
            </li>
          </ul>
        </ReleaseNotesList>
        <ReleaseNotesList>
          <figcaption>&#128736; {getIntl('Improvements')}:</figcaption>
          <ul>
            <li>"Simulations" - use and show the latest version for views where the selection is disabled.</li>
            <li>"Network loading &gt; Total loading" - show timeseries based on portfolio.</li>
            <li>
              "Network loading &gt; Customers" - show customers independently of the availability of ampere right data.
            </li>
            <li>"Setup, New load" - view page for scenarios without simulation.</li>
            <li>"Reinvestments &gt; Monetized risk matrix" - responsive design and style improvements.</li>
            <li>"Map" - Mapbox satellite view, 512px tile size.</li>
            <li>"Map &gt; UAV" - standardised power unit.</li>
          </ul>
        </ReleaseNotesList>
        {/* <ReleaseNotesList>
          <figcaption>&#128030; {getIntl('Fixes')}:</figcaption>
        </ReleaseNotesList> */}

        <p>{getIntl('Thank You for Your Collaboration')}! &#128578;</p>
        <p>
          {getIntl(
            'We appreciate your continued feedback, which help us improve and grow. Stay tuned for more exciting updates and features. Together, we’re making great things happen'
          )}
          !
        </p>
      </>
    </Modal>
  );
};

const ReleaseNotesList = styled.figure`
  margin-left: 20px;
  > ul {
    line-height: 2;
    margin-bottom: 0;
  }
`;

export default ModalReleaseNotification;
