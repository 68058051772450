import { createAction } from 'redux-actions';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { AssetLifeAPI, PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchN1ItemsAction: any = createAction(
  'n1/FETCH_ITEMS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<N1.Root, 'items' | 'itemsCount'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ itemsCount: 0, items: [] });
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.N1)(state);
      return AssetLifeAPI.get('n_1/table', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          outage_type_ids: filters?.outageType || null,
          primary_substation_ids: filters?.primarySubstationsIds?.join(',') || null,
          with_route: filters?.route === '' ? null : filters?.route,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
        },
      }).then((res: any) => ({
        itemsCount: res.data.count,
        items: res.data.rows,
        skipStoreUpdate,
      }));
    }
);
