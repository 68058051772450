import styled from 'styled-components';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isPageNetworkLoadingProfileTabActiveSelector } from 'modules/router/selectors';
import { ScenarioRunInfo } from 'components';
import { DataTableTemplate, DateRangePicker, Checkbox } from 'components/_common';
import TabsTotalLoading from './TabsTotalLoading';
import { DateFormats } from 'constants/index';

const ViewTotalLoading: React.FC = () => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [values, setValues] = useState({ startDate: moment().subtract(29, 'days'), endDate: moment() });

  const isProfileTabActive = useSelector(isPageNetworkLoadingProfileTabActiveSelector);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setShowAll(event.currentTarget.checked),
    []
  );

  const handleDateRangePickerApply = useCallback(
    (event: React.SyntheticEvent, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      setValues({ startDate: picker.startDate, endDate: picker.endDate });
    },
    []
  );

  return (
    <DataTableTemplate>
      <Row>
        <Col sm={9}>
          {!isProfileTabActive && (
            <Row>
              <StyledCol xs="auto">
                <Checkbox
                  labelKey="Show all"
                  className="icheck-primary"
                  checked={showAll}
                  onChange={handleCheckboxClick}
                />
              </StyledCol>
              {!showAll && (
                <Col xs="auto" style={{ minWidth: '300px' }}>
                  <DateRangePicker
                    startDate={values.startDate}
                    endDate={values.endDate}
                    onApply={handleDateRangePickerApply}
                    disabled={showAll}
                    isNew
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col sm={3} className="d-flex flex-column align-items-end">
          <ScenarioRunInfo />
        </Col>
      </Row>
      <TabsTotalLoading
        startDateStr={!showAll ? values.startDate.format(DateFormats.SERVER) : null}
        endDateStr={!showAll ? values.endDate.format(DateFormats.SERVER) : null}
      />
    </DataTableTemplate>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;

  > .icheck-primary {
    margin: 0 !important;
  }
`;

export default ViewTotalLoading;
