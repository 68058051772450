import moment from 'moment-timezone';
import styled from 'styled-components';
import React, { MutableRefObject } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { Spinner, ErrorBoundary } from '@utiligize/shared/components';
import theme from '@utiligize/shared/theme';

export type { HighchartsReactRefObject };

export enum ColorsMap {
  LIGHT_BLUE = '#7cb5ec',
  DARK_GRAY = '#434348',
  LIGHT_GREEN = '#90ed7d',
  LIGHT_ORANGE = '#f7a35c',
  LAVENDER_BLUE = '#8085e9',
  PINKISH_RED = '#f15c80',
  YELLOW = '#e4d354',
  TEAL = '#2b908f',
  SALMON_RED = '#f45b5b',
  AQUA = '#91e8e1',
}

export const Colors = [
  ColorsMap.LIGHT_BLUE,
  ColorsMap.DARK_GRAY,
  ColorsMap.LIGHT_GREEN,
  ColorsMap.LIGHT_ORANGE,
  ColorsMap.LAVENDER_BLUE,
  ColorsMap.PINKISH_RED,
  ColorsMap.YELLOW,
  ColorsMap.TEAL,
  ColorsMap.SALMON_RED,
  ColorsMap.AQUA,
];

export const getTooltipCircle = (color: string) =>
  `<span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px; background-color:${color}"></span>`;

require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

export const getIntlExporting = (getIntl: (localeKey: string, options?: object) => string = str => str) => ({
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
        ],
      },
    },
    menuItemDefinitions: {
      viewFullscreen: { text: getIntl('View in full screen') },
      printChart: { text: getIntl('Print chart') },
      downloadPNG: { text: getIntl('Download PNG image') },
      downloadJPEG: { text: getIntl('Download JPEG image') },
      downloadPDF: { text: getIntl('Download PDF image') },
      downloadSVG: { text: getIntl('Download SVG vector image') },
    },
  },
});

export const getBaseOptions = (
  getIntl: (localeKey: string) => string = str => str,
  data: { title?: string; xAxisTitle?: string; yAxisTitle?: string } | null
) => ({
  chart: {
    zoomType: 'x',
  },
  title: {
    text: getIntl(data?.title || ''),
  },
  yAxis: {
    title: {
      text: getIntl(data?.yAxisTitle || ''),
    },
  },
  xAxis: {
    title: {
      text: getIntl(data?.xAxisTitle || ''),
    },
  },
});

export interface ChartDataProps {
  rating?: number;
  seriesHash: { [key: string]: [number, number][] };
  zIndex: { [key: string]: number };
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  legendTitle?: string;
  chartType?: 'line' | 'column';
}

interface Props {
  options: Highcharts.Options | null;
  className?: string;
  dataMarker?: string;
  height?: string;
  getIntl?: (localeKey: string, options?: {}) => string;
  chartRef?: MutableRefObject<HighchartsReactRefObject | null>;
}

const Chart: React.FC<Props> = ({ options, className, dataMarker, getIntl = str => str, chartRef }) => {
  if (!options) {
    return (
      <div className={className}>
        <Spinner isInFullHeightContainer />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          exporting: {
            ...getIntlExporting(getIntl),
            ...(options.exporting || {}),
          },
          time: {
            timezoneOffset: -moment().utcOffset(),
          },
          lang: options.lang || {
            noData: getIntl('No data to display'),
          },
          noData: {
            style: {
              fontSize: '15px',
              color: theme.colors.purple800,
            },
          },
        }}
        containerProps={{ className, 'data-marker': dataMarker }}
        ref={chartRef}
      />
    </ErrorBoundary>
  );
};

const StyledChart = styled(Chart)<{ height?: string }>`
  position: relative;
  height: ${props => props.height || '70vh'};

  .highcharts-credits {
    display: none;
  }

  .highcharts-axis-title {
    font-size: 16px;
  }
`;

export default StyledChart;

export const transformAction = (action: Shared.ReduxAction<any>) => {
  if (!Object.keys(action.payload || {}).length) return {} as ChartDataProps;

  const keys = Object.keys(action.payload.ts_data[0] || {}).filter(key => !['time', 'tenant_id'].includes(key));

  const seriesHash = action.payload.ts_data.reduce((acc: any, item: any) => {
    keys.forEach(key => {
      const elem = [item.time * 1000, item[key]];
      if (!acc[key]) {
        acc[key] = [elem];
      } else {
        acc[key].push(elem);
      }
    });
    return acc;
  }, {});

  return {
    rating: action.payload.horizontal_line,
    seriesHash,
    title: action.payload.title,
    yAxisTitle: action.payload.ylabel,
    xAxisTitle: action.payload.xlabel,
    zIndex: action.payload.z_index,
    chartType: action.payload.chart_type || 'line',
  };
};
