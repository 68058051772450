import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { totalHoursSelector, totalHoursAmountSelector, employeeHoursAvailableSelector } from 'modules/tasks/selectors';
import { fetchTotalHoursAction } from 'modules/tasks';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TotalHoursTable: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(totalHoursSelector);
  const count = items.length;
  const totalHoursAmount = useSelector(totalHoursAmountSelector);
  const employeeHoursAvailable = useSelector(employeeHoursAvailableSelector);

  const sendRequest = useCallback(() => dispatch(fetchTotalHoursAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.TOTAL_HOURS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 261px)"
    >
      {Boolean(items?.length) &&
        items.map((item: Tasks.TotalHour) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{getIntl(item.appAction)}</td>
            <td>{getIntl(item.type)}</td>
            <td>
              {numberFormat(item.expectedTime)} {getIntl('Hours')}
            </td>
          </tr>
        ))}
      {Boolean(items?.length) && (
        <>
          <tr className="bg-white font-weight-bold">
            <td colSpan={3} className="text-right">
              {getIntl('Sum')}:
            </td>
            <td>
              {numberFormat(totalHoursAmount)} {getIntl('Hours')}
            </td>
          </tr>
          <tr className="bg-white font-weight-bold">
            <td colSpan={3} className="text-right">
              {getIntl('Employee available')}:
            </td>
            <td>
              {numberFormat(employeeHoursAvailable)} {getIntl('Hours')}
            </td>
          </tr>
        </>
      )}
    </DataTable>
  );
};

export default TotalHoursTable;
