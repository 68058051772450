import React, { useCallback } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { accessLogsSelector, accessLogsCountSelector } from 'modules/permissions/selectors';
import { fetchAccessLogsAction } from 'modules/permissions';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableAccessLogs: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(accessLogsSelector);
  const count = useSelector(accessLogsCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchAccessLogsAction(params)).then(
        (action: Shared.ReduxAction<{ accessLogs: Permissions.AccessLogs[] }>) => action.payload?.accessLogs || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.ADMIN_ACCESS_LOGS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 222px)"
    >
      {items?.map((item: Permissions.AccessLogs) => (
        <tr key={item.user + item.lastAccess}>
          <td>{item.user}</td>
          <td>{item.name || '-'}</td>
          <td>{dateFormat(item.lastAccess, 'lll')}</td>
          <td>{item.operatingSystem}</td>
          <td>{item.appOpened}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableAccessLogs;
