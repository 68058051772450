import React from 'react';
import { Form } from 'react-bootstrap';
import { useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, Checkbox, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes } from 'constants/index';

const TextInputModal: React.FC<Builder.ModalProps> = ({
  type,
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ type, formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);
  const isTextArea: boolean = [BuilderFieldsTypes.LargeTextInput, BuilderFieldsTypes.LargeText].includes(type);

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={type}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            as={isTextArea ? 'textarea' : 'input'}
            rows={3}
            autoFocus
            labelKey={isTextArea ? 'Large text' : 'Label (not shown to user)'}
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        {type !== BuilderFieldsTypes.LargeText && (
          <>
            <Form.Group>
              <FormInput
                labelKey="Placeholder"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={Boolean(touched.placeholder && errors.placeholder) ? (errors.placeholder as string) : ''}
              />
            </Form.Group>
            <Form.Group>
              <Checkbox
                className="pl-0 icheck-primary"
                name="commentsAllowed"
                checked={values.commentsAllowed}
                labelKey="Ask with multiple comments"
                onChange={handleChange}
              />
            </Form.Group>
          </>
        )}

        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default TextInputModal;
