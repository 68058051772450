import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewDetailedInvestments, ViewSummary } from 'components/Investment';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PageAppExceptions } from 'pages';
import { Routes, PermissionsTypes } from 'constants/index';

const PageInvestment: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isInvestmentEnabled}>
    <Switch>
      <Route exact path={Routes.Investment} component={ViewSummary} />
      <Route exact path={Routes.InvestmentDetailedInvestments} component={ViewDetailedInvestments} />
      <Route path="*" component={PageAppExceptions} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageInvestment;
