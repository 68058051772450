import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { changeFilterVoltagesAction } from 'modules/map';
import {
  mapIsLoadingSelector,
  mapStateThemeSelector,
  mapVoltageCheckedFiltersSelector,
  mapVoltageFiltersListSelector,
  settingsSelector,
} from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import LayerHint from 'components/Map/common/LayerHint';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as VoltageIcon } from 'components/Map/icons/icon • map panel section • voltage.svg';
import { IconVoltage } from '@utiligize/shared/resources';
import { MapThemes, VoltageLevels } from 'constants/index';
import { singleLineDiagramFilterVoltagesIdsSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';

interface Props {
  singleDiagramEnabled: boolean;
  map: Exclude<Map.MapboxMap, null>;
}

const FilterVoltages: React.FC<Props> = ({ singleDiagramEnabled, map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const settings = useSelector(settingsSelector);
  const singleLineDiagramFilterVoltagesIds = useSelector(singleLineDiagramFilterVoltagesIdsSelector);
  const mapVoltageFiltersList = useSelector(mapVoltageFiltersListSelector);
  const mapVoltageCheckedFilters = useSelector(mapVoltageCheckedFiltersSelector);
  const theme = useSelector(mapStateThemeSelector);
  const mapIsLoading = useSelector(mapIsLoadingSelector);

  const activeFilters = useMemo(() => settings.themeActiveFilters?.[theme || '']?.filter_voltage_level, [theme]); // eslint-disable-line

  // Note. Set initial filter for single-line diagram
  useEffect(() => {
    if (!singleDiagramEnabled || singleLineDiagramFilterVoltagesIds) return;
    const ids = (mapVoltageFiltersList || [])
      .filter(f => !f.isDisabled && f.full_name !== VoltageLevels[1])
      .map(f => Number(f.id));
    if (!ids.length) return;
    dispatch(setLayoutAction({ singleLineDiagramFilterVoltagesIds: ids }));
  }, [singleDiagramEnabled, singleLineDiagramFilterVoltagesIds, dispatch, mapVoltageFiltersList]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const voltage_level_id = Number(event.target.id);
    if (singleDiagramEnabled) {
      const nextIDs = isChecked
        ? [...(singleLineDiagramFilterVoltagesIds || []), voltage_level_id]
        : (singleLineDiagramFilterVoltagesIds || []).filter(id => id !== voltage_level_id);
      dispatch(setLayoutAction({ singleLineDiagramFilterVoltagesIds: nextIDs }));
    } else {
      dispatch(changeFilterVoltagesAction(isChecked, voltage_level_id, map));
    }
  };

  const renderItems = () => {
    if (!settings?.globalFilters?.filter_voltage) {
      return <StyledWarning>{getIntl('Voltage filter is not found')}</StyledWarning>;
    }

    return mapVoltageFiltersList.map(filter => {
      const isChecked = singleDiagramEnabled
        ? Boolean(singleLineDiagramFilterVoltagesIds?.includes(Number(filter.id)))
        : filter.isChecked;
      return (
        <StyledVoltageItem key={filter.id}>
          <ControlBox
            type="checkbox"
            id={String(filter.id)}
            name={filter.name ?? ''}
            checked={isChecked && !filter.isDisabled}
            disabled={filter.isDisabled || (singleDiagramEnabled && mapIsLoading)}
            labelKey={filter.full_name ?? filter.name}
            onChange={handleFilterChange}
            icon={<IconVoltage />}
            suffix={
              activeFilters?.includes(filter.id as number) && (
                <LayerHint text={getIntl('Filter that can affect selected theme')} type="dot" />
              )
            }
          />
        </StyledVoltageItem>
      );
    });
  };

  return (
    <CollapseGroup
      id="voltage"
      titleKey="Voltages"
      alwaysOpen={singleDiagramEnabled}
      icon={<VoltageIcon className="filter-group-icon" />}
      disabled={settings.isAssetGroupDisabled || theme === MapThemes.TASK}
      dataMarker="panel_voltage"
      description={
        <GroupHiddenDescription
          values={(mapVoltageCheckedFilters?.map(f => f.full_name ?? f.name) ?? []) as string[]}
          defaultValue="Nothing selected"
          showMoreItems
        />
      }
    >
      {renderItems()}
    </CollapseGroup>
  );
};

const StyledVoltageItem = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWarning = styled.div`
  color: var(--map-text-color-inactive);
`;

export default FilterVoltages;
