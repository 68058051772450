import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { useLocale, useBuilderState } from 'hooks';
import { useDispatch } from 'react-redux';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { BuilderField, BuilderTwoOptionsField } from 'components/_builder';
import { Checkbox } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformFormField } from 'utils';
import { BuilderFieldsTypes } from 'constants/index';
import './BuilderBlock.scss';

interface Props {
  isInstructionBuilder?: boolean;
  isToolInspectionBuilder?: boolean;
  blockIndex: number;
  blockSelected: boolean; // block select state
  checked: boolean;
  fields: Builder.Field[];
  delBlock?: (blockIndex: number) => void;
  moveBlock?: (blockIndex: number, offset: number) => void;
  selectBlock?: (blockIndex: number) => void;
  onBlockCheckboxChange?: (blockIndex: number, checked: boolean) => void;
  changeField?: (blockIndex: number, fields: Builder.Field[]) => void;
  addField?: (formField: Builder.Field) => void;
}

const BuilderBlock: React.FC<Props> = ({
  isInstructionBuilder = false,
  isToolInspectionBuilder = false,
  blockIndex,
  blockSelected,
  fields = [],
  checked,
  addField,
  delBlock,
  moveBlock,
  selectBlock,
  changeField,
  onBlockCheckboxChange,
}) => {
  const { fieldTypes } = useBuilderState();
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleDeleteBlockButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => delBlock!(blockIndex),
        })
      );
    },
    [dispatch, delBlock, blockIndex]
  );

  const handleSelectBlockClick = useCallback(() => {
    selectBlock!(blockIndex);
  }, [selectBlock, blockIndex]);

  const moveField = useCallback(
    (idx: number, offset: number) => {
      if ((idx === 0 && offset === -1) || (idx === fields.length - 1 && offset === 1)) {
        return;
      }
      const newFields = fields.map((item, i) => {
        if (i === idx) {
          item.index = item.index + offset;
        } else if (i === idx + offset) {
          item.index = item.index - offset;
        }
        return item;
      });
      newFields.sort((a, b) => {
        return a.index - b.index;
      });
      changeField!(blockIndex, newFields);
    },
    [changeField, blockIndex, fields]
  );

  const onTop = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      moveBlock!(blockIndex || 0, -1);
    },
    [moveBlock, blockIndex]
  );

  const onBottom = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      moveBlock!(blockIndex || 0, 1);
    },
    [moveBlock, blockIndex]
  );

  const delField = useCallback(
    (idx: number) => {
      const newFields: Builder.Field[] = _cloneDeep(fields);
      newFields.splice(idx, 1);
      newFields.forEach((item: Builder.Field, i: number) => {
        item.index = i;
      });
      changeField!(blockIndex, newFields);
    },
    [changeField, blockIndex, fields]
  );

  const handleFieldChange = useCallback(
    (field: Builder.Field) => {
      const nextFields: Builder.Field[] = _cloneDeep(fields);
      nextFields[field.index] = field;
      changeField!(blockIndex, nextFields);
    },
    [changeField, blockIndex, fields]
  );

  const handleBlockCheckboxChange = useCallback(() => {
    onBlockCheckboxChange!(blockIndex, !checked);
  }, [blockIndex, checked, onBlockCheckboxChange]);

  const renderMoveBlockButton = (position: 'top' | 'bottom') => {
    const isTopButton: boolean = position === 'top';
    return (
      <button type="button" className="btn btn-arrow-block p-0 transition" onClick={isTopButton ? onTop : onBottom}>
        <i className={`fa ${isTopButton ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </button>
    );
  };

  return (
    <div
      className={classNames('w-100 border bg-light rounded block-container', { 'border-success': blockSelected })}
      onClick={handleSelectBlockClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 20px',
        minHeight: '170px',
        cursor: 'pointer',
      }}
    >
      <Row className="mb-2">
        <Col className="d-flex align-items-center p-0">
          <span className="badge badge-success">
            {getIntl('Page')} {(blockIndex || 0) + 1}
          </span>
          {!isInstructionBuilder && !isToolInspectionBuilder && (
            <Checkbox
              className="ml-3 icheck-primary"
              checked={checked}
              labelKey="Non Applicable Option"
              onChange={handleBlockCheckboxChange}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-center p-0">{renderMoveBlockButton('top')}</Col>
        <Col className="d-flex justify-content-end p-0">
          {/* FixMe. Button icon outline component must be created */}
          <i role="button" onClick={handleDeleteBlockButtonClick} className="block-icon-delete fas fa-times" />
        </Col>
      </Row>

      <Row>
        {fields?.map((item: Builder.Field) => {
          const field = transformFormField(item);
          const isTwoOptionsField = item.type === BuilderFieldsTypes.TwoOption;
          const Component = isTwoOptionsField ? BuilderTwoOptionsField : BuilderField;
          return (
            <Component
              key={item.id || `${field.indexBlock}_${field.index}`}
              field={field}
              fieldTypes={fieldTypes}
              delField={delField}
              changeField={handleFieldChange}
              moveField={moveField}
              addField={addField as any}
            />
          );
        })}
      </Row>

      <Row className="mt-2">
        <Col className="d-flex justify-content-center">{renderMoveBlockButton('bottom')}</Col>
      </Row>
    </div>
  );
};

export default BuilderBlock;
