import React, { useCallback, useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { IDsState } from './ViewAnalysisTool';

interface Props extends Omit<FormReactSelectProps, 'value'> {
  value?: number;
  index: number;
  setIds: React.Dispatch<React.SetStateAction<IDsState[]>>;
}

const SelectAnalysisToolScenario: React.FC<Props> = ({ value, options, index, setIds, ...props }) => {
  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);

  const handleSelectChange = useCallback(
    (option: { value: number; flex: boolean }): void => {
      setIds(prev =>
        prev.map((item, idx) => (idx === index ? { ...item, scenarioId: option.value, flex: option.flex } : item))
      );
    },
    [index, setIds]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      value={selectValue}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
      mutedTextLabelKey="Scenario"
      {...props}
    />
  );
};

export default SelectAnalysisToolScenario;
