import styled from 'styled-components';
import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import { DataTableTemplate, ContentContainer, ContentCard, Button, Checkbox } from 'components/_common';
import SelectChartType, { ChartTypeKeys, ChartTypes, ChartTypesOption } from './SelectChartType';
import SelectAnalysisToolPortfolio from './SelectAnalysisToolPortfolio';
import SelectAnalysisToolScenario from './SelectAnalysisToolScenario';
import ChartAnalysis from './ChartAnalysis';
import ChartInvestmentSummary from './ChartInvestmentSummary';
import ChartCO2Emissions from './ChartCO2Emissions';
import ChartDERPhaseInProfile from './ChartDERPhaseInProfile';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';
import SelectVoltage from 'components/NetworkLoading/SelectVoltage';
import { TransformerVoltages, CableVoltages } from 'constants/index';

export type IDsState = { portfolioId: number; scenarioId: number; flex: boolean };

const ViewAnalysisTool: React.FC = () => {
  const [typeOption, setTypeOption] = useState(ChartTypes.TR_LOADING);
  const [loading, setLoading] = useState<boolean>(false);
  const isChartAnalysis = [
    ChartTypeKeys.TR_LOADING,
    ChartTypeKeys.TR_OVERLOADING,
    ChartTypeKeys.CABLES_LOADING,
    ChartTypeKeys.CABLES_OVERLOADING,
  ].includes(typeOption.key);
  const isInvestments = typeOption.key === ChartTypeKeys.INVESTMENTS;
  const isEmissions = typeOption.key === ChartTypeKeys.DETAILED_EMISSIONS;
  const isDERPhaseInProfile = typeOption.key === ChartTypeKeys.DER_PHASE_IN_PROFILE;
  const [voltage, setVoltage] = useState<Layouts.TransformerVoltages | Layouts.CableVoltages>(
    TransformerVoltages['10/0.4kV transformer']
  );

  const getVoltages = useCallback(
    (key: ChartTypeKeys) =>
      [ChartTypeKeys.TR_LOADING, ChartTypeKeys.TR_OVERLOADING].includes(key) ? TransformerVoltages : CableVoltages,
    []
  );

  const voltages = getVoltages(typeOption.key);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);

  const getInitPortfolioScenarioFlexState = useCallback(() => {
    return portfolioOptions?.reduce((acc: any, { value }) => {
      const scenarioOptions = scenarioOptionsHash?.[value];
      if (!scenarioOptions || acc.length >= 2) return acc;
      scenarioOptions.forEach(option => {
        if (option.value && acc.length < 2) acc.push({ portfolioId: value, scenarioId: option.value, flex: false });
      });
      return acc;
    }, []);
  }, [portfolioOptions, scenarioOptionsHash]);

  const [ids, setIds] = useState<IDsState[]>(getInitPortfolioScenarioFlexState());

  const portfolioScenarioHash: { [key: string]: string[] | null } = useMemo(() => {
    return portfolioOptions?.reduce((acc: any, item) => {
      const scenarioIds = ids.map(({ scenarioId, flex }) => `${scenarioId}_${flex}`);
      const scenarioOptions = scenarioOptionsHash?.[item.value] || [];
      const values = scenarioOptions
        .flatMap(scenario =>
          isInvestments ? [`${scenario.value}_${false}`, `${scenario.value}_${true}`] : [`${scenario.value}_${false}`]
        )
        .filter(value => !scenarioIds.includes(value));
      // Note. Remove portfolio id hash key when all scenarios already in use
      if (scenarioOptions.length && !values.length) return acc;
      // Note. Return null in case if scenarios must be fetched
      acc[item.value] = scenarioOptions.length ? values : null;
      return acc;
    }, {});
  }, [portfolioOptions, scenarioOptionsHash, ids, isInvestments]);

  const handleAddSetClick = useCallback(() => {
    const portfolioId = Number(Object.keys(portfolioScenarioHash)[0]);
    const values = portfolioScenarioHash[portfolioId]?.[0].split('_');
    const scenarioId = values?.[0] ? Number(values?.[0]) : null;
    const flex = values?.[1] === 'true';
    if (!portfolioId || !scenarioId) return;
    setIds(prev => [...prev, { portfolioId, scenarioId, flex }]);
  }, [portfolioScenarioHash]);

  const handleDeleteBtnClick = useCallback((event: React.SyntheticEvent) => {
    const index: number = Number(event.currentTarget.getAttribute('data-index'));
    setIds(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const index: number = Number(event.currentTarget.getAttribute('data-index'));
    const flex = event.currentTarget.checked;
    setIds(prev =>
      prev.map((item, idx) =>
        idx === index ? { portfolioId: item.portfolioId, scenarioId: item.scenarioId, flex } : item
      )
    );
  }, []);

  const handleTypeOptionChange = useCallback(
    (option: ChartTypesOption) => {
      const nextVoltagesOptions = getVoltages(option.key);
      const isCurrentVoltageExist = Object.keys(nextVoltagesOptions).includes(voltage);
      if (!isCurrentVoltageExist) {
        setVoltage(Object.keys(nextVoltagesOptions)[0] as Layouts.TransformerVoltages | Layouts.CableVoltages);
      }
      setTypeOption(option);
    },
    [getVoltages, voltage]
  );

  const filteredIds = useMemo(() => (isInvestments ? ids : ids.filter(item => !item.flex)), [isInvestments, ids]);

  return (
    <DataTableTemplate paddingBottom>
      <>
        <Row className="mb-3" style={{ rowGap: '1rem' }}>
          {filteredIds.map(({ portfolioId, scenarioId, flex }, index: number) => (
            <Col key={portfolioId + scenarioId + String(flex)} xs={2}>
              <StyledCard>
                <SelectAnalysisToolPortfolio
                  value={portfolioId}
                  options={
                    portfolioOptions
                      ?.filter(
                        option =>
                          option.value === portfolioId ||
                          Object.keys(portfolioScenarioHash).includes(String(option.value))
                      )
                      .map(option => ({
                        ...option,
                        scenarioIds: portfolioScenarioHash[option.value] || [],
                      })) || []
                  }
                  index={index}
                  setIds={setIds}
                  isDisabled={loading}
                />
                <SelectAnalysisToolScenario
                  value={scenarioId}
                  options={
                    scenarioOptionsHash?.[portfolioId]?.reduce((acc, option) => {
                      const nextOption = portfolioScenarioHash[portfolioId]?.find(i => i.includes(option.value));
                      if (option.value === scenarioId || nextOption) {
                        const values = nextOption?.split('_');
                        const flex = values?.[1] === 'true';
                        acc.push({ ...option, flex });
                      }
                      return acc;
                    }, []) || []
                  }
                  index={index}
                  setIds={setIds}
                  isDisabled={loading}
                />
                {isInvestments && (
                  <StyledCheckbox
                    labelKey="Flexible"
                    className="icheck-default"
                    checked={flex}
                    onChange={handleCheckboxClick}
                    data-index={index}
                    disabled={!portfolioScenarioHash?.[portfolioId]?.includes(`${scenarioId}_${!flex}`)}
                  />
                )}
                {filteredIds.length > 2 && (
                  <StyledDeleteCardButton
                    variant="primary-link"
                    icon={<IconDelete />}
                    data-index={index}
                    tooltipKey="Delete"
                    onClick={handleDeleteBtnClick}
                    size="small"
                    aria-label="Delete"
                    disabled={loading}
                  />
                )}
              </StyledCard>
            </Col>
          ))}
          <StyledButtonContainer xs="auto">
            <Button
              tooltipKey="Add set"
              icon={<IconPlus />}
              variant="primary"
              onClick={handleAddSetClick}
              disabled={!Object.keys(portfolioScenarioHash).length || loading}
              labelKey={!filteredIds?.length || filteredIds?.length === 6 ? 'Add set' : ''}
            />
          </StyledButtonContainer>
        </Row>
        <Row>
          <Col xs={3}>
            <SelectChartType value={typeOption.value} onChange={handleTypeOptionChange} isDisabled={loading} />
          </Col>
          {isChartAnalysis && (
            <Col xs={3}>
              <SelectVoltage
                labelKey=""
                mutedTextLabelKey="Voltage"
                value={voltage}
                voltages={voltages}
                onChange={setVoltage}
                variant="small"
              />
            </Col>
          )}
        </Row>
      </>
      <ContentContainer>
        <ContentCard>
          {isChartAnalysis && (
            <ChartAnalysis
              key={typeOption.key + voltage}
              ids={filteredIds}
              voltage={voltage}
              typeOption={typeOption}
              setLoading={setLoading}
            />
          )}
          <ChartInvestmentSummary ids={filteredIds} setLoading={setLoading} isInvestments={isInvestments} />
          <ChartCO2Emissions ids={filteredIds} setLoading={setLoading} isEmissions={isEmissions} />
          <ChartDERPhaseInProfile ids={filteredIds} setLoading={setLoading} isDERPhaseInProfile={isDERPhaseInProfile} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

const StyledCard = styled.div`
  position: relative;
  padding: 10px;
  border: 1px solid #e1e6ec;
  border-radius: 8px;

  > small:first-of-type {
    margin-bottom: 10px;
  }
`;

const StyledDeleteCardButton = styled(Button)`
  position: absolute;
  right: 0px;
  bottom: 0.5rem;
`;

const StyledButtonContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0 !important;

  > label {
    color: #6c757d;
    font-size: 12px !important;
  }
`;

export default ViewAnalysisTool;
