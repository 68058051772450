import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsAction, setMapLayersAction } from 'modules/map';
import {
  legendSelector,
  settingsSelector,
  mapStateSatelliteVisibilitySelector,
  mapStateBuildingsVisibilitySelector,
} from 'modules/map/selectors';
import { renderLayers } from 'components/Map/core/layers';
import { addSource, addLayer, sortLayers, parseStylesToSettings } from 'utils/map';
import { MapParams, StorageKeys, layersState } from 'constants/index';
import { featureTogglesSelector } from 'modules/layouts/selectors';

interface Props {
  map: Map.MapboxMap | null;
  children?: (params: { styles: Map.Style[]; layers: Map.StyleLayer[] }) => React.ReactNode | React.ReactNode[];
}

const SetupLayers: React.FC<Props> = ({ children, map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const settings = useSelector(settingsSelector);
  const legendData = useSelector(legendSelector);
  const satelliteVisibility = useSelector(mapStateSatelliteVisibilitySelector);
  const buildingsVisibility = useSelector(mapStateBuildingsVisibilitySelector);
  const featureToggles = useSelector(featureTogglesSelector);

  const [layers, setLayers] = useState<Map.StyleLayer[]>([]);
  const [styles, setStyles] = useState<Map.Style[]>([]);

  const setupSources = useCallback((styles: Map.Style[]) => {
    styles.map(s => s.source).forEach(s => addSource(s));
  }, []);

  const setupLayers = useCallback((styles: Map.Style[]) => {
    const layers = sortLayers(styles.map(s => s.layer.sublayers).flat());
    // Note. layers is the layers list for the mapbox
    layers.forEach(l => addLayer(l.id, false, l.source, l));
  }, []);

  useEffect(() => {
    if (!map || !map.getStyle() || Object.keys(legendData).length === 0) return;

    map.addLayer({
      id: 'mapbox-buildings',
      type: 'fill-extrusion',
      layout: { visibility: buildingsVisibility ? 'visible' : 'none' },
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      minzoom: 15,
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
        'fill-extrusion-opacity': 0.8,
      },
    });

    map.addLayer(
      {
        id: 'dk-satellite',
        type: 'raster',
        layout: { visibility: satelliteVisibility ? 'visible' : 'none' },
        minzoom: MapParams.minZoom,
        source: {
          type: 'raster',
          tiles: [
            `https://api.dataforsyningen.dk/orto_foraar_DAF?service=WMS&token=725d0812681cf5e64c3495a0a83c1e01&request=GetMap&layers=geodanmark_${featureToggles[StorageKeys.FEATURE_NEW_DENMARK_SATELLITE_TILES] ? 2023 : 2019}_12_5cm&styles=&format=image%2Fpng&TRANSPARENT=TRUE&version=1.3.0&width=256&height=256&crs=EPSG%3A3857&bbox={bbox-epsg-3857}`,
          ],
          tileSize: 256,
        },
      },
      'mapbox-buildings'
    );

    map.addLayer(
      {
        id: 'mapbox-satellite',
        type: 'raster',
        layout: { visibility: satelliteVisibility ? 'visible' : 'none' },
        minzoom: MapParams.minZoom,
        source: {
          type: 'raster',
          url: 'mapbox://mapbox.satellite',
          tileSize: 512,
        },
      },
      'dk-satellite'
    );

    const styles = renderLayers(legendData, settings);
    const styleLayers = styles.map(s => s.layer);
    setupSources(styles);
    setupLayers(styles);
    dispatch(setSettingsAction({ ...parseStylesToSettings(legendData, styleLayers) }));
    dispatch(setMapLayersAction(styleLayers));
    setStyles(styles);
    setLayers(styleLayers);

    return () => {
      dispatch(setMapLayersAction(null));
      setStyles([]);
      setLayers([]);
      layersState.sources = [];
      layersState.layers = [];
    };
  }, [map, dispatch, legendData]); // eslint-disable-line

  return <>{layers.length ? children?.({ styles, layers }) : null}</>;
};

export default React.memo(SetupLayers);
