import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { scenarioRunInfoSelector } from 'modules/networkLoading/selectors';
import { fetchScenarioRunInfoAction } from 'modules/networkLoading';

const ScenarioRunInfo: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const scenarioRunInfo = useSelector(scenarioRunInfoSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId) return;
    dispatch(fetchScenarioRunInfoAction({ portfolioId, scenarioId }));
  }, [dispatch, portfolioId, scenarioId]);

  return (
    <span style={{ fontSize: '70%', lineHeight: 1.016 }}>
      <span className="text-muted">{getIntl('Data timestamp')}:</span>{' '}
      <span className="text-bold">
        {scenarioRunInfo?.customer_data_timestamp ? dateFormat(scenarioRunInfo?.customer_data_timestamp, 'L LT') : '-'}
      </span>
      <br />
      {children || (
        <>
          <span className="text-muted">{getIntl('Data processed at')}:</span>{' '}
          <span className="text-bold">
            {scenarioRunInfo?.data_updated_at ? dateFormat(scenarioRunInfo?.data_updated_at, 'L LT') : '-'}
          </span>
          <br />
          <span className="text-muted">{getIntl('Simulation end')}:</span>{' '}
          <span className="text-bold">
            {scenarioRunInfo?.simulation_end ? dateFormat(scenarioRunInfo?.simulation_end, 'L LT') : '-'}
          </span>
        </>
      )}
    </span>
  );
};

export default ScenarioRunInfo;
