import styled from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTaskFiltersAction, mapStateAction } from 'modules/map';
import { settingsSelector, mapIsLoadingSelector, mapStateThemeSelector } from 'modules/map/selectors';
import { mapTasksFiltersSelector, taskDateRangeSelector } from './selectors';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import MultiSelect from 'components/Map/common/MultiSelect';
import RangeSlider from 'components/Map/common/RangeSlider';
import Loading from 'components/Map/common/Loading';
import { TaskStatusColorMap, TaskStatus, MapThemes } from 'constants/index';

const TasksPanelFilters: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const mapIsLoading = useSelector(mapIsLoadingSelector);
  const settings = useSelector(settingsSelector);
  const taskDateRange = useSelector(taskDateRangeSelector);
  const filters = useSelector(mapTasksFiltersSelector);
  const theme = useSelector(mapStateThemeSelector);

  useEffect(() => {
    if (filters || theme !== MapThemes.TASK) return;
    dispatch(fetchTaskFiltersAction());
  }, [filters, theme, dispatch]);

  const handleRangeSliderChange = useCallback(
    (value: number, valueEnd: number) => {
      if (mapIsLoading) return;
      const [startYear, endYear] = [value, valueEnd].map(v => v + 2020);
      if (startYear === taskDateRange.startYear && endYear === taskDateRange.endYear) return;
      dispatch(mapStateAction({ taskDateRange: { startYear, endYear } }));
    },
    [mapIsLoading, dispatch, taskDateRange.startYear, taskDateRange.endYear]
  );

  const handleMultiSelectChange = useCallback(
    (state: Partial<Map.MapState>) => dispatch(mapStateAction(state)),
    [dispatch]
  );

  const renderFilters = () => {
    if (!filters) return <Loading style={{ padding: '50px 0' }} />;
    return (
      <>
        <RangeSlider
          initValue={taskDateRange.startYear - 2020}
          initValueEnd={taskDateRange.endYear - 2020}
          tooltipLabel={i => String(2020 + i)}
          onChange={handleRangeSliderChange}
          max={20}
          min={0}
          range
          disabled={mapIsLoading}
        />
        <MultiSelect
          title="Departments"
          name="taskDepartments"
          options={filters.departments}
          onChange={handleMultiSelectChange}
          className="mb-2"
          isSelectAllNodeEnabled
          disabled={mapIsLoading}
        />
        <MultiSelect
          title="Actions"
          name="taskActions"
          options={filters.actions}
          onChange={handleMultiSelectChange}
          className="mb-2"
          isSelectAllNodeEnabled
          disabled={mapIsLoading}
        />
        <MultiSelect
          title="Task types"
          name="taskTypes"
          options={filters.types}
          onChange={handleMultiSelectChange}
          className="mb-2"
          isSelectAllNodeEnabled
          disabled={mapIsLoading}
        />
        <MultiSelect
          title="Users"
          name="taskUserEmails"
          options={filters.users}
          onChange={handleMultiSelectChange}
          className="mb-2"
          isSelectAllNodeEnabled
          disabled={mapIsLoading}
        />
        <StyledStatusesMultiSelect
          title="Statuses"
          name="taskStatuses"
          options={filters.statuses}
          onChange={handleMultiSelectChange}
          isSelectAllNodeEnabled
          disabled={mapIsLoading}
        />
      </>
    );
  };

  return (
    <CollapseGroup
      id="task"
      dataMarker="panel_tasks"
      titleKey={settings.themeTitles?.ts!}
      disabled={settings.isTasksThemeDisabled}
      stateLoc="theme"
    >
      {renderFilters()}
    </CollapseGroup>
  );
};

const StyledStatusesMultiSelect = styled(MultiSelect)`
  .sidebar-checkbox.active {
    input[id='${TaskStatus.MobileSubmitted}'] + .sidebar-checkbox-icon > div::after {
      background-color: ${TaskStatusColorMap[TaskStatus.MobileSubmitted]};
    }
    input[id='${TaskStatus.NotStarted}'] + .sidebar-checkbox-icon > div::after {
      background-color: ${TaskStatusColorMap[TaskStatus.NotStarted]};
    }
    input[id='${TaskStatus.Ongoing}'] + .sidebar-checkbox-icon > div::after {
      background-color: ${TaskStatusColorMap[TaskStatus.Ongoing]};
    }
    input[id='${TaskStatus.Finished}'] + .sidebar-checkbox-icon > div::after {
      background-color: ${TaskStatusColorMap[TaskStatus.Finished]};
    }
  }
`;

export default TasksPanelFilters;
