import moment from 'moment';
import { useLocale } from 'hooks';
import React, { useMemo, MutableRefObject } from 'react';
import Chart, { getBaseOptions, getTooltipCircle, HighchartsReactRefObject } from 'components/_charts/Chart';

declare module 'highcharts' {
  interface Chart {
    resetZoomButton?: SVGElement;
  }
}

export interface ChartDataProps {
  series?: Shared.SeriesOptionsType[];
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  chartRef?: MutableRefObject<HighchartsReactRefObject | null>;
  zoomSyncChartRef?: MutableRefObject<HighchartsReactRefObject | null>;
  xAxisMin?: number;
  xAxisMax?: number;
}

const ChartAggregatedMeter: React.FC<ChartDataProps & { dataMarker: string }> = ({
  dataMarker,
  title = '',
  xAxisTitle = '',
  yAxisTitle = '',
  xAxisMin,
  xAxisMax,
  series,
  chartRef,
  zoomSyncChartRef,
}) => {
  const { getIntl, dateFormat } = useLocale();
  const baseOptions = getBaseOptions(getIntl, { title, xAxisTitle, yAxisTitle });
  const options = useMemo(
    () => ({
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        events: {
          selection: function (event: any) {
            const chart = zoomSyncChartRef?.current?.chart as Highcharts.Chart;
            if (!chart) return;

            if (event.resetSelection) {
              chart.xAxis[0].setExtremes(undefined, undefined, true, false, { trigger: 'syncExtremes' });
              chart.resetZoomButton?.destroy();
              chart.resetZoomButton = undefined;
              return true;
            }

            const { min, max } = event.xAxis?.[0];
            chart.xAxis[0].setExtremes(min, max, true, false, { trigger: 'syncExtremes' });
            if (!chart.resetZoomButton) chart.showResetZoom();
          },
        },
      },
      xAxis: {
        ...baseOptions.xAxis,
        type: 'datetime',
        min: xAxisMin,
        max: xAxisMax,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        useHTML: true,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${dateFormat(moment.unix(Number(this.x) / 1000).toISOString(), 'L LT')}<br>
          ${this.points?.map(point => `${getTooltipCircle(point.series.color as string)}${point.series.name}: <b>${point.y}</b>`).join('<br>')}`;
        },
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
      },
      series: series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, series, getIntl, dateFormat, zoomSyncChartRef, xAxisMin, xAxisMax]
  ) as unknown as Highcharts.Options;

  return <Chart chartRef={chartRef} options={series ? options : null} dataMarker={dataMarker} />;
};

export default ChartAggregatedMeter;
