import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { checklistsCountSelector, checklistsSelector } from 'modules/tasks/selectors';
import { fetchChecklistsAction, deleteChecklistAction } from 'modules/tasks';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const ChecklistsTable: React.FC<Props> = ({ maxTableHeight }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const count = useSelector(checklistsCountSelector);
  const items = useSelector(checklistsSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchChecklistsAction(params)).then(
        (action: Shared.ReduxAction<{ checklistsHash: Type.Hash<Tasks.Checklist> }>) =>
          Object.values(action.payload?.checklistsHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ checklistsModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteFormItem = useCallback(
    (id: number): Promise<void> => {
      return dispatch(deleteChecklistAction(id));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => handleDeleteFormItem(id) }));
    },
    [dispatch, handleDeleteFormItem]
  );

  return (
    <DataTable
      paginationType={PaginationType.CHECKLISTS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Checklist) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.assetCategoryCode || '-'}</td>
          <td>{getIntl(item.appAction)}</td>
          <td>
            {item.questions?.map((question: string) => (
              <React.Fragment key={question}>
                - {question}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default ChecklistsTable;
