const theme = {
  colors: {
    white: '#ffffff',
    brightRed: '#dc3545',
    red50: '#ffebee',
    red500: '#f44336',
    red600: '#e53935',
    red900: '#b71c1c',
    green500: '#2db82d',
    green600: '#1e9e1e',
    grey25: '#fbfdff',
    grey50: '#F4F8FB',
    grey100: '#e1e6ec',
    grey200: '#b3b7bb',
    grey300: '#6d7882',
    grey400: '#505c66',
    grey900: '#1c2533',
    purple25: '#F0EEF6',
    purple50: '#e0deec',
    purple100: '#E0DEEB',
    purple150: '#A79CBC',
    purple200: '#675d88',
    purple300: '#B0AECE',
    purple400: '#B0AECE',
    purple800: '#1e134b',
    purple900: '#0f0a2b',
  },
  paddings: {
    primary: '1rem',
  },
  margins: {
    primary: '10px',
  },
  heights: {
    topNavigation: '53px',
  },
};

export default theme;
