import axios from 'axios';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType, BuilderFieldsTypes } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAnswersAction: any = createAction(
  'answers/FETCH_ANSWERS',
  async (
    {
      uuid,
      assetCode,
      portfolioId,
      versionId,
      skipPagination,
      skipStoreUpdate,
      queryInput,
    }: {
      uuid: string;
      assetCode: string;
      portfolioId: Layouts.Root['portfolioId'];
      versionId: number;
      skipPagination: boolean;
      skipStoreUpdate?: true;
      queryInput: string;
    } = {} as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<{ data: Answers.Root; skipStoreUpdate: true | undefined }> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ANSWERS)(state);
      return axios
        .get('api/admin/v2/secure/answers', {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? queryInput || null : query,
            lang: appLangSelector(state).toLowerCase(),
            filters: (uuid && { uuid }) || (assetCode && { assetCode }) || filters,
            portfolioId,
            versionId,
            exportXML: skipStoreUpdate && skipPagination,
          },
        })
        .then(res => ({ data: res.data, skipStoreUpdate }));
    }
);

export const fetchAnswerAction = createAction(
  'answers/FETCH_ANSWER',
  async (id: number) => (): Promise<Answers.Answer> => {
    return axios.get(`api/admin/v2/secure/answers/${id}`).then(
      (res: {
        data: {
          answers: {
            attachments: { fileLink: string; gcsFileName: string; id: number }[];
            comments: { message: string; uuid: string }[];
            fieldId: number;
            formAnswerId: number | null;
            historyId: number | null;
            options: number[];
            taskId: number | null;
            value: string;
          }[];

          lastSnapshot: {
            createdAt: string;
            id: number;
            pages: {
              checked: false;
              fields: Answers.Field[];
              id: number;
              isOptional: null;
              parentId: number | null;
            }[];
          };
          id: number;
          name: string;
          type: string;
          dateCreated: string;
          assetcategory: string;
          assetCode: string;
          userName: string;
          tasksIds: number[];
        };
      }) => {
        const fieldsMap = res.data.lastSnapshot.pages
          // Flat fields
          .reduce((acc: Answers.Field[], page: any) => [...acc, ...page.fields], [])
          // Sort child fields
          .sort((a, b) => Number(a.parentId) - Number(b.parentId))
          // Transform to hash
          .reduce((acc: Map<number, Answers.Field>, field: Answers.Field) => {
            acc.set(field.id, { ...field, answer: null });
            return acc;
          }, new Map());

        // Apply answers
        res.data.answers.forEach((answer: any) => {
          fieldsMap.set(answer.fieldId, { ...(fieldsMap.get(answer.fieldId) as Answers.Field), answer });
        });

        const fields: Answers.Field[] = [...fieldsMap.values()];

        // Find fields ids which must be excluded
        const excludedFieldsIds = fields.reduce((acc: number[], field) => {
          if (field.type !== BuilderFieldsTypes.TwoOption || !field.answer) return acc;
          const excludedOption = field.options.find(option => option.id !== field.answer.options?.[0]);
          if (excludedOption.targetQuestionIds) return [...acc, ...excludedOption.targetQuestionIds];
          return acc;
        }, []);

        return {
          fields: fields.filter(
            i => !excludedFieldsIds.includes(i.id) && (!i.parentId || !excludedFieldsIds.includes(i.parentId))
          ),
          name: res.data.name,
          dateCreated: res.data.dateCreated,
          userName: res.data.userName,
          assetCode: res.data.assetCode,
          tasksIds: res.data.tasksIds,
        };
      }
    );
  }
);

export const deleteAnswerAction = createAction(
  'answers/DELETE_ANSWER',
  async ({ id, redirectUrl }: { id: number; redirectUrl?: string }) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.delete(`api/admin/v1/secure/answers/${id}`).then(() => {
        if (redirectUrl) dispatch(push(redirectUrl));
        dispatch(setSuccessToastAction('Answer has been deleted'));
      });
  }
);
