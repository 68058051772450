import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useLocale } from 'hooks';
import { allTableN1OptionsFetchedSelector } from 'modules/options/selectors';
import { itemsCountSelector, itemsSelector } from 'modules/n1/selectors';
import { fetchN1ItemsAction } from 'modules/n1';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { MapThemeGroups, MapThemes, PaginationType, Routes, StorageKeys } from 'constants/index';
import { IconMap } from '@utiligize/shared/resources';
import { getStorageItem, setStorageItem } from 'utils';

const TableN1: React.FC = () => {
  const dispatch = useDispatch();
  const { getIntl } = useLocale();
  const allTableN1OptionsFetched = useSelector(allTableN1OptionsFetchedSelector);

  const count = useSelector(itemsCountSelector);
  const items = useSelector(itemsSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchN1ItemsAction(params)).then(
        (action: Shared.ReduxAction<{ items: N1.Root['items'] }>) => action.payload?.items || []
      ),
    [dispatch]
  );

  const handleMapLinkClick = useCallback(() => {
    setStorageItem({
      [StorageKeys.MAP_CACHE]: {
        ...(getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || {}),
        theme: MapThemes.N1_ROUTES,
        themeGroup: MapThemeGroups.FORECAST_AND_INVESTMENT,
      },
    });
  }, []);

  return (
    <DataTable
      paginationType={PaginationType.N1}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!allTableN1OptionsFetched || !portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map?.((item, i) => (
        <tr key={i}>
          <td>{item.outage_asset || '-'}</td>
          <td>{getIntl(item.outage_asset_type)}</td>
          <td>{getIntl(item.outage_type_text)}</td>
          <td>{item.primary_substation || '-'}</td>
          <td className="text-break" style={{ maxWidth: '10vw' }}>
            {item.route}
          </td>
          <td className="text-right">
            {item.positionOnTheMap ? (
              <Button
                tooltipKey="Show on map"
                icon={<IconMap />}
                linkProps={{
                  to: { pathname: generatePath(Routes.Map), state: { n1Route: item.positionOnTheMap } },
                }}
                size="small"
                onClick={handleMapLinkClick}
              />
            ) : (
              '-'
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableN1;
