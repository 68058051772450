import React from 'react';
import { useLocale } from 'hooks';
import { AppErrorsTypes } from 'constants/index';

const PageAppExceptions: React.FC<{ appError?: App.AppErrorsTypes }> = ({
  appError = AppErrorsTypes.PAGE_NOT_FOUND,
}) => {
  const { getIntl } = useLocale();

  const getColor = () => {
    switch (appError) {
      case AppErrorsTypes.NO_SCENARIOS:
        return 'text-warning';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'text-warning';
      default:
        return 'text-danger';
    }
  };

  const getStatus = () => {
    switch (appError) {
      case AppErrorsTypes.NO_SCENARIOS:
        return '';
      case AppErrorsTypes.NO_SIMULATIONS:
        return '';
      default:
        return 404;
    }
  };

  const getTitle = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'User not found';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'Tenant not found';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'No assigned tenant';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'No scenario found';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'No simulation found';
      default:
        return 'Page not found';
    }
  };

  const getMessage = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'We could not find the user you were looking for. Please contact your administrator';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'We could not find the tenant you were looking for. Meanwhile, you may use the user menu to select another tenant';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'We could not find any assigned tenant to your account. Please contact your administrator';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'We could not find a scenario. Meanwhile, you may use the menu to select another portfolio';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'We could not find a simulation. Meanwhile, you may use the menu to select another portfolio and/or scenario';
      default:
        return 'We could not find the page you were looking for. Meanwhile, you may use the dashboard menu';
    }
  };

  const color = getColor();
  const status = getStatus();
  return (
    <div className="error-page mt-0 pt-5">
      {status && <h2 className={`headline ${color}`}>{status}</h2>}
      <div className="error-content">
        <h3>
          <i className={`fas fa-exclamation-triangle ${color}`} /> {getIntl(getTitle())}.
        </h3>
        <p>{getIntl(getMessage())}.</p>
      </div>
    </div>
  );
};

export default PageAppExceptions;
