import { combineActions, handleActions } from 'redux-actions';
import {
  fetchTransformersAction,
  fetchAssetCommentsAction,
  setSolvedAction,
  getTimeSeriesChartData,
  getProfileChartData,
  getDurationChartData,
  fetchAssetCustomersAction,
  getTotalLoadingAggregatedMeterChartsDataAction,
  fetchScenarioRunInfoAction,
  resetScenarioRunInfoAction,
  getTransformersHistoricalLoadingChartData,
  getTransformersVoltageChartData,
  getTotalLoadingProfileChartData,
  setFutureChartData,
  fetchFutureChartData,
  getReliabilityChartData,
} from './actions';
import { fetchCablesAction, getCablesHistoricalLoadingChartData, getCablesVoltageChartData } from './cables.actions';

const initialState: NetworkLoading.Root = {
  transformersHash: {} as NetworkLoading.Root['transformersHash'],
  transformersCount: 0,
  ignoreMessagesHash: {} as NetworkLoading.Root['ignoreMessagesHash'],
  ignoreMessagesCount: 0,
  cablesHash: {} as NetworkLoading.Root['cablesHash'],
  cablesCount: 0,
  customersTypesHash: {} as NetworkLoading.Root['customersTypesHash'],
  customersTypesInstallations: [],
  scenarioRunInfo: null,
  futureChartDataHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchTransformersAction,
    fetchAssetCommentsAction,
    fetchCablesAction,
    fetchAssetCustomersAction,
    fetchScenarioRunInfoAction,
    resetScenarioRunInfoAction,
    fetchFutureChartData
  ) as any]: {
    next: (
      state: NetworkLoading.Root,
      action: Shared.ReduxAction<Partial<NetworkLoading.Root> & { skipStoreUpdate: true | undefined }>
    ): NetworkLoading.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  [setFutureChartData.toString()]: (
    state: NetworkLoading.Root,
    action: Shared.ReduxAction<NetworkLoading.Root['futureChartDataHash']>
  ): NetworkLoading.Root => ({
    ...state,
    futureChartDataHash: {
      ...state.futureChartDataHash,
      ...action.payload,
    },
  }),
};

export {
  fetchTransformersAction,
  fetchAssetCommentsAction,
  fetchCablesAction,
  setSolvedAction,
  getTimeSeriesChartData,
  getProfileChartData,
  getDurationChartData,
  fetchAssetCustomersAction,
  getTotalLoadingAggregatedMeterChartsDataAction,
  fetchScenarioRunInfoAction,
  getTransformersHistoricalLoadingChartData,
  getTransformersVoltageChartData,
  getCablesHistoricalLoadingChartData,
  getCablesVoltageChartData,
  getTotalLoadingProfileChartData,
  setFutureChartData,
  fetchFutureChartData,
  getReliabilityChartData,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS as any, initialState);
