import styled from 'styled-components';
import React from 'react';
import { Table } from 'react-bootstrap';
import { TasksModal } from 'components/_modals';
import { ContentContainer } from 'components/_common';

interface Props {
  children: React.ReactElement;
}

const TableWrapper: React.FC<Props> = ({ children }) => (
  <>
    <ContentContainer>
      <div style={{ display: 'block', width: '100%', maxHeight: 'calc(100vh - 222px)', overflow: 'auto' }}>
        <StyledTable bordered className="utiligize-table dataTable">
          {children}
        </StyledTable>
      </div>
    </ContentContainer>
    <TasksModal hideButton />
  </>
);

const StyledTable = styled(Table)`
  table-layout: fixed;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-collapse: separate !important;
  border-spacing: 0;

  thead {
    position: sticky;
    top: 0;
    background: #fafafa;
    z-index: 2;

    th {
      border-bottom-color: #dee2e6 !important;
    }

    tr:first-child > th {
      border-top: 1px solid #dee2e6;
    }

    tr > th.sticky {
      border-bottom-width: 0 !important;
    }
  }

  tr > th.sticky {
    background-color: #fafafa;
  }

  tr > td.sticky {
    background-color: white;
  }

  tr > th,
  tr > td {
    padding: 9px 6px !important;
    box-sizing: border-box;

    &.sticky {
      position: sticky;
      z-index: 1;
    }

    &.first {
      width: 150px;
      left: 0;
      border-left: 1px solid #dee2e6;
    }

    &.first-170 {
      width: 170px;
    }

    &.first-footer {
      width: 570px;
      left: 0;
      border-left: 1px solid #dee2e6;
    }

    &.second {
      left: 150px;
      width: 245px;
    }

    &.second-225 {
      left: 170px;
      width: 225px;
    }

    &.third {
      width: 90px;
      left: 395px;
    }

    &.fourth {
      width: 90px;
      left: 485px;
    }

    &.third-fourth {
      width: 180px;
      left: 395px;
    }

    &.week {
      width: 140px;
      text-align: center;
    }

    &.day {
      padding: 0 !important;
      background: white;
    }

    &.bg-light-gray {
      background-color: #d3d3d3;
    }
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background: #fafafa;
    z-index: 2;

    tr:not(:last-child) th,
    tr:not(:last-child) td {
      border-bottom: 0;
    }
  }
`;

export default TableWrapper;
