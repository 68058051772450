import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useLocale } from 'hooks';
import { finishedTasksCountSelector, finishedTasksSelector } from 'modules/tasks/selectors';
import { fetchTasksAction, deleteTaskAction, reopenTaskAction } from 'modules/tasks';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import {
  Alert,
  DataTable,
  ShowUsersOverlay,
  ChecklistStatus,
  TaskTypeBadge,
  Button,
  IconDeleteTableAction,
} from 'components/_common';
import { Routes, PaginationType, TaskTypes } from 'constants/index';
import { IconReopen } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksFinishedTable: React.FC<Props> = ({ maxTableHeight }) => {
  const { getIntl, dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(finishedTasksCountSelector);
  const items = useSelector(finishedTasksSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksAction({ ...params, includeExternalTime: true, includeExpectedTimeFields: false })).then(
        (action: Shared.ReduxAction<{ finishedTasksHash: Type.Hash<Tasks.Task> }>) =>
          Object.values(action.payload?.finishedTasksHash || {})
      ),
    [dispatch]
  );

  const handleReopenTaskBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(
        setModalConfirmationMetaAction({
          titleKey: 'Do you want to reopen the task?',
          bodyComponent: (
            <Alert className="mb-0 text-center" variant="warning">
              {getIntl('Once the task will be reopened, please make sure end date is in the future')}
            </Alert>
          ),
          submitButtonProps: {
            labelKey: 'Reopen task',
          },
          onConfirm: () => dispatch(reopenTaskAction(id)),
        })
      );
    },
    [dispatch, getIntl]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(push(generatePath(Routes.Tasks, { id })));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.TASKS_FINISHED}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      hover
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Task) => (
        <tr key={item.id} data-id={item.id} onClick={handleDetailsBtnClick}>
          <td>
            <TaskTypeBadge id={item.id} type={item.type} className="mr-2" />
            {item.name}
          </td>
          {item.type === TaskTypes.Autogenerated ? (
            <td colSpan={2} className="text-center">
              {item.taskMonths?.map(month => getIntl(month)).join(', ')}
            </td>
          ) : (
            <>
              <td className="text-nowrap">{item.startDate ? dateFormat(item.startDate) : '-'}</td>
              <td className="text-nowrap">{item.startDate ? dateFormat(item.endDate) : '-'}</td>
            </>
          )}
          <td className="text-nowrap">{item.createdBy?.fullName || '-'}</td>
          <td>{item.finishDate ? dateFormat(item.finishDate) : '-'}</td>
          <td>{item.finishedBy ? item.finishedBy.fullName : '-'}</td>
          <td className="text-center">
            {item.registeredTime ? `${numberFormat(item.registeredTime)} ${getIntl('Hours')}` : '-'}
          </td>
          <td className="text-center">
            {item.navisionRegisteredTime ? `${numberFormat(item.navisionRegisteredTime)} ${getIntl('Hours')}` : '-'}
          </td>
          <td className="text-center">
            {item.taskCheckListId ? <ChecklistStatus id={item.id} isChecklistDone={item.isChecklistDone} /> : '-'}
          </td>
          <td className="text-right">
            <ShowUsersOverlay
              popoverId={item.id}
              userIds={item.assignedUsers.map(user => user.id)}
              responsibleUserIds={item.responsibleUsers.map(user => user.id)}
            />
            <Button
              marginLeft
              tooltipKey="Reopen task"
              icon={<IconReopen />}
              data-id={item.id}
              onClick={handleReopenTaskBtnClick}
              size="small"
            />
            <IconDeleteTableAction id={item.id} name={item.name || ''} marginLeft action={deleteTaskAction} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TasksFinishedTable;
