import React from 'react';
import { Switch, Route } from 'react-router';
import { PageAppExceptions } from 'pages';
import { Models, Manufacturers } from 'components/AssetModels';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageAssetModels: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isAssetModelsEnabled}>
    <Switch>
      <Route exact path={Routes.AssetModels} component={Models} />
      <Route exact path={Routes.AssetModelsManufacturers} component={Manufacturers} />
      <Route path="*" component={PageAppExceptions} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageAssetModels;
