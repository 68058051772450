import React, { useMemo, useEffect, useCallback } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsTenantsSelector } from 'modules/permissions/selectors';
import { fetchPermissionsTenantsAction } from 'modules/permissions';

interface Props extends Omit<FormReactSelectProps, 'value' | 'onChange' | 'options'> {
  values: number[];
  onChange?: (value: number[], actionMeta: Type.SelectActionMetaBase) => void;
}

const SelectTenants: React.FC<Props> = ({ labelKey = 'Tenants', isDisabled, values, onChange, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const tenants = useSelector(permissionsTenantsSelector);
  const tenantsFetched = Boolean(tenants.length);

  useEffect(() => {
    if (tenantsFetched) return;
    dispatch(fetchPermissionsTenantsAction({ skipPagination: true, skipFilters: true }));
  }, [tenantsFetched, dispatch]);

  const { options, selectValues } = useMemo(
    () =>
      tenants.reduce(
        (acc: any, { id, tenantName }: Permissions.Tenant) => {
          const item = { value: id, label: tenantName };
          if (values.includes(id)) acc.selectValues.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValues: [] }
      ),
    [values, tenants]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption[], actionMeta: Type.SelectActionMetaBase) => {
      if (onChange) onChange(value?.map(v => v.value as number) || [], actionMeta);
    },
    [onChange]
  );

  return (
    <FormReactSelect
      {...props}
      isMulti
      labelKey={labelKey}
      isLoading={!tenantsFetched}
      isDisabled={!tenantsFetched || isDisabled}
      value={selectValues}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

export default SelectTenants;
