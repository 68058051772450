import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsUsersCountSelector, permissionsUsersSelector } from 'modules/permissions/selectors';
import { fetchUsersAction, deleteUserAction } from 'modules/permissions';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { sortPermissions } from 'utils';
import { PaginationType, PermissionsTypesLocalesMap } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const TableUsers: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(permissionsUsersCountSelector);
  const items = useSelector(permissionsUsersSelector);

  const sendRequest = useCallback(() => dispatch(fetchUsersAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const permissionsUsersModalId: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (permissionsUsersModalId) dispatch(setLayoutAction({ permissionsUsersModalId }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteUserAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.PERMISSIONS_USERS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 222px)"
    >
      {items?.map((item: Users.User) => (
        <tr key={item.id}>
          <td>{item.fullName}</td>
          <td>{item.email}</td>
          <td>{item.groupPermissions?.length ? item.groupPermissions?.map(i => i.name).join(', ') : '-'}</td>
          <td>
            {Object.values(item.userPermissions || {}).filter(Boolean).length
              ? (Object.keys(item.userPermissions!) as Permissions.PermissionsTypes[])
                  .sort(sortPermissions)
                  .map((key: string) => {
                    if (!item.userPermissions![key as Permissions.PermissionsTypes]) return null;
                    return (
                      <React.Fragment key={key}>
                        - {PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes]?.labelKey || key}
                        <br />
                      </React.Fragment>
                    );
                  })
              : '-'}
          </td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableUsers;
