import { withIntl } from 'hoc';
import {
  default as SharedChart,
  Colors,
  ColorsMap,
  getBaseOptions,
  ChartDataProps,
  HighchartsReactRefObject,
  transformAction,
  getIntlExporting,
  getTooltipCircle,
} from '@utiligize/shared/components/Chart';

const Chart = withIntl(SharedChart);

export default Chart;

export { Colors, ColorsMap, getBaseOptions, transformAction, getIntlExporting, getTooltipCircle };
export type { ChartDataProps, HighchartsReactRefObject };
