import { isNKTenant } from 'utils';

export enum TopTabsIndexesMap {
  LiveData = 0,
  AssetDetails = isNKTenant ? 1 : 0,
  Reinvestment = isNKTenant ? 2 : 1,
  SubComponents = 3,
  Photo = 4,
  History = 5,
  Answers = 6,
  TaskCompletions = 7,
  DataManuals = 8,
  Customers = isNKTenant ? 9 : 2,
  Comments = isNKTenant ? 10 : 3,
}

export enum BottomTabsIndexesMap {
  Power = 0,
  Voltage = 1,
  Losses = 2,
  Profile = 3,
  Duration = 4,
  Reliability = 5,
  Tasks = 6,
}
