import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getTotalLoadingProfileChartData } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartTotalLoadingProfile: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const baseOptions = getBaseOptions(getIntl, chartData);

  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    setChartData(null);
    dispatch(getTotalLoadingProfileChartData({ portfolioId, scenarioId }))
      .then((action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data'> | null>) => {
        setChartData({
          series:
            action.payload?.series.map((i, index: number) => ({
              name: i.group || '',
              type: 'line' as any,
              data: i.data.map((d, index) => [action.payload!.datetime_x[index], d]),
              color: Colors[index],
            })) || [],
          title: action.payload?.title || '',
          xAxisTitle: action.payload?.xaxis_title || '',
          yAxisTitle: action.payload?.yaxis_title || '',
        });
      })
      .catch(console.error);
  }, [portfolioId, scenarioId, dispatch]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        tickInterval: 1,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
          marker: {
            enabled: false,
          },
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, chartData, getIntl]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_network_loading_total_loading_profile" />;
};

export default ChartTotalLoadingProfile;
