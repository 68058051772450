import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { fetchAnswersAction, deleteAnswerAction } from 'modules/answers';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { answersItemsSelector, answersCountSelector } from 'modules/answers/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { IconDelete, IconDetails } from '@utiligize/shared/resources';
import { AnswerDeleteAlert } from 'components';
import { DataTable, Button } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';

interface Props {
  assetCode?: string;
  containerClassName?: string;
}

const AnswersTable: React.FC<Props> = ({ assetCode, containerClassName = '' }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const inAssetDetailsView = Boolean(assetCode);

  const count = useSelector(answersCountSelector);
  const items = useSelector(answersItemsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      if (!portfolioId || !versionId) return Promise.resolve();
      return dispatch(fetchAnswersAction({ assetCode, portfolioId, versionId, ...params })).then(
        (action: Shared.ReduxAction<{ data: Answers.Root }>) => action.payload?.data?.rows || []
      );
    },
    [dispatch, assetCode, portfolioId, versionId]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const name: string = event.currentTarget.getAttribute('data-name') || '';
      dispatch(
        setModalConfirmationMetaAction({
          bodyComponent: <AnswerDeleteAlert name={name} />,
          onConfirm: () => dispatch(deleteAnswerAction({ id })).then(() => sendRequest() as any),
        })
      );
    },
    [dispatch, sendRequest]
  );

  const renderAssetCode = (item: Answers.Item) => {
    if (!item.assetUuid || !item.assetNumber) return item.assetNumber || '-';
    return (
      <Link
        to={{
          pathname: generatePath(Routes.Map, { uuid: item.assetUuid }),
          state: { prevRoute: window.location.pathname },
        }}
      >
        {item.assetNumber}
      </Link>
    );
  };

  return (
    <DataTable
      hideFilters={inAssetDetailsView}
      hideHeaders={inAssetDetailsView ? [4] : []}
      paginationType={PaginationType.ANSWERS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      containerClassName={containerClassName}
      maxHeight="calc(100vh - 170px)"
      triggerTableUpdateDeps={[portfolioId, versionId]}
    >
      {items.map((item: Answers.Item) => {
        const hasForm: boolean = Boolean(item.form);
        const name = hasForm ? item.form.name : item.formName;
        return (
          <tr key={item.id}>
            <td>{name}</td>
            <td>{dateFormat(item.createdAt)}</td>
            <td>{hasForm ? getIntl(item.form?.formtype?.name) : '-'}</td>
            <td>{hasForm ? getIntl(item.form.assetcategory?.name) || '-' : item.assetCategory}</td>
            {!inAssetDetailsView && <td>{renderAssetCode(item)}</td>}
            <td>{item.sso_user.fullName}</td>
            <td className="text-right">
              <Button
                tooltipKey="Details"
                icon={<IconDetails />}
                data-id={item.id}
                linkProps={{ to: generatePath(Routes.HistoryAnswers, { id: item.id }) }}
                size="small"
              />
              <Button
                marginLeft
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                data-name={name}
                onClick={handleDeleteBtnClick}
                size="small"
              />
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default AnswersTable;
