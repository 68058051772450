import React, { useState, useMemo, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchInspectionsAction } from 'modules/inspections';
import { inspectionsItemsSelector } from 'modules/inspections/selectors';
import { FormReactSelect } from 'components/_common';

interface Props {
  labelKey?: string;
  errorKey?: string;
  disabled?: boolean;
  value: number | null;
  onChange?: (value: Type.SelectOption, actionMeta: Type.SelectActionMetaBase) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

const SelectToolInspectionForms: React.FC<Props> = memo(
  ({ labelKey = 'Tool inspection', disabled = false, value, ...rest }) => {
    const dispatch: Shared.CustomDispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const items: Inspections.Item[] = useSelector(inspectionsItemsSelector);

    useEffect(() => {
      dispatch(fetchInspectionsAction({ skipFilters: true, skipPagination: true }))
        .then(() => setLoading(false))
        .catch(console.error);
    }, [dispatch]);

    const { options, selectValue } = useMemo(
      () =>
        items.reduce(
          (acc: any, inspection: Inspections.Item) => {
            const item = { value: inspection.id, label: inspection.name, userIds: inspection.userIds };
            if (inspection.id === value) acc.selectValue = item;
            acc.options.push(item);
            return acc;
          },
          { options: [], selectValue: null }
        ),
      [items, value]
    );

    return (
      <FormReactSelect
        labelKey={labelKey}
        isLoading={loading}
        isDisabled={loading || disabled}
        value={selectValue}
        options={options}
        {...rest}
      />
    );
  }
);

export default SelectToolInspectionForms;
