import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Form, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { Checkbox } from 'components/_common';
import { PermissionsTypesLocalesMap, PermissionGroups } from 'constants/index';

const PermissionsGroups: React.FC<{
  objKey: 'permissionsTypes' | 'tenantPermissions' | 'userPermissions';
  permissions: Permissions.Permissions;
  onChange: (e: React.ChangeEvent) => void;
}> = ({ objKey, permissions, onChange }) => {
  const { getIntl } = useLocale();

  const permissionsByGroups = useMemo(
    () =>
      (Object.keys(permissions) as Permissions.PermissionsTypes[]).reduce(
        (acc: Record<PermissionGroups, any[]>, key) => {
          const { group, labelKey = key } = PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes] || {};

          const checkbox = (
            <Form.Group as={Col} xs={group === PermissionGroups.READ_WRITE ? 6 : 4} key={key} className="mb-0">
              <Checkbox
                labelKey={labelKey}
                name={`${objKey}.${key}`}
                className="icheck-primary"
                checked={permissions[key as Permissions.PermissionsTypes]}
                onChange={onChange}
              />
            </Form.Group>
          );

          acc[group].push(checkbox);
          return acc;
        },
        Object.values(PermissionGroups).reduce(
          (acc, key) => {
            acc[key] = [];
            return acc;
          },
          {} as Record<PermissionGroups, any[]>
        )
      ),
    [objKey, permissions, onChange]
  );

  return (
    <>
      {Object.values(PermissionGroups)
        .filter(key => Boolean(permissionsByGroups[key]?.length))
        .map((key, index: number) => (
          <React.Fragment key={key}>
            <label className={classNames('mb-0', { 'mt-3': index })}>{getIntl(key)}</label>
            <hr className="mt-0 mb-1" />
            <Form.Row>{permissionsByGroups[key]}</Form.Row>
          </React.Fragment>
        ))}
    </>
  );
};

export default PermissionsGroups;
