import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { getGalleryImages } from 'utils';
import { _keyBy } from '@utiligize/shared/utils';
import { AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetCategoriesAction: any = createAction(
  'assets/FETCH_CATEGORIES',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Asset.Root, 'categories'>> => {
      const state: State.Root = getState();
      return axios
        .get('api/admin/v1/secure/assetcategories', {
          params: {
            limit: null,
            offset: null,
            sort: 'ASC',
            column: 'name',
            query: null,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({ categories: res.data.assetCategories }));
    }
);

export const fetchAssetAction = createAction(
  'assets/FETCH_ASSET',
  ({ uuid, assetCode, versionId }: { uuid?: string; assetCode?: string; versionId?: number }) =>
    (): Promise<Asset.ExtendedItem | null> => {
      return axios
        .get(`api/admin/v2/secure/assets/${uuid || assetCode}`, { params: { versionId } })
        .then(res => {
          const attachments = res.data.attachments || res.data.liveData?.attachments;
          const images = attachments
            ? getGalleryImages(
                attachments.filter((attachment: Type.Attachment) => attachment.width && attachment.height)
              )
            : undefined;
          return {
            ...res.data,
            images,
            ...(res.data.liveData ? { liveData: { ...res.data.liveData, images } } : null),
          };
        })
        .catch(() => null);
    }
);

export const updateAssetAction = createAction(
  'assets/UPDATE_ASSET',
  (assetMeta: Asset.Item) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return axios.put(`api/admin/v1/secure/assets/${assetMeta.assetCode}`, assetMeta).then(async () => {
        const state = getState();
        // redux state must be updated with for assigned meter number
        if (assetMeta.elInTablID) await dispatch(fetchSmartMetersAction());
        // update asset meta for UnifiedAssetPanel
        const uuid = mapPanelSelectedAssetUUIDSelector(state);
        const versionId = simulationVersionIdSelector(state);
        if (uuid && versionId) {
          await dispatch(fetchAssetAction({ uuid, versionId }));
        }
        dispatch(setSuccessToastAction('Asset has been saved'));
      });
    }
);

export const deleteAssetPhotosAction = createAction(
  'assets/DELETE_ASSET_PHOTOS',
  ({ uuid, assetCode, attachmentKeys }: { uuid?: string; assetCode?: string; attachmentKeys: string[] }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const versionId = simulationVersionIdSelector(getState());
      return axios
        .post(`api/admin/v2/secure/assets/${uuid || assetCode}/attachments/delete`, { attachmentKeys, versionId })
        .then(() => {
          dispatch(setSuccessToastAction('Selected photos has been deleted'));
        });
    }
);

export const fetchModelsByAssetCategoryAction = createAction(
  'assets/FETCH_MODELS_BY_CATEGORY',
  (categoryCode: Type.AssetCategories) => (): Promise<AssetModels.Item[]> => {
    return axios.get(`api/admin/v1/secure/assets/${categoryCode}/assetmodels`).then(res => res.data.rows);
  }
);

export const fetchSubComponentsAction: any = createAction(
  'assets/FETCH_SUB_COMPONENTS',
  async (assetCategoryCode: Type.AssetCategories) => (): Promise<Pick<Asset.Root, 'subComponents'>> => {
    return axios
      .get('api/admin/v1/secure/subcomponentsType', {
        params: { assetCategoryCode, sort: 'ASC', column: 'name' },
      })
      .then((res: any) => ({ subComponents: res.data.rows }))
      .catch(() => ({ subComponents: [] }));
  }
);

export const createSubComponentAction = createAction(
  'assets/CREATE_SUB_COMPONENT',
  async (data: { name: string; assetCategoryCode: Type.AssetCategories }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post('api/admin/v1/secure/subcomponentsType', data).then(async (res: any) => {
        await dispatch(fetchSubComponentsAction(data.assetCategoryCode));
        dispatch(setSuccessToastAction('Asset sub-component type has been created'));
        return res.data;
      });
    }
);

export const deleteSubComponentAction = createAction(
  'assets/DELETE_SUB_COMPONENT',
  (id: number, assetCategoryCode: Type.AssetCategories) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.delete(`api/admin/v1/secure/subcomponentsType/${id}`).then(async () => {
        await dispatch(fetchSubComponentsAction(assetCategoryCode));
        dispatch(setSuccessToastAction('Asset sub-component type has been deleted'));
      });
    };
  }
);

export const fetchSmartMetersAction = createAction(
  'assets/FETCH_SMART_METERS',
  () => (): Promise<Pick<Asset.Root, 'smartMetersHash' | 'smartMetersHashFetched'>> =>
    axios.get('api/admin/v1/secure/smartMeters').then(res => ({
      smartMetersHash: _keyBy(res.data.rows, (item: Asset.SmartMeter) => `_${item.elInTablID}_`),
      smartMetersHashFetched: true,
    }))
);

export const fetchAssetTaskCompletionsAction = createAction(
  'assets/FETCH_ASSET_TASK_COMPLETIONS',
  async (uuid: string) => (): Promise<Pick<Asset.Root, 'taskCompletions'>> =>
    axios.get(`api/admin/v2/secure/assets/${uuid}/taskCompletions`).then((res: any) => ({
      taskCompletions: res.data.taskCompletions,
    }))
);

export const fetchAssetCodeAutoCompleteAction: any = createAction(
  'assets/FETCH_ASSET_CODE_AUTO_COMPLETE',
  async (query: string) => (): Promise<string[]> =>
    axios
      .get(`api/admin/v1/secure/assets/codes/validate/${query}`)
      .then(res => res.data)
      .catch(() => [])
);

export const fetchAssetCodeAutoCompleteNewAction: any = createAction(
  'assets/FETCH_ASSET_CODE_AUTO_COMPLETE_NEW',
  async (query: string, withGeometry: boolean) =>
    (dispatch: Function, getState: () => State.Root): Promise<Asset.AssetCodeItem[]> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return AssetLifeAPI.get<Asset.AssetCodeItem[]>('map/search_assets_by_code', {
        params: {
          asset_code: query,
          with_geometry: withGeometry,
          portfolio_id: portfolioId,
          version_id: versionId,
        },
      })
        .then(res => res.data)
        .catch(() => []);
    }
);

export const fetchInstallationNumberAutoCompleteAction: any = createAction(
  'assets/FETCH_INSTALLATION_NUMBER_AUTO_COMPLETE',
  async (query: string) => (): Promise<string[]> =>
    axios
      .get(`api/admin/v1/secure/smartMeters/codes/validate/${query}`)
      .then(res => res.data)
      .catch(() => [])
);
