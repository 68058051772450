import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import * as d3 from 'd3';
import RangeSlider from 'components/Map/common/RangeSlider';
import { Spinner, ContentContainer, ContentCard } from 'components/_common';
import { AssetLifeAPI } from 'constants/index';
import ColorLegend from './legend/icon • map block • risk • kva rating.svg';
import SizeLegend from './legend/icon • map block • risk • utilization.svg';

interface MatrixSettings {
  chart: d3.Selection<d3.BaseType, any, any, any>;
  pointsContainer: d3.Selection<SVGElement, any, any, any>;
  axisLabelsContainer: d3.Selection<SVGElement, any, any, any>;
  gridContainer: d3.Selection<SVGElement, any, any, any>;
  cellsContainer: d3.Selection<SVGElement, any, any, any>;
  labelsContainer: d3.Selection<SVGElement, any, any, any>;
  sumsContainer: d3.Selection<SVGElement, any, any, any>;
  tooltip: d3.Selection<HTMLDivElement, any, any, any>;
  margin: number;
  axisLabelMargin: number;
  chartGridPos: number;
  chartGridWidth: number;
  chartGridHeight: number;
  axisLabelXPos: number;
  axisLabelYPos: number;
  chartGridCountX: number;
  chartGridCountY: number;
  chartGridCellWidth: number;
  chartGridCellHeight: number;
  chartGridCellMargin: number;
  chartPosX: number;
  chartPosY: number;
  chartCellCountX: number;
  chartCellCountY: number;
  chartCellWidth: number;
  chartCellHeight: number;
}

const Matrix: React.FC = () => {
  const containerRef = useRef(null);
  const [data, setData] = useState<any>(null);
  const [settings, setSettings] = useState<MatrixSettings | null>(null);
  const maxProp = useMemo(() => (data ? Object.keys(data?.point_coordinates.points).length - 1 : 0), [data]);
  const [currentProp, setCurrentProp] = useState(0);

  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver(() => {
      if (containerRef.current) {
        const { clientWidth } = containerRef.current;
        const width = clientWidth - 257 - 62;
        const height = Math.min((width / 4) * 3, document.documentElement.clientHeight - 53 - 62);
        setDimensions({ width, height });
      }
    });

    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const getPropName = useCallback((value: number) => Object.keys(data?.point_coordinates.points || {})[value], [data]);

  const getCellTextPosition = useCallback(
    (x: number, y: number) => {
      if (!settings) return { x: 0, y: 0 };
      const { chartGridPos, chartGridCellWidth, chartGridCellHeight } = settings;
      return {
        x: chartGridPos + x * chartGridCellWidth + chartGridCellWidth / 2,
        y: chartGridPos + y * chartGridCellHeight + chartGridCellHeight / 2,
      };
    },
    [settings]
  );

  const getPointPosition = useCallback(
    (x: number, y: number) => {
      if (!settings) return { x: 0, y: 0 };
      const { chartPosX, chartPosY, chartGridCellWidth, chartGridCellHeight, chartCellCountX, chartCellCountY } =
        settings;
      const chartWidth = chartGridCellWidth * chartCellCountX;
      const chartHeight = chartGridCellHeight * chartCellCountY;
      const chartOffsetX = (chartWidth / 100) * x;
      const chartOffsetY = (chartHeight / 100) * y;
      return {
        x: chartPosX + chartOffsetX,
        y: chartPosY + chartOffsetY,
      };
    },
    [settings]
  );

  const addPointTooltip = useCallback(
    (points: d3.Selection<SVGCircleElement, any, SVGElement, any>) => {
      if (!settings?.tooltip) return;
      points
        .on('mouseenter', function (e) {
          d3.select(this).attr('opacity', '0.6');
          settings.tooltip.transition().duration(200).style('opacity', 1);
          const text = `Asset: ${this.dataset.name}, Utilization: ${this.dataset.utilization}`;
          settings.tooltip
            .html(text)
            .style('left', e.pageX + 'px')
            .style('top', e.pageY - 10 + 'px');
        })
        .on('mouseleave', function () {
          d3.select(this).attr('opacity', '1');
          settings.tooltip.transition().duration(500).style('opacity', 0);
        });
    },
    [settings?.tooltip]
  );

  const addChartPoints = useCallback(
    (prop: string) => {
      if (!settings || !data) return;
      const { pointsContainer } = settings;
      const colorMap = { red: '#B92B27', green: '#8A3D9E', blue: '#1565C0' };
      const radiusScale = d3.scaleThreshold().domain([30, 70]).range([4, 6, 8]);

      pointsContainer.selectAll('.matrix-point-circle').remove();

      const points = pointsContainer
        .selectAll('.matrix-point-circle')
        .data(data.point_coordinates.points[prop])
        .enter()
        .append('circle')
        .classed('matrix-point-circle', true)
        .attr('r', (d: any) => radiusScale(d.size))
        .attr('fill', (d: any) => (colorMap as any)[d.colour])
        .attr('cx', (d: any) => getPointPosition(d.x, d.y).x)
        .attr('cy', (d: any) => getPointPosition(d.x, d.y).y)
        .attr('stroke', 'white')
        .attr('stroke-width', 2)
        .attr('data-name', (d: any) => d.label)
        .attr('data-utilization', (d: any) => d.size)
        .style('cursor', 'pointer');

      addPointTooltip(points);
    },
    [addPointTooltip, getPointPosition, settings, data]
  );

  const addAxisLabels = useCallback(() => {
    if (!settings || !data) return;
    const { axisLabelsContainer, axisLabelXPos, axisLabelYPos, margin } = settings;

    // x axis label
    axisLabelsContainer
      .append('text')
      .classed('matrix-chart-axis-label', true)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('x', axisLabelXPos)
      .attr('y', margin)
      .text(data.point_coordinates.x_label);

    // y axis label
    axisLabelsContainer
      .append('text')
      .classed('matrix-chart-axis-label', true)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('x', axisLabelYPos)
      .attr('y', margin)
      .text(data.point_coordinates.y_label);
  }, [settings, data]);

  const addChartGrid = useCallback(() => {
    if (!settings) return;
    const {
      gridContainer,
      chartGridPos,
      chartGridWidth,
      chartGridHeight,
      chartGridCellWidth,
      chartGridCellHeight,
      chartGridCountX,
      chartGridCountY,
    } = settings;

    // outer border
    gridContainer
      .append('rect')
      .attr('x', chartGridPos)
      .attr('y', chartGridPos)
      .attr('width', chartGridWidth)
      .attr('height', chartGridHeight)
      .attr('rx', 9.5)
      .attr('stroke', '#F1F6FF')
      .attr('fill', 'transparent');

    // x axis lines
    for (const i of [...Array(chartGridCountX - 1).keys()].map(x => x + 1)) {
      gridContainer
        .append('line')
        .attr('x1', chartGridPos)
        .attr('x2', chartGridPos + chartGridWidth)
        .attr('y1', chartGridPos + i * chartGridCellHeight)
        .attr('y2', chartGridPos + i * chartGridCellHeight)
        .attr('stroke', '#F1F6FF');
    }

    // y axis lines
    for (const i of [...Array(chartGridCountY - 1).keys()].map(x => x + 1)) {
      gridContainer
        .append('line')
        .attr('x1', chartGridPos + i * chartGridCellWidth)
        .attr('x2', chartGridPos + i * chartGridCellWidth)
        .attr('y1', chartGridPos)
        .attr('y2', chartGridPos + chartGridHeight)
        .attr('stroke', '#F1F6FF');
    }
  }, [settings]);

  const addChartCells = useCallback(() => {
    if (!settings || !data) return;
    const {
      cellsContainer,
      chartCellCountX,
      chartCellCountY,
      chartPosX,
      chartPosY,
      chartGridCellMargin,
      chartGridCellWidth,
      chartGridCellHeight,
      chartCellWidth,
      chartCellHeight,
    } = settings;

    const valueRange = [1, chartCellCountX + chartCellCountY];
    const valueDomain = [valueRange[0], d3.mean(valueRange)!, valueRange[1]];
    const colorScale = d3.scaleLinear<string>().domain(valueDomain).range(['#63be7b', '#ffe984', '#f8696b']);

    for (const x of [...Array(chartCellCountX).keys()]) {
      for (const y of [...Array(chartCellCountY).keys()]) {
        const textPosition = getCellTextPosition(x + 1, y + 1);
        cellsContainer
          .append('rect')
          .attr('x', chartPosX + chartGridCellMargin + x * chartGridCellWidth)
          .attr('y', chartPosY + chartGridCellMargin + y * chartGridCellHeight)
          .attr('width', chartCellWidth)
          .attr('height', chartCellHeight)
          .attr('rx', 4.5)
          .attr('stroke', '#FFF')
          .attr('fill', colorScale(x + y + 2));

        cellsContainer
          .append('text')
          .attr('fill', '#ffffff')
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .attr('x', textPosition.x)
          .attr('y', textPosition.y)
          .text(data.point_coordinates.cells[y * chartCellCountX + x].sum);
      }
    }
  }, [getCellTextPosition, settings, data]);

  const addChartLabels = useCallback(() => {
    if (!settings || !data) return;
    const { labelsContainer, chartCellCountX, chartCellCountY } = settings;
    const labelsX = [data.point_coordinates.currency, ...[...Array(chartCellCountX).keys()].map(i => 'HI' + i), 'Σ'];
    const labelsY = [...[...Array(chartCellCountY).keys()].map(i => 'C' + i), 'Σ'];

    for (const [i, label] of labelsX.entries()) {
      const textPosition = getCellTextPosition(i, 0);
      labelsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }

    for (const [i, label] of labelsY.entries()) {
      const textPosition = getCellTextPosition(0, i + 1);
      labelsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }
  }, [getCellTextPosition, settings, data]);

  const addChartSums = useCallback(() => {
    if (!settings || !data) return;
    const { sumsContainer, chartGridCountY, chartGridCountX } = settings;
    const groupX = Array.from(d3.group(data.point_coordinates.cells, (d: any) => d.x));
    const groupY = Array.from(d3.group(data.point_coordinates.cells, (d: any) => d.y));
    const sumX = groupX.map(([_, x]) => d3.sum(x.map((x: any) => x.sum)).toFixed(1));
    const sumY = groupY.map(([_, y]) => d3.sum(y.map((y: any) => y.sum)).toFixed(1));

    for (const [i, label] of sumX.entries()) {
      const textPosition = getCellTextPosition(i + 1, chartGridCountY - 1);
      sumsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }

    for (const [i, label] of sumY.entries()) {
      const textPosition = getCellTextPosition(chartGridCountX - 1, i + 1);
      sumsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }
  }, [getCellTextPosition, settings, data]);

  useEffect(() => {
    setData(null);
    AssetLifeAPI.put('intervention/risk_matrix_intervention', {
      aggregation_level: 'asset_class',
      risk_type: 'All',
      y_bins: 6,
      x_bins: 6,
      assets: null,
      polygon_selected: null,
    }).then(action => setData(action.data));
  }, []);

  useEffect(() => {
    if (!data) return;

    const chart = d3.select(containerRef.current).select('#MatrixSVG');

    d3.selectAll('.matrix-tooltip').remove();
    chart.classed('matrix-chart-container', true).selectAll('*').remove();

    const gridContainer = chart.append<SVGElement>('g').classed('matrix-chart-grid', true);
    const cellsContainer = chart.append<SVGElement>('g').classed('matrix-chart-cells', true);
    const axisLabelsContainer = chart.append<SVGElement>('g').classed('matrix-axis-labels', true);
    const labelsContainer = chart.append<SVGElement>('g').classed('matrix-chart-labels', true);
    const sumsContainer = chart.append<SVGElement>('g').classed('matrix-chart-sums', true);
    const pointsContainer = chart.append<SVGElement>('g').classed('matrix-chart-points', true);
    const tooltip = d3.select('body').append('div').classed('matrix-tooltip', true);

    const margin = 20;
    const axisLabelMargin = 10;

    const chartGridPos = margin + axisLabelMargin + 15;
    const chartGridWidth = dimensions.width - chartGridPos - margin;
    const chartGridHeight = dimensions.height - chartGridPos - margin;

    const axisLabelXPos = chartGridWidth / 2 + margin + axisLabelMargin + 15;
    const axisLabelYPos = (chartGridHeight / 2 + margin + axisLabelMargin + 15) * -1;

    const chartGridCountX = 8;
    const chartGridCountY = 8;

    const chartGridCellWidth = chartGridWidth / chartGridCountX;
    const chartGridCellHeight = chartGridHeight / chartGridCountY;
    const chartGridCellMargin = 1;

    const chartPosX = chartGridPos + chartGridCellWidth;
    const chartPosY = chartGridPos + chartGridCellHeight;

    const chartCellCountX = 6;
    const chartCellCountY = 6;

    const chartCellWidth = chartGridCellWidth - chartGridCellMargin * 2;
    const chartCellHeight = chartGridCellHeight - chartGridCellMargin * 2;

    setSettings({
      chart,
      pointsContainer,
      axisLabelsContainer,
      gridContainer,
      cellsContainer,
      labelsContainer,
      sumsContainer,
      tooltip,
      margin,
      axisLabelMargin,
      chartGridPos,
      chartGridWidth,
      chartGridHeight,
      axisLabelXPos,
      axisLabelYPos,
      chartGridCountX,
      chartGridCountY,
      chartGridCellWidth,
      chartGridCellHeight,
      chartGridCellMargin,
      chartPosX,
      chartPosY,
      chartCellCountX,
      chartCellCountY,
      chartCellWidth,
      chartCellHeight,
    });
  }, [data, dimensions]);

  useEffect(() => {
    if (!settings) return;
    addAxisLabels();
    addChartGrid();
    addChartCells();
    addChartLabels();
    addChartSums();
  }, [settings, addAxisLabels, addChartGrid, addChartCells, addChartLabels, addChartSums]);

  useEffect(() => {
    if (!settings) return;
    addChartPoints(getPropName(currentProp));
  }, [settings, currentProp, addChartPoints, getPropName]);

  if (!data) return <Spinner isInFullHeightContainer />;
  return (
    <ContentContainer className="border-top-0 d-flex" ref={containerRef}>
      <ToolTipStyles />
      <ContentCard>
        <StyledMatrix id="MatrixSVG" width={dimensions.width} height={dimensions.height}></StyledMatrix>
      </ContentCard>
      <ContentCard className="ml-3 align-self-start pt-4 pb-4">
        <RangeSlider
          initValue={currentProp}
          min={0}
          max={maxProp}
          onChange={setCurrentProp}
          tooltipLabel={getPropName}
          className="w-100 pt-3"
        />
        <img className="mt-1" src={ColorLegend} alt="" draggable={false} />
        <StyledSizeLegend src={SizeLegend} alt="" draggable={false} />
      </ContentCard>
    </ContentContainer>
  );
};

const StyledSizeLegend = styled.img`
  position: relative;
  left: 2px;
`;

const StyledMatrix = styled.svg`
  font-size: 15px;
  text-transform: uppercase;
  user-select: none;

  .matrix-chart-axis-label {
    fill: #353a3f;
  }
`;

const ToolTipStyles = createGlobalStyle`
  .matrix-tooltip {
    fill: #333;
    position: absolute;
    padding: 5px 10px;
    font-size: 13px;
    background: white;
    border-radius: 8px;
    pointer-events: none;
    z-index: 99999;
    transform: translate(-50%, -100%);
    opacity: 0;
    box-shadow: 0px 6px 10px 2px rgb(50 50 71 / 8%), 0px 4px 8px rgb(50 50 71 / 6%);
  }
`;

export default Matrix;
