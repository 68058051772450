import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

export enum ChartTypeKeys {
  TR_LOADING = 'TR_LOADING',
  TR_OVERLOADING = 'TR_OVERLOADING',
  CABLES_LOADING = 'CABLES_LOADING',
  CABLES_OVERLOADING = 'CABLES_OVERLOADING',
  INVESTMENTS = 'INVESTMENTS',
  DETAILED_EMISSIONS = 'DETAILED_EMISSIONS',
  DER_PHASE_IN_PROFILE = 'DER_PHASE_IN_PROFILE',
}

export interface ChartTypesOption {
  key: ChartTypeKeys;
  index?: number;
  asset_class?: 'Transformer' | 'Cable';
  value: string;
}

export const ChartTypes: {
  [key in ChartTypeKeys]: ChartTypesOption;
} = {
  [ChartTypeKeys.TR_LOADING]: {
    key: ChartTypeKeys.TR_LOADING,
    index: 1,
    asset_class: 'Transformer',
    value: 'Transformers - mean loading',
  },
  [ChartTypeKeys.TR_OVERLOADING]: {
    key: ChartTypeKeys.TR_OVERLOADING,
    index: 0,
    asset_class: 'Transformer',
    value: 'Transformers - overloading',
  },
  [ChartTypeKeys.CABLES_LOADING]: {
    key: ChartTypeKeys.CABLES_LOADING,
    index: 1,
    asset_class: 'Cable',
    value: 'Cables - mean loading',
  },
  [ChartTypeKeys.CABLES_OVERLOADING]: {
    key: ChartTypeKeys.CABLES_OVERLOADING,
    index: 0,
    asset_class: 'Cable',
    value: 'Cables - overloading',
  },
  [ChartTypeKeys.INVESTMENTS]: { key: ChartTypeKeys.INVESTMENTS, value: 'Investments' },
  [ChartTypeKeys.DETAILED_EMISSIONS]: { key: ChartTypeKeys.DETAILED_EMISSIONS, value: 'Detailed emissions' },
  [ChartTypeKeys.DER_PHASE_IN_PROFILE]: { key: ChartTypeKeys.DER_PHASE_IN_PROFILE, value: 'DER phase-in profile' },
};

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string;
}

const SelectChartType: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();

  const { options, selectValue } = useMemo(
    () =>
      Object.values(ChartTypes).reduce(
        (acc: any, option) => {
          const item = { ...option, label: getIntl(option.value) };
          if (value === option.value) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, getIntl]
  );

  return (
    <FormReactSelect variant="small" {...props} mutedTextLabelKey="Chart type" value={selectValue} options={options} />
  );
};

export default SelectChartType;
