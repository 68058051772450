import React from 'react';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';
import { numberSeparatedBySpaces } from 'utils/map';

interface Props extends React.HTMLAttributes<HTMLElement> {
  text: string | number;
}

const LayerElementsCount: React.FC<Props> = ({ text, children, ...rest }) => {
  const { getIntl } = useLocale();
  if (!text) return <>{children}</>;
  return (
    <OverlayTriggerTooltip
      placement="top"
      overlayId="countHint"
      overlayChildren={
        <>
          {getIntl('Quantity of assets')}: {numberSeparatedBySpaces(text)}
        </>
      }
    >
      <div {...rest}>{children}</div>
    </OverlayTriggerTooltip>
  );
};

export default LayerElementsCount;
