import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { employeesCountSelector, employeesSelector } from 'modules/employees/selectors';
import { fetchEmployeesAction, deleteEmployeeAction } from 'modules/employees';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const EmployeesTable: React.FC = () => {
  const { dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(employeesCountSelector);
  const items = useSelector(employeesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchEmployeesAction(params)).then(
        (action: Shared.ReduxAction<{ employeesHash: Type.Hash<Employees.Item> }>) =>
          Object.values(action.payload?.employeesHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ employeeModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteEmployeeAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.EMPLOYEES}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map((item: Employees.Item) => (
        <tr key={item.id}>
          <td>{item.fullName}</td>
          <td>{item.email}</td>
          <td>{dateFormat(item.startDate)}</td>
          <td>{dateFormat(item.endDate)}</td>
          <td>{numberFormat(item.minutes / 60)}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default EmployeesTable;
