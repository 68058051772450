import React, { useCallback } from 'react';
import { OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEventPreventedExec } from 'hooks';
import { initPagination } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { Button, SelectTaskAppAction, StringsInputAutocomplete } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import DateRangeFilter from './DateRangeFilter';
import YearFilter from './YearFilter';
import SelectTypeFilter from './SelectTypeFilter';
import SelectUsersFilter from './SelectUsersFilter';
import SelectTaskStatusFilter from './SelectTaskStatusFilter';
import { PaginationType, DataTableFilterKeys } from 'constants/index';
import { IconFilter } from '@utiligize/shared/resources';

interface Props {
  paginationType: Type.PaginationType;
}

const Filters: React.FC<Props> = ({ paginationType }) => {
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));

  const formik = useConfiguredFormik({ paginationType, filters: filters as Layouts.Filters });
  const { values, setFieldValue, submitForm, setValues, resetForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const hasFilters = Object.values(filters!).some(v => v);
  const hasValues = Object.values(values).some(v => v);

  const handleResetFormButtonClick = React.useCallback(() => {
    setValues({ ...initPagination[paginationType].filters });
    submitForm();
  }, [setValues, paginationType, submitForm]);

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, meta: any) => {
      setFieldValue(meta.name, value?.value || null);
    },
    [setFieldValue]
  );

  // wait until Popover close animation will be performed
  const handleResetFormWithDelay = useCallback(() => setTimeout(resetForm, 500), [resetForm]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      onToggle={handleResetFormWithDelay}
      overlay={
        <Popover id="0" style={{ minWidth: '300px' }}>
          <Popover.Content>
            <Form onSubmit={handleFormSubmit}>
              {values.hasOwnProperty(DataTableFilterKeys.ASSET_CODE) && (
                <Form.Group>
                  <StringsInputAutocomplete
                    autoFocus
                    labelKey="Asset code"
                    values={[values.assetCode].filter(Boolean) as string[]}
                    onChange={(value: string[] | null) =>
                      setFieldValue(DataTableFilterKeys.ASSET_CODE, value?.[0] || null)
                    }
                  />
                </Form.Group>
              )}
              {values.hasOwnProperty(DataTableFilterKeys.TYPE) && paginationType !== PaginationType.EMPLOYEES && (
                <SelectTypeFilter values={values} setFieldValue={setFieldValue} submitForm={submitForm} />
              )}
              {values.hasOwnProperty(DataTableFilterKeys.STATUS) && paginationType !== PaginationType.EMPLOYEES && (
                <SelectTaskStatusFilter
                  values={values}
                  paginationType={paginationType}
                  setFieldValue={setFieldValue}
                  submitForm={submitForm}
                />
              )}
              {values.hasOwnProperty(DataTableFilterKeys.YEAR) && (
                <YearFilter
                  values={values}
                  setValues={setValues}
                  submitForm={submitForm}
                  paginationType={paginationType}
                  isClearable={![PaginationType.TOTAL_HOURS, PaginationType.EMPLOYEES].includes(paginationType)}
                />
              )}
              {values.hasOwnProperty(DataTableFilterKeys.USER_IDS) && (
                <SelectUsersFilter
                  values={values}
                  setFieldValue={setFieldValue}
                  submitForm={submitForm}
                  paginationType={paginationType}
                />
              )}
              {values.hasOwnProperty(DataTableFilterKeys.APP_ACTION) && (
                <Form.Group>
                  <SelectTaskAppAction
                    name={DataTableFilterKeys.APP_ACTION}
                    actions={values.appAction || null}
                    onChange={handleSelectChange}
                    isClearable
                    isMulti
                    variant="small"
                    isSearchable={false}
                  />
                </Form.Group>
              )}
              {values.hasOwnProperty(DataTableFilterKeys.START_TIME) && (
                <DateRangeFilter values={values} setValues={setValues} submitForm={submitForm} />
              )}
              <div className="text-right">
                <Button
                  type="button"
                  labelKey="Reset"
                  className="mr-2"
                  onClick={handleResetFormButtonClick}
                  disabled={!hasValues}
                  variant="primary-outline"
                />
                <Button
                  type="submit"
                  labelKey="Apply"
                  variant="primary"
                  disabled={JSON.stringify(filters) === JSON.stringify(values)}
                />
              </div>
            </Form>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        labelKey={hasFilters ? 'Change filters' : 'Add filters'}
        className="ml-2"
        icon={<IconFilter />}
        variant="primary-outline"
      />
    </OverlayTrigger>
  );
};

export default Filters;
