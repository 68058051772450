import Highcharts from 'highcharts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { summaryPlanCurrencySelector, summaryPlanItemsSelector } from 'modules/investment/selectors';
import Chart from 'components/_charts/Chart';

interface ChartDataProps {
  series: Omit<Shared.SeriesOptionsType, 'type'>[];
  categories: number[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartSummary: React.FC<{ summaryGroupIds: Layouts.Filters['summaryGroupIds'] }> = ({ summaryGroupIds }) => {
  const { getIntl, numberFormat } = useLocale();
  const items = useSelector(summaryPlanItemsSelector);
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const { currency, rate } = useSelector(summaryPlanCurrencySelector);

  const SummaryGroupIdsMap: { [key: string]: keyof Investment.SummaryPlanItem } = useMemo(
    () => ({
      'g.asset_register_category': 'asset_register_category',
      'a.replacement_reason': 'replacement_reason',
      primary_substation_id: 'name',
      grid_zone_id: 'description',
      'a.voltage_id': 'voltage_level_text',
      'a.portfolio_id': 'department',
    }),
    []
  );

  const cartesian = useCallback((args: string[][]) => {
    if (!args?.length) return args;
    const r: any = [],
      max = args.length - 1;
    function helper(arr: any, i: any) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  }, []);

  useEffect(() => {
    if (!items) return;

    // 1. Find all available, unique chart categories => [2024, 2025, etc]
    const categories = [...new Set(items.map(item => item.suggested_replacement_year))];

    // 2. Find all available values per each group by applied filter
    const hash = summaryGroupIds?.reduce((acc: any, id: string) => {
      acc[id] = [...new Set(items.map(i => i[SummaryGroupIdsMap[id]]).filter(Boolean))];
      return acc;
    }, {});

    // 3. Convert it to array of arrays
    const seriesValues = cartesian(Object.values(hash || {}));

    // 4. Create series array
    const series = summaryGroupIds?.length
      ? seriesValues // 4.1 map through the values
          .map((values: string[]) => ({
            name: values.join(), // dummy hidden name
            // 4.2 data => array of number per each category
            data: categories.map(year =>
              items.reduce((acc, item) => {
                if (item.suggested_replacement_year !== year) return acc;
                // 4.3 apply value only if all columns match group by filters
                const allFilterValuesMatch = summaryGroupIds?.every(
                  (id, index) => item[SummaryGroupIdsMap[id]] === values[index]
                );
                if (!allFilterValuesMatch) return acc;
                return item.replacement_cost / rate;
              }, 0)
            ),
          }))
          // 4.4 filter out all series where data array contains only zeros
          .filter((item: any) => item.data.filter(Boolean).length)
      : [
          {
            name: getIntl('Investment cost ({{currency}})', { currency }),
            data: items.map(item => item.replacement_cost / rate),
          },
        ];

    setChartData({
      series,
      categories,
      title: getIntl('Yearly summary'),
      yAxisTitle: getIntl('Investment cost ({{currency}})', { currency }),
      xAxisTitle: getIntl('Investment year'),
    });
    // Note. Skip unnecessary properties since table update will cause items re-render anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, getIntl, SummaryGroupIdsMap, cartesian, rate]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: 'column',
        zoomType: 'y',
      },
      title: {
        text: chartData?.title,
      },
      xAxis: {
        title: {
          text: chartData?.xAxisTitle,
        },
        categories: chartData?.categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: chartData?.yAxisTitle,
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numberFormat(this.total, { minimumFractionDigits: 2 });
          },
        },
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          const messages = [];
          const item = items.find(
            item => item.suggested_replacement_year === this.x && item.replacement_cost / rate === this.y
          );
          if (item?.replacement_cost) {
            messages.push(
              `${getIntl('Investment cost ({{currency}})', { currency })}: ${numberFormat(
                item.replacement_cost / rate,
                { minimumFractionDigits: 2 }
              )}`
            );
          }
          if (item?.asset_register_category) {
            messages.push(`${getIntl('Asset category')}: ${item.asset_register_category}`);
          }
          if (item?.replacement_reason) {
            messages.push(`${getIntl('Investment reason')}: ${item.replacement_reason}`);
          }
          if (item?.description) {
            messages.push(`${getIntl('Grid zone')}: ${item.description}`);
          }
          if (item?.voltage_level_text) {
            messages.push(`${getIntl('Voltage level')}: ${item.voltage_level_text}`);
          }
          if (item?.department) {
            messages.push(`${getIntl('Portfolio')}: ${item.department}`);
          }
          if (item?.name) {
            messages.push(`${getIntl('Primary substation')}: ${item.name}`);
          }

          return messages.join('<br />');
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: chartData?.series,
      legend: {
        enabled: false,
      },
    }),
    // Note. Skip unnecessary properties since table update will cause items re-render anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  ) as Highcharts.Options;

  return <Chart options={options} dataMarker="chart_investment_yearly_summary" />;
};

export default ChartSummary;
