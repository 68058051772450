import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { maintenancePlansModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { maintenancePlanSelectorFactory } from 'modules/maintenance/selectors';
import {
  Modal,
  Button,
  FormInput,
  SelectAssetCategories,
  SelectTimestampOptions,
  SelectMaintenanceMissingAction,
  HiddenFormSubmit,
  Checkbox,
} from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const MaintenancePlanModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(maintenancePlansModalIdSelector);
  const maintenancePlan: Maintenance.Plan | undefined = useSelector(maintenancePlanSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && maintenancePlan);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ maintenancePlansModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldTouched,
    setValues,
    setFieldValue,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!maintenancePlan) return;
    setValues({
      planName: maintenancePlan.planName,
      assetCategory: maintenancePlan.assetCategory,
      dateSelect: maintenancePlan.dateSelect,
      date_explicit: Boolean(maintenancePlan.date_explicit),
      missingAction: maintenancePlan.missingAction,
      intervalYears: maintenancePlan.intervalYears,
    });
    setShow(true);
  }, [maintenancePlan, setValues]);

  const handleTimestampSelectChange = useCallback(
    (timestampOptions: string[]) => {
      setFieldValue('dateSelect', timestampOptions?.[0]);
    },
    [setFieldValue]
  );

  const handleSelectAssetCategoriesChange = useCallback(
    (v: any) => {
      setFieldValue('assetCategory', v.value);
      setFieldValue('dateSelect', '');
    },
    [setFieldValue]
  );

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { name, checked: date_explicit } = event.target as HTMLInputElement;
      setFieldValue(name, date_explicit);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create plan" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit plan' : 'Create plan'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Plan name"
              name="planName"
              value={values.planName}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.planName && errors.planName) ? (errors.planName as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectAssetCategories
              value={values.assetCategory}
              onChange={handleSelectAssetCategoriesChange}
              onBlur={() => setFieldTouched('assetCategory')}
              errorKey={Boolean(touched.assetCategory && errors.assetCategory) ? (errors.assetCategory as string) : ''}
              excludeSyntheticOptions
            />
          </Form.Group>
          <Form.Group>
            <SelectTimestampOptions
              disabled={!values.assetCategory}
              errorKey={Boolean(touched.dateSelect && errors.dateSelect) ? (errors.dateSelect as string) : ''}
              timestampOptions={values.dateSelect ? [values.dateSelect] : []}
              setValues={handleTimestampSelectChange}
              onBlur={() => setFieldTouched('dateSelect')}
              assetCategoryCode={values.assetCategory}
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              className="pl-0 icheck-primary"
              name="date_explicit"
              checked={values.date_explicit}
              labelKey="Date explicit"
              onChange={handleCheckboxChange}
            />
          </Form.Group>
          <Form.Group>
            <SelectMaintenanceMissingAction
              value={values.missingAction}
              onChange={(v: any) => setFieldValue('missingAction', v.value)}
              onBlur={() => setFieldTouched('missingAction')}
              errorKey={Boolean(touched.missingAction && errors.missingAction) ? (errors.missingAction as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              min={1}
              type="number"
              labelKey="Intervals (years)"
              name="intervalYears"
              value={values.intervalYears || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.intervalYears && errors.intervalYears) ? (errors.intervalYears as string) : ''}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default MaintenancePlanModal;
