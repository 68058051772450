import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPermissionsGroupsAction } from 'modules/permissions';
import { permissionsGroupsSelector } from 'modules/permissions/selectors';
import { FormReactSelect } from 'components/_common';

interface Props {
  disabled?: boolean;
  isMulti?: true | undefined;
  isClearable?: true | undefined;
  labelKey?: string;
  errorKey?: string;
  ids: number[];
  setIds: (value: number[]) => void;
  onBlur?: () => void;
}

const SelectPermissionsGroup: React.FC<Props> = ({
  disabled = false,
  isMulti,
  labelKey = 'Groups',
  ids = [],
  setIds,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const permissionsGroups = useSelector(permissionsGroupsSelector);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchPermissionsGroupsAction({ skipPagination: true, skipFilters: true })).then(() => setIsLoading(false));
  }, [dispatch]);

  const { options, value } = useMemo(
    () =>
      permissionsGroups.reduce(
        (acc: any, group: Permissions.Group) => {
          const item = { value: group.id, label: group.name };
          if (ids.includes(group.id)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [ids, permissionsGroups]
  );

  const handleSelectChange = useCallback(
    (permissionsGroups: Type.SelectOption[]) => {
      setIds(isMulti ? permissionsGroups?.map(i => i.value) || [] : [(permissionsGroups as any)?.value]);
    },
    [isMulti, setIds]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      isDisabled={isLoading || disabled}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholderKey="Select group"
      variant="small"
      blurInputOnSelect
    />
  );
};

export default SelectPermissionsGroup;
