import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useLocale, useToggle, useBuilderState } from 'hooks';
import { useDispatch } from 'react-redux';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { FieldControls, BuilderFieldsRenderer } from 'components/_builder';
import { Button, Checkbox } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformFormField } from 'utils';
import { FIELDS, MAX_FIELDS, BuilderFieldsTypes } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

interface Props {
  isSecondChildField?: boolean;
  isFirstField?: boolean;
  field: Builder.Field;
  displayedNumber: string;
  addField: (formField: Builder.Field) => void;
  changeField: (field: Builder.Field) => void;
  delField: (index: number) => void;
}

const TwoOptionsChildField: React.FC<Props> = ({
  isSecondChildField = false,
  isFirstField = false,
  field,
  displayedNumber,
  addField,
  changeField,
  delField,
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [showModal, toggleModal] = useToggle();
  const { fieldTypes, selectValue } = useBuilderState();

  const addSecondChild = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      const idx = Number((event.target as HTMLButtonElement).getAttribute('data-option-index'));
      const nextField: Builder.Field = _cloneDeep(field);

      if (!nextField.params[1].variants?.[idx]?.field) {
        throw Error("TwoOptionsFirstChildField nextField.params[1].variants?.[idx].field doesn't exist");
      }

      const fields = nextField.params[1].variants[idx].field!;
      if (fields?.length === MAX_FIELDS) return alert('Max fields limit reached');

      // Use incremented index in case if array isn't empty,
      // for 1000 -> 2000 and 3000 as default indexes
      // for 4000 -> 5000 and 6000 as default indexes
      const index = fields?.length ? fields[fields.length - 1].index + 1 : nextField.index + 1000 + 1000 * idx;
      const newField: Builder.Field = {
        ...FIELDS[BuilderFieldsTypes.Text],
        indexBlock: nextField.indexBlock,
        index,
        id: index,
        parentId: nextField.id,
        fieldtypeId: fieldTypes.filter(type => type.name === BuilderFieldsTypes.Text)[0].id,
      };
      nextField.params[1].variants[idx].field!.push(newField);
      changeField(nextField);
    },
    [field, fieldTypes, changeField]
  );

  const handleDeleteChildField = useCallback(
    async (id: number) => {
      const nextField: Builder.Field = _cloneDeep(field);
      nextField.params?.forEach(itemParam => {
        itemParam.variants?.forEach(itemVariant => {
          if (itemVariant.field) {
            (itemVariant.field as Builder.Field[])?.forEach((itemField, idx) => {
              if (Number(itemField.id) === id) {
                (itemVariant.field as Builder.Field[])?.splice(idx, 1);
              }
            });
          }
        });
      });

      nextField.params[1]?.variants?.forEach((variant, index) => {
        if (!(variant.field as Builder.Field[]).length) {
          (nextField.checked as boolean[])[index] = false;
        }
      });
      changeField(nextField);
    },
    [field, changeField]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      if (showModal) toggleModal(event);
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => delField(field.id),
        })
      );
    },
    [showModal, toggleModal, dispatch, delField, field.id]
  );

  const changeFieldType = useCallback(
    (selectedValue: { name: string; value: string; label: string }) => {
      const nextField = _cloneDeep(field);
      const newField = _cloneDeep((FIELDS as any)[selectedValue.name]);
      newField.type = selectedValue.name;
      newField.fieldtype = { name: selectedValue.name };
      newField.indexBlock = nextField.indexBlock;
      newField.index = nextField.index;
      newField.id = nextField.id;
      newField.title = '';
      newField.parentId = nextField.parentId;
      newField.fieldtypeId = fieldTypes.filter(type => type.name === newField.type)[0].id;
      newField.checked = [false, false];

      changeField(newField);
    },
    [field, fieldTypes, changeField]
  );

  const setSelectValue = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      const { value } = event.currentTarget as HTMLInputElement;
      const selectedValue = { name: value, label: value, value };
      changeFieldType(selectedValue);
    },
    [changeFieldType]
  );

  const handleChangeChildField = useCallback(
    (changedField: Builder.Field) => {
      const nextField: Builder.Field = _cloneDeep(field);
      nextField.params.forEach(itemParam => {
        itemParam.variants?.forEach(itemVariant => {
          itemVariant.field?.forEach((itemField, idx) => {
            if (itemField.id === changedField.id) {
              (itemVariant.field as Builder.Field[])[idx] = changedField;
            }
          });
        });
      });
      changeField(nextField);
    },
    [field, changeField]
  );

  const handleCheckBoxClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      const { checked } = event.target as HTMLInputElement;
      const index = Number((event.target as HTMLInputElement).getAttribute('data-index'));
      const nextField = _cloneDeep(field);
      nextField.checked[index] = checked;
      changeField(nextField);
    },
    [field, changeField]
  );

  const renderCheckbox = (idx: number) => {
    if (!field.params?.[1].variants?.[idx].field?.length) return null;
    return (
      <Checkbox
        dataIndex={idx}
        className="icheck-primary"
        checked={(field.checked as boolean[])[idx]}
        labelKey="New page"
        onChange={handleCheckBoxClick}
      />
    );
  };

  const renderVariants = (idx: number) => {
    return field.params.map(itemParam =>
      itemParam.variants?.[idx]?.field?.map((itemField, index: number) => {
        const number = idx ? Number(itemParam.variants?.[0]?.field?.length) + index : index;
        return (
          <TwoOptionsChildField
            isSecondChildField
            key={itemField.id}
            field={{ ...transformFormField(itemField), indexBlock: field.indexBlock }}
            displayedNumber={`${displayedNumber}.${number + 1}`}
            delField={handleDeleteChildField}
            changeField={handleChangeChildField}
            addField={addField}
          />
        );
      })
    );
  };

  const listTypeFields = useMemo(() => {
    if (!selectValue) return [field.fieldtype?.name];
    return selectValue.formtype.fgtns?.reduce((acc: Builder.FieldsTypes[], fgtn: any) => {
      fgtn.fgt.ftns.forEach((item: Builder.FTNS) => {
        acc.push(item.fieldtype.name);
      });
      return acc;
    }, []);
  }, [selectValue, field.fieldtype]);

  return (
    <>
      <div className="p-1 border mt-2 position-relative rounded">
        <FieldControls onEdit={toggleModal} onDelete={handleDeleteBtnClick} />
        <div className="ml-0 mr-4 pl-3 flex-column">
          <div className="mb-4">
            <span className="field-text font-weight-bold">{displayedNumber}</span>
            {field.title && <span className="field-text"> - {field.title}</span>}
          </div>
          <Form.Group>
            <Form.Control as="select" custom value={field.fieldtype?.name} onChange={setSelectValue}>
              {listTypeFields
                .filter(i => (isSecondChildField ? i !== BuilderFieldsTypes.TwoOption : i))
                .map(item => (
                  <option key={item} value={item}>
                    {getIntl(item!)}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </div>
        {!isSecondChildField && (
          <Row className="mx-0">
            <Col>{renderVariants(0)}</Col>
            <Col>{renderVariants(1)}</Col>
          </Row>
        )}
        {!isSecondChildField && field.type === BuilderFieldsTypes.TwoOption && isFirstField && (
          <Row className="mx-0">
            {[0, 1].map((index: number) => {
              const locale = field.params?.[1]?.variants?.[index]?.value;
              return (
                <Col key={index} className="p-3 d-flex justify-content-between align-items-center">
                  <div>
                    <Button
                      icon={<IconPlus />}
                      size="small"
                      labelKey={`${getIntl('Add')}${locale ? ` - ${locale}` : ''}`}
                      data-option-index={index}
                      onClick={addSecondChild}
                    />
                  </div>
                  {renderCheckbox(index)}
                </Col>
              );
            })}
          </Row>
        )}
      </div>
      {showModal && (
        <BuilderFieldsRenderer
          type={field.type}
          formField={field}
          onCloseModal={toggleModal}
          onChangeModal={changeField}
        />
      )}
    </>
  );
};
export default TwoOptionsChildField;
