import React from 'react';
import { Matrix } from 'components/_charts';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageMonetizedRisk: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isMonetizedRiskEnabled}>
    <Matrix />
  </PagePermissionsProvider>
);

export default PageMonetizedRisk;
