import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { componentPriceScenariosCountSelector, componentPriceScenariosSelector } from 'modules/setup/selectors';
import { fetchComponentPriceScenariosAction, deleteComponentPriceScenarioAction } from 'modules/setup';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const TableComponentPriceScenarios: React.FC = () => {
  const { getIntl, dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const paginationType = PaginationType.COMPONENT_PRICE_SCENARIOS;
  const count = useSelector(componentPriceScenariosCountSelector);
  const items = useSelector(componentPriceScenariosSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchComponentPriceScenariosAction(params)).then(
        (action: Shared.ReduxAction<{ componentPriceScenariosHash: Setup.Root['componentPriceScenariosHash'] }>) =>
          Object.values(action.payload?.componentPriceScenariosHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const description: string = event.currentTarget.getAttribute('data-description') || '';
      dispatch(
        setModalConfirmationMetaAction({
          title: getIntl('Do you want to delete the "{{description}}" scenario?', { description }),
          onConfirm: async () => dispatch(deleteComponentPriceScenarioAction(id)),
        })
      );
    },
    [dispatch, getIntl]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ componentPriceScenarioModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ componentPriceScenarioModalMeta: { id, isDuplicate: true } }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: Setup.ComponentPriceScenario) => (
        <tr key={item.id}>
          <td>{item.description || '-'}</td>
          <td>{numberFormat(item.price_evolution_percent, { fallback: '-' })}</td>
          <td>{item.sso_user || '-'}</td>
          <td>{item.created_at ? dateFormat(item.created_at) : '-'}</td>
          <td>{item.updated_at ? dateFormat(item.updated_at) : '-'}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.id}
              marginLeft
              onClick={handleDuplicateBtnClick}
              size="small"
            />
            <Button
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              data-description={item.description}
              marginLeft
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableComponentPriceScenarios;
