import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { forecastedScenariosCountSelector, forecastedScenariosSelector } from 'modules/setup/selectors';
import { fetchForecastedScenariosAction, deleteForecastedScenarioAction } from 'modules/setup';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdHashSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconLock, IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const TableForecastedScenarios: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(forecastedScenariosCountSelector);
  const items = useSelector(forecastedScenariosSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioIdHash = useSelector(scenarioIdHashSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchForecastedScenariosAction(params)).then(
        (action: Shared.ReduxAction<{ forecastedScenariosHash: Type.Hash<Setup.ForecastedScenario> }>) =>
          Object.values(action.payload?.forecastedScenariosHash || {})
      ),
    [dispatch]
  );

  const handleSelectScenarioType = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>): void => {
      const scenarioId: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(setLayoutAction({ scenarioIdHash: { ...scenarioIdHash, [portfolioId!]: scenarioId } }));
    },
    [scenarioIdHash, portfolioId, dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const description: string = event.currentTarget.getAttribute('data-description') || '';
      dispatch(
        setModalConfirmationMetaAction({
          title: getIntl('Do you want to delete the "{{description}}" scenario?', { description }),
          onConfirm: async () => dispatch(deleteForecastedScenarioAction(id)),
        })
      );
    },
    [dispatch, getIntl]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ overviewScenarioModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ overviewScenarioModalMeta: { id, isDuplicate: true } }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.FORECASTED_SCENARIOS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: Setup.ForecastedScenario) => (
        <StyledTr key={item.id} data-id={item.id} active={scenarioId === item.id} onClick={handleSelectScenarioType}>
          <td>{item.description || '-'}</td>
          <td>{item.user || '-'}</td>
          <td>{item.created_at ? dateFormat(item.created_at) : '-'}</td>
          <td>{item.updated_at ? dateFormat(item.updated_at) : '-'}</td>
          <td>
            {item.years.reduce((acc: string, year: number, index: number) => {
              if (!acc) acc += String(year);
              const nextYear = item.years[index + 1];

              if (nextYear - year === 1) {
                if (!acc.endsWith('-')) acc += '-';
                return acc;
              }

              if (acc.endsWith('-')) acc += String(year);
              if (nextYear) acc += `, ${String(nextYear)}`;
              return acc;
            }, '')}
          </td>
          <td className="text-right">
            {!item.read_only && (
              <Button
                tooltipKey="Edit"
                icon={<IconEdit />}
                data-id={item.id}
                onClick={handleEditBtnClick}
                size="small"
              />
            )}
            <Button
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.id}
              marginLeft
              onClick={handleDuplicateBtnClick}
              size="small"
            />
            <Button
              tooltipKey={!item.read_only ? 'Delete' : 'This is read only scenario'}
              icon={!item.read_only ? <IconDelete /> : <IconLock />}
              data-id={item.id}
              data-description={item.description}
              marginLeft
              onClick={!item.read_only ? handleDeleteBtnClick : () => null}
              size="small"
            />
          </td>
        </StyledTr>
      ))}
    </DataTable>
  );
};

const StyledTr = styled.tr<{ active: boolean }>`
  ${({ active, theme }) =>
    active
      ? `background-color: ${theme.colors.purple50}!important`
      : `&:hover {
          background-color: ${theme.colors.grey25};
          cursor: pointer;
        }`};
`;

export default TableForecastedScenarios;
