import React, { useMemo, useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioOptionsAction } from 'modules/options';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import { PageAppExceptions } from 'pages';
import { ViewAnalysisTool } from 'components/AnalysisTool';
import { Spinner } from 'components/_common';
import { Routes } from 'constants/index';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageAnalysisTool: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);

  const portfolioOptionsIds = useMemo(
    () => portfolioOptions?.map(option => option.value).filter(value => value !== portfolioId) || [],
    [portfolioOptions, portfolioId]
  );

  useEffect(() => {
    if (!portfolioOptionsIds.length) return;
    portfolioOptionsIds.forEach(id => dispatch(fetchScenarioOptionsAction(id)));
  }, [portfolioOptionsIds, dispatch]);

  const renderContent = () => {
    if (portfolioOptions?.length !== Object.keys(scenarioOptionsHash).length) {
      return <Spinner isInFullHeightContainer />;
    }
    return (
      <Switch>
        <Route exact path={Routes.AnalysisTool} component={ViewAnalysisTool} />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    );
  };

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isAnalysisToolEnabled}>
      <>{renderContent()}</>
    </PagePermissionsProvider>
  );
};

export default PageAnalysisTool;
