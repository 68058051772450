import React from 'react';
import { useSelector } from 'react-redux';
import {
  isNetworkLoadingCablesHistoricalLoadingDistributionTabActiveSelector,
  isNetworkLoadingCablesVoltageTabActiveSelector,
  isRouteActiveSelectorFactory,
} from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsCables from './TabsCables';
import TableFilters from './TableFilters';
import { PaginationType, Routes } from 'constants/index';

const ViewCables: React.FC = () => {
  const isScenariosTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NetworkLoadingCables));
  const isHistoricalLoadingDistributionTabActive = useSelector(
    isNetworkLoadingCablesHistoricalLoadingDistributionTabActiveSelector
  );
  const isVoltageTabActive = useSelector(isNetworkLoadingCablesVoltageTabActiveSelector);
  const isFutureMeanTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.FutureMean}`)
  );
  const isFutureOverloadedTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.FutureOverloaded}`)
  );
  const type = PaginationType.CABLES;
  return (
    <DataTableTemplate>
      <TableFilters
        type={type}
        hideScenariosYear={isFutureMeanTabActive || isFutureOverloadedTabActive}
        showFilterSolved={isScenariosTabActive}
        showVoltageDisplay={isScenariosTabActive}
        showPercentile={isScenariosTabActive || isHistoricalLoadingDistributionTabActive || isVoltageTabActive}
        showFlex={!isFutureMeanTabActive && !isFutureOverloadedTabActive}
      />
      <TabsCables type={type} />
    </DataTableTemplate>
  );
};

export default ViewCables;
