import styled from 'styled-components';
import React, { useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  investmentScenariosSelector,
  investmentScenariosMetaCurrencySelector,
  investmentScenariosHashSelector,
} from 'modules/setup/selectors';
import {
  fetchInvestmentScenariosAction,
  deleteInvestmentScenarioAction,
  updateInvestmentScenarioAction,
} from 'modules/setup';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { investmentScenarioIdSelector, portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button, Checkbox } from 'components/_common';
import { PaginationType, TableHeaders } from 'constants/index';
import { IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const TableInvestmentScenarios: React.FC = () => {
  const { getIntl, dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const paginationType = PaginationType.INVESTMENT_SCENARIOS;
  const items = useSelector(investmentScenariosSelector);
  const currency = useSelector(investmentScenariosMetaCurrencySelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const investmentScenarioId = useSelector(investmentScenarioIdSelector);
  const hash = useSelector(investmentScenariosHashSelector);
  const [loading, setLoading] = useState(false);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchInvestmentScenariosAction(params)).then(
        (action: Shared.ReduxAction<{ investmentScenariosHash: Setup.Root['investmentScenariosHash'] }>) =>
          Object.values(action.payload?.investmentScenariosHash || {})
      ),
    [dispatch]
  );

  const handleSelectInvestmentScenarioType = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>): void => {
      const investmentScenarioId: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(setLayoutAction({ investmentScenarioId }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const description: string = event.currentTarget.getAttribute('data-description') || '';
      dispatch(
        setModalConfirmationMetaAction({
          title: getIntl('Do you want to delete the "{{description}}" scenario?', { description }),
          onConfirm: async () => dispatch(deleteInvestmentScenarioAction(id)),
        })
      );
    },
    [dispatch, getIntl]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentScenarioModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentScenarioModalMeta: { id, isDuplicate: true } }));
    },
    [dispatch]
  );

  const handleLossOptimisationCheckboxClick = useCallback(
    (event: React.SyntheticEvent) => {
      const { checked: loss_optimisation } = event.target as HTMLInputElement;
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      const scenario: Setup.InvestmentScenario | undefined = hash[`_${id}_`];
      if (!scenario) return;
      setLoading(true);
      dispatch(
        (updateInvestmentScenarioAction as any)(
          {
            description: scenario.investment_description,
            forecasted_scenario_id: scenario.forecasted_scenario_id,
            component_price_scenario_id: scenario.component_price_scenario_id,
            interest_rate: scenario.interest_rate,
            sso_users_id: scenario.sso_users_id,
            loss_optimisation,
            investment_scenario_id: scenario.id,
          },
          { useOptimisticUpdate: true }
        )
      ).finally(() => setLoading(false));
    },
    [hash, dispatch]
  );

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[paginationType].map(header => {
        if (!header.titleKey?.includes('{{currency}}')) return header;
        return {
          ...header,
          title: getIntl(header.titleKey, { currency }),
        };
      }),
    ],
    [paginationType, currency, getIntl]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={items.length}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      customHeaders={customHeaders}
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: Setup.InvestmentScenario) => (
        <StyledTr
          key={item.id}
          data-id={item.id}
          $active={investmentScenarioId === item.id}
          onClick={handleSelectInvestmentScenarioType}
        >
          <td>{item.investment_description || '-'}</td>
          <td>{item.sso_user || '-'}</td>
          <td>{item.created_at ? dateFormat(item.created_at) : '-'}</td>
          <td>{item.updated_at ? dateFormat(item.updated_at) : '-'}</td>
          <td>{item.forecasted_scenario || '-'}</td>
          <td>{item.component_price_scenario || '-'}</td>
          <td>{item.interest_rate ?? '-'}</td>
          <td>{numberFormat(item.price_per_mwh, { minimumFractionDigits: 2, fallback: '-' })}</td>
          <td className="text-center">
            <Checkbox
              data-id={item.id}
              className="icheck-primary ml-2 position-relative"
              checked={item.loss_optimisation}
              onChange={handleLossOptimisationCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.id}
              marginLeft
              onClick={handleDuplicateBtnClick}
              size="small"
            />
            <Button
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              data-description={item.investment_description}
              marginLeft
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </StyledTr>
      ))}
    </DataTable>
  );
};

const StyledTr = styled.tr<{ $active: boolean }>`
  ${({ $active, theme }) =>
    $active
      ? `background-color: ${theme.colors.purple50}!important`
      : `&:hover {
          background-color: ${theme.colors.grey25};
          cursor: pointer;
        }`};
`;

export default TableInvestmentScenarios;
