import styled from 'styled-components';
import React, { useState, useEffect, useRef, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MapContext } from 'context/map';
import { setMapStateAction } from 'modules/map';
import { settingsSelector, showAddressSearchSelector } from 'modules/map/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import Controls from 'components/Map/Controls';
import AddressSearch from 'components/Map/common/AddressSearch';
import SetupImages from 'components/Map/core/SetupImages';
import SetupLayers from 'components/Map/core/SetupLayers';
import SetupEvents from 'components/Map/core/SetupEvents';
import SetupFilters from 'components/Map/core/SetupFilters';
import SetupScenarios from 'components/Map/core/SetupScenarios';
import SetupThemes from 'components/Map/core/SetupThemes';
import SetupSearch from 'components/Map/core/SetupSearch';
import { UserService } from '@utiligize/shared/services';
import { getStorageItem } from 'utils';
import { parseObject } from 'utils/map';
import { _throttle } from '@utiligize/shared/utils';
import { MapParams, StorageKeys } from 'constants/index';

declare global {
  interface Window {
    map: Map.MapboxMap | null;
  }
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

const MapContainer: React.FC = () => {
  const isDebugEnabled = localStorage.getItem('DEBUG_ENABLED');
  const dispatch: Shared.CustomDispatch = useDispatch();
  const history = useHistory<{
    n1Route: N1.Item['positionOnTheMap'];
    positiononthemap: DataQuality.Issue['positiononthemap'];
  }>();
  const settings = useSelector(settingsSelector);
  const showAddressSearch = useSelector(showAddressSearchSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const { map, setMap } = useContext(MapContext);
  const mapContainer = useRef<HTMLHeadingElement>(null);
  const [mapStyleLoad, setMapStyleLoad] = useState(false);

  useEffect(() => {
    if (!map) return;
    let previousIsLoading = true;

    const handleSourceData = _throttle(() => {
      const isLoading = !map?.areTilesLoaded?.();
      if (previousIsLoading === isLoading) return;
      previousIsLoading = isLoading;
      dispatch(setMapStateAction({ isLoading }));
    }, 500); // throttles to every 500ms

    map.on('sourcedata', handleSourceData);
    return () => {
      map.off('sourcedata', handleSourceData);
      handleSourceData.cancel();
    };
  }, [map, dispatch]);

  // init map
  useEffect(() => {
    if (!mapContainer.current || !settings.mapCenter) return;
    if (isDebugEnabled) console.info('MapContainer - map init process started');

    const map: Map.MapboxMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      maxZoom: MapParams.maxZoom - 0.1,
      minZoom: MapParams.minZoom,
      maxTileCacheSize: 100000,
      refreshExpiredTiles: true,
      center: settings.mapCenter,
      pitch: 0,
      bearing: 0,
      zoom: 9,
      antialias: true,
      attributionControl: false,
      transformRequest: url => {
        const request = { url } as mapboxgl.RequestParameters;
        if (url.startsWith(process.env.REACT_APP_API_URL!)) {
          const url = new URL(request.url);
          url.searchParams.set('version_id', String(settings.layerUpdate?.version ?? 0));
          url.searchParams.set('simulation_id', String(settings.layerUpdate?.simulation ?? 0));
          url.searchParams.set('portfolio_id', String(portfolioId));
          request.url = url.toString();
          request.headers = Object.fromEntries(
            Object.entries({
              Authorization: UserService.getToken(),
              tenant: getStorageItem<string>(StorageKeys.SELECTED_TENANT),
            }).filter(([_, v]) => Boolean(v))
          );
        }
        return request;
      },
    });
    map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
    map.getCanvas().style.cursor = map.__cursor = 'pointer';

    if (isDebugEnabled) window.map = map;

    const { n1Route, positiononthemap } = history.location.state || {};
    const bounds = parseObject(n1Route || positiononthemap)?.bound;

    if (bounds) {
      map.fitBounds(bounds as mapboxgl.LngLatBoundsLike, { padding: 200, linear: true, duration: 0 });
    }

    map.on('load', () => setMap(map));
    map.on('dragstart', () => (map.getCanvas().style.cursor = map.__cursor = 'grabbing'));
    map.on('dragend', () => (map.getCanvas().style.cursor = map.__cursor = 'pointer'));
    map.on('style.load', () => setMapStyleLoad(true));

    return () => {
      setMap(null);
      setMapStyleLoad(false);
      if (window.map) window.map = null;
      map.remove();
    };
  }, [dispatch, settings.mapCenter]); // eslint-disable-line

  useEffect(() => {
    if (!map || !mapContainer.current) return;
    const node = mapContainer.current;
    const resizeObserver = new ResizeObserver(() => map.resize());
    resizeObserver.observe(node);
    return () => {
      resizeObserver.unobserve(node);
    };
  }, [map, dispatch]);

  if (isDebugEnabled) console.info('MapContainer - main render');
  return (
    <>
      <StyledMapContainer ref={mapContainer} data-marker="main_map" />
      <SetupLayers map={map}>
        {({ styles, layers }) => (
          <SetupImages map={map} isMapStyleLoaded={mapStyleLoad}>
            <SetupEvents map={map} isMapStyleLoaded={mapStyleLoad} styles={styles}>
              {map && layers.length && (
                <>
                  <SetupFilters map={map} styleLayers={layers} />
                  <SetupThemes />
                  <SetupScenarios />
                  <SetupSearch map={map} styleLayers={layers} />
                </>
              )}
              {Boolean(map) && <Controls />}
            </SetupEvents>
          </SetupImages>
        )}
      </SetupLayers>
      {showAddressSearch && <AddressSearch map={map} />}
    </>
  );
};

const StyledMapContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  *:focus {
    outline: none;
  }
`;

export default MapContainer;
