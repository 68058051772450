import React, { useState, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { setSolvedAction } from 'modules/networkLoading';
import { Checkbox, FormInput } from 'components/_common';

interface Props {
  uuid: string;
  checked: boolean;
  paginationType: Type.PaginationType;
}

const SolvedOption: React.FC<Props> = memo(({ uuid, checked, paginationType }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [explanation, setExplanation] = useState<string>('');

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setExplanation(event.target.value);
  }, []);

  const handleInputBlur = useCallback(() => {
    setLoading(true);
    dispatch(setSolvedAction(uuid, explanation, show, paginationType)).finally(() => setLoading(false));
    if (show) {
      setShow(!show);
      setExplanation('');
    }
  }, [dispatch, uuid, explanation, show, paginationType]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      handleInputBlur();
    },
    [handleInputBlur]
  );

  const handleCheckboxChange = useCallback(() => {
    if (checked) return handleInputBlur();
    setShow(show => !show);
  }, [checked, handleInputBlur]);

  return (
    <OverlayTrigger
      trigger="click"
      show={show}
      rootClose
      overlay={
        <Popover id={uuid}>
          <Popover.Content>
            <FormInput
              size="sm"
              autoFocus
              labelKey="Explanation"
              name="explanation"
              value={explanation || ''}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleKeyPress}
            />
          </Popover.Content>
        </Popover>
      }
    >
      <Checkbox
        disabled={loading}
        dataIndex={uuid}
        className="icheck-primary d-inline-block ml-2 position-relative"
        checked={checked || show}
        onChange={handleCheckboxChange}
      />
    </OverlayTrigger>
  );
});

export default SolvedOption;
