import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocale, useToggle, useBuilderState, useEventPreventedExec } from 'hooks';
import { fetchFormsByTypeAction } from 'modules/forms';
import { FormReactSelect, Modal, HiddenFormSubmit, FormInput, Checkbox } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes, BuilderFormTypeNames, isProduction } from 'constants/index';

const LinkedFormModal: React.FC<Builder.ModalProps> = ({
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { formTypes, selectValue } = useBuilderState();
  const isEditMode: boolean = Boolean(formField);

  const [show, toggleModal] = useToggle(true);
  const [formsOptions, setFormOptions] = useState([]);

  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!values.formType?.value) return;
    dispatch(fetchFormsByTypeAction(values.formType?.value, selectValue.assetCategories.id)).then((action: any) => {
      setFormOptions(
        action.payload
          .filter((item: Forms.Item) => item.name !== selectValue.name)
          .map((item: Forms.Item) => ({ value: item.id, label: item.name }))
      );
    });
  }, [values.formType, dispatch, selectValue.name, selectValue.assetCategories.id]);

  const formTypesOptions = useMemo(() => {
    return formTypes
      .filter((item: Type.FormType) => item.name !== BuilderFormTypeNames.ToolInspection)
      .map((item: Type.FormType) => ({ value: item.id, label: getIntl(item.name) }));
  }, [formTypes, getIntl]);

  const handleSelectChange = useCallback(
    (value: any, actionMeta: any) => {
      setFieldValue(actionMeta.name, value);
      if (actionMeta.name === 'formType') {
        setFieldValue('selectedForm', null);
        setFormOptions([]);
      }
    },
    [setFieldValue]
  );

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.LinkedForm}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Label (shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <FormReactSelect
              labelKey="Form type"
              name="formType"
              value={values.formType}
              options={formTypesOptions}
              onChange={handleSelectChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.formType && errors.formType) ? (errors.formType as string) : ''}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <FormReactSelect
              labelKey="Selected form"
              name="selectedForm"
              value={values.selectedForm}
              options={formsOptions}
              onChange={handleSelectChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.selectedForm && errors.selectedForm) ? (errors.selectedForm as string) : ''}
              isDisabled={!values.formType}
            />
          </Form.Group>
        </Form.Row>
        {!isProduction && (
          <Form.Group>
            <Checkbox
              className="pl-0 icheck-primary"
              name="scheduleAllowed"
              checked={values.scheduleAllowed}
              labelKey="Ask as to-do question"
              onChange={handleChange}
            />
          </Form.Group>
        )}
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default LinkedFormModal;
