import React from 'react';
import { DataTableTemplate, ContentContainer, ContentCard } from 'components/_common';
import ChartCO2Emission from './ChartCO2Emission';
import Filters from './Filters';

const ResponseUsedKeysLocalesMap: { [key in keyof Type.CO2EmissionChartDataItem]?: string } = {
  existing_assets_t_co2e: 'Current grid',
  investments_t_co2e: 'Investments',
  losses_t_co2e_investment: 'Losses with investments',
  maintenance_t_co2e: 'Maintenance',
};

const ViewDetailedEmissions: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <Filters />
    <ContentContainer>
      <ContentCard>
        <ChartCO2Emission titleKey="Detailed emissions" ResponseUsedKeysLocalesMap={ResponseUsedKeysLocalesMap} />
      </ContentCard>
    </ContentContainer>
  </DataTableTemplate>
);

export default ViewDetailedEmissions;
