import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { appIsAdminUserSelector } from 'modules/app/selectors';
import { featureTogglesSelector, muteErrorToastActionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { UserService } from '@utiligize/shared/services';
import { Button, Checkbox } from 'components/_common';
import SelectLanguage from './SelectLanguage';
import SelectTimezone from './SelectTimezone';
import SelectMapTheme from './SelectMapTheme';
import SelectTenants from './SelectTenants';
import SelectCurrency from './SelectCurrency';
import { getStorageItem } from 'utils';
import { Routes, StorageKeys } from 'constants/index';
import { IconUser, IconLogout, IconUserInfo, IconUsers2 } from '@utiligize/shared/resources';

const PopoverUserSettings: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
  const appIsAdminUser = useSelector(appIsAdminUserSelector);
  const muteErrorToastAction = useSelector(muteErrorToastActionSelector);
  const featureToggles = useSelector(featureTogglesSelector);

  const handleCheckBoxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLayoutAction({ muteErrorToastAction: !event.currentTarget.checked }));
    },
    [dispatch]
  );

  const handleLogoutClick = useCallback((): Promise<void> => UserService.doLogout(), []);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <StyledPopover id="popover-user-settings">
          <Popover.Content>
            <div className="d-flex align-items-center my-1">
              <IconUserInfo width={30} height={30} />
              <div className="ml-2">
                {claims?.firstName} {claims?.lastName}
                <br />
                {claims?.email}
              </div>
            </div>
            <Dropdown.Divider />
            <Form.Group>
              <SelectLanguage />
            </Form.Group>
            <Form.Group>
              <SelectTimezone />
            </Form.Group>
            <Form.Group>
              <SelectMapTheme />
            </Form.Group>

            <SelectTenants />

            {featureToggles[StorageKeys.FEATURE_CURRENCIES] && (
              <Form.Group>
                <SelectCurrency />
              </Form.Group>
            )}

            <Form.Group>
              <Checkbox
                labelKey="Show error notifications"
                className="icheck-primary"
                checked={!muteErrorToastAction}
                onChange={handleCheckBoxClick}
              />
            </Form.Group>
            <Dropdown.Divider />
            <StyledControlsContainer>
              {appIsAdminUser && (
                <Button
                  linkProps={{ to: generatePath(Routes.Admin), className: 'w-100' }}
                  icon={<IconUsers2 />}
                  labelKey="Admin"
                  className="w-100"
                />
              )}
              <Button
                icon={<IconLogout />}
                labelKey="Logout"
                onClick={handleLogoutClick}
                variant="danger"
                className="w-100"
              />
            </StyledControlsContainer>
          </Popover.Content>
        </StyledPopover>
      }
    >
      <Button
        marginLeft
        variant="primary"
        icon={<IconUser />}
        aria-label="User settings"
        data-marker="header__user_settings"
      />
    </OverlayTrigger>
  );
};

const StyledPopover = styled(Popover)`
  width: 325px;
  max-width: 325px;
  z-index: 10001;
`;

const StyledControlsContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;

export default PopoverUserSettings;
