import Highcharts from 'highcharts';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart from 'components/_charts/Chart';
import { getCO2EmissionChartData } from 'modules/co2e';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import { IDsState } from './ViewAnalysisTool';

type Hash = Record<string, { name: string; data: Type.CO2EmissionChartDataItem[] }> | null;

interface ChartDataProps {
  series: Omit<Shared.SeriesOptionsType, 'type'>[];
  title: string;
  yAxisTitle: string;
}

interface Props {
  isEmissions: boolean;
  ids: IDsState[];
  setLoading: React.Dispatch<boolean>;
}

const ChartCO2Emissions: React.FC<Props> = ({ isEmissions, ids, setLoading }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const [itemsHashStore, setItemsHashStore] = useState<Hash>(null);
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const getSeriesName = useCallback(
    ({ portfolioId, scenarioId }: Omit<IDsState, 'flex'>) => {
      const portfolioOptionLabel = portfolioOptions!.find(option => option.value === portfolioId)?.label;
      const scenarioOptionLabel = scenarioOptionsHash?.[portfolioId!]!.find(
        option => option.value === scenarioId
      )?.label;
      return `${portfolioOptionLabel} - ${scenarioOptionLabel}`;
    },
    [portfolioOptions, scenarioOptionsHash]
  );

  const getStoreKey = useCallback(
    ({ portfolioId, scenarioId }: Omit<IDsState, 'flex'>) => `${portfolioId}_${scenarioId}`,
    []
  );

  const seriesItems = useMemo(() => {
    const items = ids.map(item => itemsHashStore?.[getStoreKey(item)]).filter(Boolean);
    return items.length === ids.length ? items : null;
  }, [ids, itemsHashStore, getStoreKey]);

  const emptyDataIds = useMemo(
    () => ids.filter(item => !itemsHashStore?.[getStoreKey(item)]),
    [ids, itemsHashStore, getStoreKey]
  );

  useEffect(() => {
    if (!isEmissions || !emptyDataIds.length) return;
    setLoading(true);
    Promise.all(
      emptyDataIds.map(({ portfolioId, scenarioId }) => {
        return dispatch(getCO2EmissionChartData({ portfolioId, scenarioId, isCO2eCumulativeOverview: false })).then(
          action => ({
            [getStoreKey({ portfolioId, scenarioId })]: {
              name: getSeriesName({ portfolioId, scenarioId }),
              data: action.payload,
            },
          })
        );
      })
    )
      .then(results => {
        setItemsHashStore(prev => ({ ...prev, ...results.reduce((acc, item) => ({ ...acc, ...item })) }));
      })
      .finally(() => setLoading(false));
  }, [isEmissions, emptyDataIds, dispatch, getSeriesName, getStoreKey, setLoading]);

  useEffect(() => {
    if (!isEmissions || !seriesItems) return;

    const series = seriesItems.flatMap(item => [
      {
        name: `<div>${getIntl('Current grid')}</div><small>${item?.name}</small>`,
        data: item?.data.map(item => [item.year, item.existing_assets_t_co2e]),
      },
      {
        name: `<div>${getIntl('Investments')}</div><small>${item?.name}</small>`,
        data: item?.data.map(item => [item.year, item.investments_t_co2e]),
      },
      {
        name: `<div>${getIntl('Losses with investments')}</div><small>${item?.name}</small>`,
        data: item?.data.map(item => [item.year, item.losses_t_co2e_investment]),
      },
      {
        name: `<div>${getIntl('Maintenance')}</div><small>${item?.name}</small>`,
        data: item?.data.map(item => [item.year, item.maintenance_t_co2e]),
      },
    ]);

    setChartData({ series, title: 'Detailed emissions', yAxisTitle: 'CO₂e (t/year)' });
  }, [isEmissions, seriesItems, getIntl]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: { zoomType: 'x' },
      title: { text: getIntl(chartData?.title || '') },
      yAxis: {
        title: { text: getIntl(chartData?.yAxisTitle || '') },
      },
      series: chartData?.series,
      legend: {
        useHTML: true,
      },
    }),
    [chartData, getIntl]
  ) as Highcharts.Options;

  if (!isEmissions) return null;
  return (
    <Chart
      options={chartData ? options : null}
      dataMarker="chart_analysis_tool_emissions"
      height="calc(100vh - 355px)"
    />
  );
};

export default ChartCO2Emissions;
