import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { fetchIndividualCustomersAction, deleteIndividualCustomerAction } from 'modules/newLoad';
import {
  individualCustomersCountSelector,
  individualCustomersSplitBySectionHashSelector,
} from 'modules/newLoad/selectors';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import ModalEditIndividualCustomer from './ModalEditIndividualCustomer';
import { DataTable, Button } from 'components/_common';
import { getStorageItem, setStorageItem } from 'utils';
import { PaginationType, StorageKeys, MapThemeGroups, MapThemes, Routes } from 'constants/index';
import { IconEdit, IconDelete, IconDetails, IconMap } from '@utiligize/shared/resources';
import ModalProfile from './ModalProfile';

const TableConnectionManager: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const individualCustomersSplitBySectionHash = useSelector(individualCustomersSplitBySectionHashSelector);
  const keys = Object.keys(individualCustomersSplitBySectionHash);
  const count = useSelector(individualCustomersCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchIndividualCustomersAction()), [dispatch]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteIndividualCustomerAction(id)),
        })
      );
    },
    [dispatch]
  );

  const handleViewProfileButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ newLoadDERProfileModalId: id }));
    },
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setLayoutAction({ newLoadEditIndividualCustomerModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleMapLinkClick = useCallback(() => {
    setStorageItem({
      [StorageKeys.MAP_CACHE]: {
        ...(getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || {}),
        theme: MapThemes.YEARLY_MAX_LOAD,
        themeGroup: MapThemeGroups.FORECAST_AND_INVESTMENT,
      },
    });
  }, []);

  const getMapLinkProps = useCallback(
    (uuid: string, year: number) => ({
      to: {
        pathname: generatePath(Routes.Map, { uuid }),
        state: { keepMapTheme: true, year, theme: MapThemes.YEARLY_MAX_LOAD },
      },
      onClick: handleMapLinkClick,
    }),
    [handleMapLinkClick]
  );

  return (
    <>
      <DataTable
        paginationType={PaginationType.NEW_LOAD_CONNECTION_MANAGER}
        totalAmount={count}
        sendRequest={sendRequest}
        waitForDependencies={!portfolioId || !scenarioId}
        triggerTableUpdateDeps={[portfolioId, scenarioId]}
      >
        {keys?.map((key: string) => {
          const individualCustomers: NewLoad.IndividualCustomer[] = individualCustomersSplitBySectionHash[key];
          return (
            <React.Fragment key={key}>
              <tr>
                <td colSpan={8}>
                  <b>
                    {key} - {individualCustomers[0].category}
                  </b>
                </td>
              </tr>
              {individualCustomers.map((item: NewLoad.IndividualCustomer) => (
                <tr key={item.id}>
                  <td>{item.connection_year || '-'}</td>
                  <td>{item.description || '-'}</td>
                  <td>
                    <Link {...getMapLinkProps(item.customer_node_from_id, item.connection_year!)}>
                      {item.customer_node_from || '-'}
                    </Link>
                  </td>
                  <td className="text-center">{item.customer_id || '-'}</td>
                  <td className="text-center">{item.customer_mw || '-'}</td>
                  <td>{item.power_factor ?? '-'}</td>
                  <td>{item.leading_lagging || '-'}</td>
                  <td className="text-right">
                    <Button
                      tooltipKey="Show on map"
                      icon={<IconMap />}
                      linkProps={getMapLinkProps(item.customer_node_from_id, item.connection_year!)}
                      size="small"
                    />
                    {item.der_profile_info_id && (
                      <Button
                        tooltipKey="Profile"
                        icon={<IconDetails />}
                        data-id={item.id}
                        onClick={handleViewProfileButtonClick}
                        size="small"
                        marginLeft
                      />
                    )}
                    <Button
                      marginLeft
                      tooltipKey="Edit"
                      icon={<IconEdit />}
                      data-id={item.id}
                      onClick={handleEditBtnClick}
                      size="small"
                    />
                    <Button
                      marginLeft
                      tooltipKey="Delete"
                      icon={<IconDelete />}
                      data-id={item.id}
                      onClick={handleDeleteBtnClick}
                      size="small"
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </DataTable>
      <ModalProfile />
      <ModalEditIndividualCustomer />
    </>
  );
};

export default TableConnectionManager;
