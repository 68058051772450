import { useRef } from 'react';

export default function useDebouncedCallback(callback: Function, delay: number = 200) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = (...args: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          const result = await callback(...args); // Await callback result if it's async
          resolve(result); // Resolve the promise with the callback result
        } catch (error) {
          reject(error); // Reject the promise if the callback throws an error
        }
      }, delay);
    });
  };

  return debouncedFunction;
}
