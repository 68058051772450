import React, { useMemo, useEffect } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { fetchFutureChartData } from 'modules/networkLoading';
import { futureChartDataSelectorFactory } from 'modules/networkLoading/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import Chart, { getBaseOptions } from 'components/_charts/Chart';

interface Props {
  asset_class: 'Transformer' | 'Cable';
  type: Type.PaginationType;
  index: 0 | 1;
  dataMarker: string;
}

const ChartFuture: React.FC<Props> = ({ asset_class, type, index, dataMarker = '' }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const storeKey = `${asset_class}_${filters?.voltage}_${portfolioId}_${scenarioId}_${simulationId}_${versionId}`;
  const futureChartDataSelector = useMemo(() => futureChartDataSelectorFactory(storeKey, index), [storeKey, index]);
  const data = useSelector(futureChartDataSelector);

  const baseOptions = getBaseOptions(getIntl, {
    title: data?.title,
    xAxisTitle: data?.xAxisTitle,
    yAxisTitle: data?.yAxisTitle,
  });

  useEffect(() => {
    if (!filters?.voltage || !asset_class || !portfolioId || !scenarioId || !simulationId || !versionId || data) return;
    dispatch(
      fetchFutureChartData({
        asset_class,
        voltage: filters?.voltage,
        portfolioId,
        scenarioId,
        simulationId,
        versionId,
        storeKey,
      })
    );
  }, [dispatch, filters?.voltage, asset_class, portfolioId, scenarioId, simulationId, versionId, data, storeKey]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `<b>${getIntl(data?.yAxisTitle || '')}:</b> ${this.point.y}`;
        },
      },
      series: data?.series?.map((s: any) => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: { enabled: false },
    }),
    [baseOptions, data?.series, getIntl, data?.yAxisTitle]
  ) as unknown as Highcharts.Options;

  return <Chart key={storeKey} options={data?.series ? options : null} dataMarker={dataMarker} />;
};

export default ChartFuture;
