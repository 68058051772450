import { createSelector } from 'reselect';
import { appLangSelector } from 'modules/app/selectors';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
  investmentScenarioIdSelector,
} from 'modules/layouts/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { isProduction, Routes } from 'constants/index';
import { generatePath } from 'react-router';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.options;

export const portfolioOptionsSelector = createSelector(rootSelector, options => options.portfolioOptions);

export const portfolioOptionSelector = createSelector(
  [portfolioOptionsSelector, portfolioIdSelector],
  (portfolioOptions, portfolioId) => portfolioOptions?.find(option => option.value === portfolioId) || null
);

export const isPortfolioLoadflowEnabledSelector = createSelector(portfolioOptionSelector, portfolioOption =>
  Boolean(portfolioOption?.loadflow_enabled)
);

export const scenarioOptionsHashSelector = createSelector(rootSelector, options => options.scenarioOptionsHash);

export const scenarioOptionsSelector = createSelector(
  [scenarioOptionsHashSelector, portfolioIdSelector],
  (hash, portfolioId) => (portfolioId && hash?.[portfolioId]) || null
);

export const scenarioSelectedOptionSelector = createSelector(
  [scenarioOptionsSelector, scenarioIdSelector],
  (options, scenarioId) => options?.find(option => option.value === scenarioId) || null
);

export const scenariosParamSelector = createSelector(
  scenarioSelectedOptionSelector,
  scenarioOption => scenarioOption?.scenariosParam || null
);

export const scenarioYearsOptionsSelector = createSelector(rootSelector, options => options.scenarioYearsOptions);

export const simulationOptionsHashSelector = createSelector(rootSelector, options => options.simulationOptionsHash);

export const simulationOptionsSelector = createSelector(
  [simulationOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId) => hash?.[`${portfolioId}_${scenarioId}`] || null
);

export const isSelectSimulationEnabledSelector = createSelector(
  [
    isPortfolioLoadflowEnabledSelector,
    isRouteActiveSelectorFactory(Routes.Map),
    isRouteActiveSelectorFactory(Routes.DataQualityCustomersConnected),
    isRouteActiveSelectorFactory(Routes.DataQualityStatistics),
    isRouteActiveSelectorFactory(`${Routes.DataQualityStatistics}${Routes.HASH.MissingAge}`),
    isRouteActiveSelectorFactory(generatePath(Routes.History)),
    isRouteActiveSelectorFactory(generatePath(Routes.HistoryAnswers)),
    isRouteActiveSelectorFactory(Routes.NetworkLoading, false),
    isRouteActiveSelectorFactory(Routes.NetworkLoadingTotalLoading, false),
  ],
  (
    isPortfolioLoadflowEnabled,
    isMap,
    isDataQualityCustomersConnected,
    isDataQualityStatistics,
    isDataQualityStatisticsMissingAge,
    isHistory,
    isHistoryAnswers,
    isNetworkLoading,
    isNetworkLoadingTotalLoading
  ): boolean =>
    isPortfolioLoadflowEnabled &&
    (isMap ||
      isDataQualityCustomersConnected ||
      isDataQualityStatistics ||
      isDataQualityStatisticsMissingAge ||
      isHistory ||
      isHistoryAnswers ||
      (isNetworkLoading && !isNetworkLoadingTotalLoading && !isProduction))
);

export const simulationOptionSelector = createSelector(
  [simulationOptionsSelector, simulationIdSelector, isSelectSimulationEnabledSelector],
  (simulationOptions, simulationId, isSelectSimulationEnabled) =>
    isSelectSimulationEnabled
      ? simulationOptions?.find(option => option.value === simulationId) || null
      : simulationOptions?.[0] || null
);

export const simulationVersionIdSelector = createSelector(
  simulationOptionSelector,
  option => option?.versionId || null
);

export const percentilesOptionsHashSelector = createSelector(rootSelector, options => options.percentilesOptionsHash);

export const percentilesOptionsSelector = createSelector(
  [percentilesOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId) => hash[`${portfolioId}_${scenarioId}`] || null
);

export const scenariosYearsOptionsHashSelector = createSelector(
  rootSelector,
  options => options.scenariosYearsOptionsHash
);

export const scenariosYearsOptionsSelector = createSelector(
  [scenariosYearsOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId) => hash[`${portfolioId}_${scenarioId}`] || null
);

export const outageTypeOptionsSelector = createSelector(rootSelector, options => options.outageTypeOptions);

export const routesOptionsSelector = createSelector(rootSelector, options => options.routesOptions);

export const investmentScenariosOptionsHashSelector = createSelector(
  rootSelector,
  options => options.investmentScenariosOptionsHash
);

export const investmentScenariosOptionsSelector = createSelector(
  [investmentScenariosOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId) => hash?.[`${portfolioId}_${scenarioId}`] || null
);

export const investmentScenariosOptionSelector = createSelector(
  [investmentScenariosOptionsSelector, investmentScenarioIdSelector],
  (investmentScenariosOptions, investmentScenarioId) =>
    investmentScenariosOptions?.find(option => option.value === investmentScenarioId) || null
);

export const componentPriceScenarioOptionsHashSelector = createSelector(
  rootSelector,
  options => options.componentPriceScenarioOptionsHash
);

export const componentPriceScenarioOptionsSelector = createSelector(
  [componentPriceScenarioOptionsHashSelector, portfolioIdSelector],
  (hash, portfolioId) => hash[String(portfolioId)]
);

export const componentPriceTypeOptionsSelector = createSelector(
  rootSelector,
  options => options.componentPriceTypeOptions
);

export const cnaimAssetCategoriesOptionsHashSelector = createSelector(
  rootSelector,
  options => options.cnaimAssetCategoriesOptionsHash
);

export const cnaimAssetCategoriesOptionsSelector = createSelector(
  [cnaimAssetCategoriesOptionsHashSelector, appLangSelector],
  (hash, lang) => hash[lang] || null
);

export const DERsDescriptionOptionsHashSelector = createSelector(
  rootSelector,
  options => options.DERsDescriptionOptionsHash
);

export const DERsDescriptionOptionsSelector = createSelector(
  [DERsDescriptionOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (DERsDescriptionOptionsHash, portfolioId, scenarioId) =>
    DERsDescriptionOptionsHash?.[`${portfolioId}_${scenarioId}`] || null
);

export const DERsPointAssetsOptionsHashSelector = createSelector(
  rootSelector,
  options => options.DERsPointAssetsOptionsHash
);

export const DERsPointAssetsOptionsSelector = createSelector(
  [rootSelector, simulationVersionIdSelector],
  (options, versionId) => (versionId && options.DERsPointAssetsOptionsHash[versionId]) || null
);

export const workTypeOptionsSelector = createSelector(rootSelector, options => options.workTypeOptions);

export const primarySubstationOptionsHashSelector = createSelector(
  rootSelector,
  options => options.primarySubstationOptionsHash
);

export const primarySubstationOptionsSelector = createSelector(
  [primarySubstationOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId) => hash[`${portfolioId}_${scenarioId}`] || null
);

export const allTableN1OptionsFetchedSelector = createSelector(
  [outageTypeOptionsSelector, routesOptionsSelector, primarySubstationOptionsSelector],
  (outageTypeOptions, routesOptions, primarySubstationOptions) =>
    Boolean(outageTypeOptions?.length && routesOptions?.length && primarySubstationOptions?.length)
);

export const pointTypeOptionsHashSelector = createSelector(rootSelector, options => options.pointTypeOptionsHash);

export const pointTypeOptionsSelectorFactory = (
  voltage_level_id: Map.MapDrawAssetFeatureProperties['voltage_level_id']
) => createSelector(pointTypeOptionsHashSelector, hash => hash[String(voltage_level_id)]);

export const transformerTypeOptionsHashSelector = createSelector(
  rootSelector,
  options => options.transformerTypeOptionsHash
);

export const transformerTypeOptionsSelectorFactory = ({
  componentPriceScenarioId,
  voltage_level_id,
}: Pick<Map.MapDrawAssetFeatureProperties, 'componentPriceScenarioId' | 'voltage_level_id'>) =>
  createSelector(transformerTypeOptionsHashSelector, hash => hash[`${componentPriceScenarioId}_${voltage_level_id}`]);

export const cableTypeOptionsHashSelector = createSelector(rootSelector, options => options.cableTypeOptionsHash);

export const cableTypeOptionsSelectorFactory = ({
  componentPriceScenarioId,
  voltage_level_id,
}: Pick<Map.MapDrawAssetFeatureProperties, 'componentPriceScenarioId' | 'voltage_level_id'>) =>
  createSelector(cableTypeOptionsHashSelector, hash => hash[`${componentPriceScenarioId}_${voltage_level_id}`]);
