import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { FormReactSelect } from 'components/_common';
import { PaginationType } from 'constants/index';

const SelectAssetCategories: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.MAINTENANCE_PLAN_CNAIM;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const value = filters?.reinvestmentAssetCategory;

  const options = useMemo(
    () => [
      { value: '10_04_trafo', label: getIntl('10/0.4 kV Transformer (GM)') },
      { value: 'LV', label: getIntl('LV board') },
      { value: 'MV', label: getIntl('MV board') },
      { value: 'RTU', label: getIntl('RTU') },
      { value: 'KS', label: getIntl('Cabinets') },
      { value: 'TH', label: getIntl('Stations') },
      { value: 'KO', label: getIntl('Switchgear') },
      { value: 'SS', label: getIntl('Traffic Lights') },
      { value: 'TS', label: getIntl('Ignition Cabinet') },
    ],
    [getIntl]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, reinvestmentAssetCategory: (value as any)?.value }, offset: 0 },
        })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <FormReactSelect
      value={(value && options.find(i => i.value === value)) || null}
      options={options}
      onChange={handleSelectChange}
      isSearchable={false}
    />
  );
};

export default SelectAssetCategories;
