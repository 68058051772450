import styled from 'styled-components';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getProfileChartData } from 'modules/networkLoading';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  selectedChartFlexSelector,
  selectedChartYearSelector,
} from 'modules/layouts/selectors';
import { SelectDERsPreviewYear, Checkbox } from 'components/_common';
import Chart, { ChartDataProps, getBaseOptions } from './Chart';
import { setLayoutAction } from 'modules/layouts';

interface Props {
  uuid: string;
  height?: '100%';
  isTooltipOutside?: boolean;
}

const AssetProfileChart: React.FC<Props> = ({ uuid, height, isTooltipOutside = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const year = useSelector(selectedChartYearSelector);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const baseOptions = getBaseOptions(getIntl, chartData);

  const handleSelectYear = useCallback(
    (selectedChartYear: number) => dispatch(setLayoutAction({ selectedChartYear })),
    [dispatch]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  const PercentileOpacityMap: { [key: string]: number } = useMemo(
    () => ({
      '100 (max)': 1,
      '90': 0.8,
      '75': 0.7,
      '50': 0.6,
      '25': 0.5,
      '10': 0.4,
      '0 (min)': 0.4,
    }),
    []
  );

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId) return;
    dispatch(getProfileChartData({ portfolioId, scenarioId, uuid, year, flex }))
      .then((action: Shared.ReduxAction<any>) => {
        const { hash: seriesHash, zIndex } = action.payload.ts_data.reduce(
          (
            acc: { hash: Record<string, number[][]>; zIndex: Record<string, number> },
            item: { hour: number; kVA: number; percentile: string },
            index: number
          ) => {
            const dataItem = [item.hour, item.kVA];
            const name = String(item.percentile);
            if (!acc.hash[name]) {
              acc.hash[name] = [dataItem];
              acc.zIndex = { ...acc.zIndex, [name]: index };
            } else {
              acc.hash[name].push(dataItem);
            }
            return acc;
          },
          { hash: {}, zIndex: {} }
        );

        return {
          seriesHash,
          title: action.payload.title,
          legendTitle: action.payload.legend_title,
          yAxisTitle: action.payload.ylabel,
          xAxisTitle: action.payload.xlabel,
          zIndex,
        };
      })
      .then(setChartData)
      .catch(() => setChartData({ series: [] } as any));
  }, [dispatch, portfolioId, scenarioId, uuid, year, flex]);

  const options = useMemo(() => {
    const series = Object.keys(chartData?.seriesHash || {})
      .sort((a, b) => chartData?.zIndex[a]! - chartData?.zIndex[b]!)
      .map((key: string) => ({
        name: getIntl(key),
        type: 'line',
        data: chartData?.seriesHash[key],
        opacity: PercentileOpacityMap[key] || 1,
        lineWidth: 2,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      }));

    return {
      ...baseOptions,

      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        shared: false,
        outside: isTooltipOutside,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          const hour = this.x;
          const kVA = this.y;
          const percentile = this.series.name;

          return `${getIntl('Hour')}: ${hour}<br/>kVA: ${kVA}<br/>${getIntl('Percentile')}: ${percentile}`;
        },
      },
      legend: {
        title: {
          text: getIntl(chartData?.legendTitle || ''),
        },
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        reversed: true,
      },
      colors: series.map(item => `rgba(45, 76, 147, ${item.opacity})`),
      series,
    };
  }, [baseOptions, chartData, PercentileOpacityMap, getIntl, isTooltipOutside]) as unknown as Highcharts.Options;

  return (
    <>
      <StyledContainer data-marker="asset_timeseries__buttons_block">
        <SelectDERsPreviewYear
          labelKey=""
          value={year}
          onChange={handleSelectYear}
          variant="small"
          isSearchable={false}
        />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary ml-2"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </StyledContainer>
      <Chart dataMarker="asset_timeseries_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 50%;
  transform: translate(50%);
  display: inline-flex;
  justify-content: space-between;
`;

export default AssetProfileChart;
