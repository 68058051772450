import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { SelectTaskType } from 'components/_common';
import { DataTableFilterKeys } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Layouts.Filters>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const SelectTypeFilter: React.FC<Props> = ({ inDataTableHeaderFilters = false, values, setFieldValue, submitForm }) => {
  const handleSelectChange = useCallback(
    async (value: any) => {
      await setFieldValue(DataTableFilterKeys.TYPE, value?.value?.length ? value?.value : null);
      if (inDataTableHeaderFilters) submitForm();
    },
    [setFieldValue, inDataTableHeaderFilters, submitForm]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.TYPE)) return null;

  const Select = (
    <SelectTaskType
      labelKey={inDataTableHeaderFilters ? '' : undefined}
      name={DataTableFilterKeys.TYPE}
      types={values.type || ''}
      onChange={handleSelectChange}
      isClearable
      isMulti
      variant="small"
      isSearchable={false}
    />
  );

  return inDataTableHeaderFilters ? <Col sm={2}>{Select}</Col> : <Form.Group>{Select}</Form.Group>;
};

export default SelectTypeFilter;
