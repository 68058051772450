import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { PageAppExceptions } from 'pages';
import { ViewTransformers, ViewCables, ViewTotalLoading, ViewVoltageDrop } from 'components/NetworkLoading';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes, StorageKeys } from 'constants/index';

const PageNetworkLoading: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isNetworkLoadingEnabled}>
      <Switch>
        <Route exact path={Routes.NetworkLoading} component={ViewTransformers} />
        <Route exact path={Routes.NetworkLoadingCables} component={ViewCables} />
        <Route exact path={Routes.NetworkLoadingTotalLoading} component={ViewTotalLoading} />
        {featureToggles[StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP] && (
          <Route exact path={Routes.NetworkLoadingViewVoltageDrop} component={ViewVoltageDrop} />
        )}
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    </PagePermissionsProvider>
  );
};

export default PageNetworkLoading;
