import styled from 'styled-components';
import classNames from 'classnames';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link, NavLink, useLocation, matchPath, generatePath } from 'react-router-dom';
import { OverlayTriggerTooltip } from '@utiligize/shared/components';
import { IconChevronLeft } from '@utiligize/shared/resources';

export type SidebarAccordionItemProps = {
  to: string;
  labelKey: string;
  icon: React.ReactElement;
  disabled?: boolean;
  dataMarker?: string;
};

interface Props {
  labelKey: string;
  dataMarker: string;
  icon: React.ReactElement;
  items: SidebarAccordionItemProps[];
  isSidebarOpen: boolean;
  getIntl?: (localeKey: string, options?: {}) => string;
  isFlatMenu?: boolean;
}

const SidebarAccordion: React.FC<Props> = ({
  labelKey,
  dataMarker,
  icon,
  items,
  isSidebarOpen,
  getIntl = str => str,
  isFlatMenu = false,
}) => {
  const { pathname } = useLocation();
  const isActive: boolean = useMemo(() => items.some(item => !!matchPath(pathname, item.to)), [items, pathname]);
  const [open, setOpen] = useState(isActive);

  // Open accordion automatically when some item is active
  useEffect(() => {
    if (isActive) setOpen(true);
  }, [isActive]);

  const toggleAccordion = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(value => !value);
  }, []);

  const renderOverlay = (icon: React.ReactElement, labelKey: string, dataMarker: string) => (
    <OverlayTriggerTooltip
      key={labelKey}
      placement="right-end"
      delay={{ show: 250, hide: 250 }}
      overlayId={labelKey}
      overlayChildren={getIntl(labelKey)}
    >
      <button aria-label={labelKey} data-marker={dataMarker}>
        {icon}
      </button>
    </OverlayTriggerTooltip>
  );

  const renderItems = () =>
    items.map(item => (
      <li key={item.to} className={classNames({ disabled: item.disabled })}>
        <StyledNavLink as={NavLink} to={generatePath(item.to)} data-sidebar-open={isSidebarOpen}>
          {renderOverlay(item.icon, item.labelKey, item.dataMarker || '')}
          <p>{getIntl(item.labelKey)}</p>
        </StyledNavLink>
      </li>
    ));

  if (isFlatMenu) return renderItems();
  return (
    <StyledAccordion className={classNames({ 'menu-open': open })} data-sidebar-open={isSidebarOpen}>
      <StyledNavLink
        as={Link}
        to="#"
        onClick={toggleAccordion}
        data-sidebar-open={isSidebarOpen}
        data-is-header
        open={open}
      >
        {renderOverlay(icon, labelKey, dataMarker)}
        <p>
          {getIntl(labelKey)}
          <IconChevronLeft className="ml-1" />
        </p>
      </StyledNavLink>
      <ul className="nav nav-treeview">{renderItems()}</ul>
    </StyledAccordion>
  );
};

const StyledAccordion = styled.li<{ 'data-sidebar-open': boolean; 'data-is-header'?: boolean; open?: boolean }>`
  &:not(.menu-open) {
    ${props => (props['data-sidebar-open'] ? '' : `border-bottom: 1px solid #342B5D`)};
    margin-bottom: 5px;
  }

  &:first-child {
    ${props => (props['data-sidebar-open'] ? '' : `border-top: 1px solid #342B5D`)};
  }

  &.menu-open {
    ${props => (props['data-sidebar-open'] ? '' : `background: #342B5D`)};
    border-radius: 8px;

    + li {
      ${props => (props['data-sidebar-open'] ? '' : `border-top: 1px solid #342B5D`)};
      margin-top: 5px;
    }
  }
`;

const StyledNavLink = styled(NavLink)<{ 'data-sidebar-open': boolean; 'data-is-header'?: boolean; open?: boolean }>`
  &&&&&& {
    display: flex;
    justify-content: ${props => (props['data-sidebar-open'] ? 'space-between' : 'center')};
    align-items: center;
    background-color: unset;
    color: ${props => props.theme.colors.grey100};
    padding: 7px 6px;
    margin: ${props => (props['data-sidebar-open'] ? '1px 0px' : '3px')};

    &:hover {
      ${props => (props['data-sidebar-open'] ? 'background: ' + props.theme.colors.purple200 : '')};
      border-radius: 8px;
    }

    &.active {
      background: ${props => props.theme.colors.purple200};
      border-radius: 8px;

      svg,
      button > svg {
        fill: ${props => props.theme.colors.grey100};
      }
    }

    button {
      display: flex;
      margin: 0;
      padding: 0;
      background: unset;
      border: unset;
    }

    svg {
      fill: #17a2b8;
      width: 19px;
      height: 19px;
      fill: ${props => (props['data-sidebar-open'] ? props.theme.colors.grey100 : props.theme.colors.grey25)};
    }

    > svg {
      display: ${props => (props['data-is-header'] && props['data-sidebar-open'] ? 'none' : 'inline-block')};
    }

    > p {
      display: ${props => (props['data-sidebar-open'] ? 'flex' : 'none')};
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      font-weight: ${props => (props['data-is-header'] ? '500' : '400')};
      color: ${props => (props['data-is-header'] ? props.theme.colors.grey25 : props.theme.colors.grey100)};
      font-size: 14px;
      margin-left: 9px;
      line-height: 1;
      white-space: nowrap;

      > svg {
        transform: ${({ open }) => (open ? 'rotate(0.25turn)' : 'rotate(-0.25turn)')};
      }
    }

    ${props =>
      props['data-is-header'] && !props['data-sidebar-open']
        ? `
          background: ${props.theme.colors.purple800};
          border-radius: 8px;
    
          button > svg {
            width: 22px;
            height: 22px;
            fill: ${props.theme.colors.grey100};
          }
    `
        : ''};
  }
`;

export default SidebarAccordion;
