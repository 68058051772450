import { combineActions, handleActions } from 'redux-actions';
import {
  fetchMaintenancePlansAction,
  fetchMaintenanceSummaryAction,
  fetchMaintenanceDetailedPlanAction,
  createMaintenanceDetailedPlanAction,
  updateMaintenanceDetailedPlanItemAction,
  createMaintenancePlanAction,
  updateMaintenancePlanAction,
  deleteMaintenancePlanAction,
  recalculateMaintenancePlanAction,
  fetchPlanCNAIMAction,
  getAverageTimeSpentByTransformersChartData,
  getAverageTimeSpentByCabinetsChartData,
  fetchTimeByActionTypeChartDataAction,
  fetchConditionAssessmentChartDataAction,
} from './actions';

const initialState: Maintenance.Root = {
  maintenancePlansHash: {} as Maintenance.Root['maintenancePlansHash'],
  maintenancePlansCount: 0,
  maintenancePlansFetched: false,
  maintenanceSummaryHash: {} as Maintenance.Root['maintenanceSummaryHash'],
  maintenanceSummaryCount: 0,
  maintenanceDetailedPlanHash: {} as Maintenance.Root['maintenanceDetailedPlanHash'],
  maintenanceDetailedPlanCount: 0,
  maintenancePlanCNAIMHash: {} as Maintenance.Root['maintenancePlanCNAIMHash'],
  maintenancePlanCNAIMCount: 0,
  timeByActionTypeChartData: null,
  conditionAssessmentChartData: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(
    fetchMaintenancePlansAction,
    fetchMaintenanceSummaryAction,
    fetchMaintenanceDetailedPlanAction,
    fetchPlanCNAIMAction,
    fetchTimeByActionTypeChartDataAction,
    fetchConditionAssessmentChartDataAction
  ) as any]: {
    next: (state: NetworkLoading.Root, action: Shared.ReduxAction<Maintenance.Root>): Maintenance.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export {
  fetchMaintenancePlansAction,
  fetchMaintenanceSummaryAction,
  fetchMaintenanceDetailedPlanAction,
  createMaintenanceDetailedPlanAction,
  updateMaintenanceDetailedPlanItemAction,
  createMaintenancePlanAction,
  updateMaintenancePlanAction,
  deleteMaintenancePlanAction,
  recalculateMaintenancePlanAction,
  fetchPlanCNAIMAction,
  getAverageTimeSpentByTransformersChartData,
  getAverageTimeSpentByCabinetsChartData,
  fetchTimeByActionTypeChartDataAction,
  fetchConditionAssessmentChartDataAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
