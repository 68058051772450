import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { ScenarioRunInfo } from 'components';
import { SelectSolved, FormReactSelect, Checkbox, SelectScenariosYear } from 'components/_common';
import SelectVoltage from './SelectVoltage';
import SelectPercentile from './SelectPercentile';
import {
  PaginationType,
  TransformerVoltages,
  CableVoltages,
  VoltageSide,
  VoltageDisplay,
  BICalculations,
} from 'constants/index';

interface Props {
  type: Type.PaginationType;
  hideScenariosYear?: boolean;
  showFilterSolved?: boolean;
  showVoltageSide?: boolean;
  showVoltageDisplay?: boolean;
  showBICalculation?: boolean;
  showPercentile?: boolean;
  showFlex?: boolean;
}

const TableFilters: React.FC<Props> = ({
  type,
  hideScenariosYear,
  showFilterSolved = false,
  showVoltageSide = false,
  showVoltageDisplay = false,
  showBICalculation = false,
  showPercentile = false,
  showFlex = false,
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const voltages = [PaginationType.TRANSFORMERS].includes(type) ? TransformerVoltages : CableVoltages;

  const handleSelectChange = useCallback(
    (value: any, meta: any) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [meta.name]: value?.value || value }, offset: 0 },
        })
      );
    },
    [type, dispatch, filters]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [event.currentTarget.name]: event.currentTarget.checked }, offset: 0 },
        })
      );
    },
    [type, dispatch, filters]
  );

  const voltageSideOptions = useMemo(
    () => Object.keys(VoltageSide).map((key: string) => ({ value: key, label: getIntl(key) })),
    [getIntl]
  );

  const voltageDisplayOptions = useMemo(
    () => Object.keys(VoltageDisplay).map((key: string) => ({ value: key, label: getIntl(key) })),
    [getIntl]
  );

  const biCalculationOptions = useMemo(
    () => Object.keys(BICalculations).map((key: string) => ({ value: key, label: getIntl(key) })),
    [getIntl]
  );

  return (
    <>
      <Row>
        <Col sm={9}>
          <Row style={{ rowGap: '0.5rem' }}>
            {!hideScenariosYear && (
              <Col xs={3}>
                <SelectScenariosYear
                  name="year"
                  value={filters?.year as number}
                  onChange={handleSelectChange}
                  labelKey=""
                  mutedTextLabelKey="Scenarios year"
                  variant="small"
                />
              </Col>
            )}
            <Col xs={3}>
              <SelectVoltage
                name="voltage"
                labelKey=""
                mutedTextLabelKey="Voltage"
                value={filters?.voltage}
                voltages={voltages}
                onChange={handleSelectChange}
                variant="small"
              />
            </Col>
            {showVoltageSide && (
              <Col xs={3}>
                <FormReactSelect
                  name="voltageSide"
                  labelKey=""
                  options={voltageSideOptions}
                  value={filters?.voltageSide ? { value: filters?.voltageSide, label: filters?.voltageSide } : null}
                  onChange={handleSelectChange}
                  isSearchable={false}
                  variant="small"
                  mutedTextLabelKey="Voltage side"
                />
              </Col>
            )}
            {showVoltageDisplay && (
              <Col xs={3}>
                <FormReactSelect
                  name="voltageDisplay"
                  labelKey=""
                  options={voltageDisplayOptions}
                  value={
                    filters?.voltageDisplay ? { value: filters?.voltageDisplay, label: filters?.voltageDisplay } : null
                  }
                  onChange={handleSelectChange}
                  isSearchable={false}
                  variant="small"
                  mutedTextLabelKey="Voltage display"
                />
              </Col>
            )}
            {showPercentile && (
              <Col xs={3}>
                <SelectPercentile
                  name="percentile"
                  labelKey=""
                  value={filters?.percentile || null}
                  onChange={handleSelectChange}
                  isSearchable={false}
                  variant="small"
                  mutedTextLabelKey="Percentile"
                />
              </Col>
            )}
            {showBICalculation && (
              <Col xs={3}>
                <FormReactSelect
                  name="BICalculation"
                  labelKey=""
                  options={biCalculationOptions}
                  value={
                    filters?.BICalculation ? { value: filters?.BICalculation, label: filters?.BICalculation } : null
                  }
                  onChange={handleSelectChange}
                  isSearchable={false}
                  variant="small"
                  mutedTextLabelKey="BI calculation"
                />
              </Col>
            )}
            {showFilterSolved && (
              <Col xs={3}>
                <SelectSolved
                  name="solvedValue"
                  mutedTextLabelKey="Filter solved"
                  value={filters?.solvedValue}
                  onChange={handleSelectChange}
                  variant="small"
                />
              </Col>
            )}
            {showFlex && (
              <Col xs="auto">
                <Checkbox
                  labelKey="Flex"
                  className="icheck-primary"
                  name="flex"
                  checked={filters?.flex}
                  onChange={handleCheckboxClick}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col sm={3} className="d-flex flex-column align-items-end">
          <ScenarioRunInfo />
        </Col>
      </Row>
    </>
  );
};

export default TableFilters;
