import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector, showCableAnimationSelector } from 'modules/map/selectors';
import { setMapStateAction } from 'modules/map';
import { Button } from 'components/_common';

const CableAnimationButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const settings = useSelector(settingsSelector);

  const hasHighVoltageCableLayer = useMemo(
    () => settings.assetLayers?.some(layer => layer.includes('asset__cables_voltage_3')),
    [settings.assetLayers]
  );

  const toggleCableAnimationState = useCallback(() => {
    dispatch(setMapStateAction({ showCableAnimation: !showCableAnimation }));
  }, [dispatch, showCableAnimation]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: showCableAnimation })}
      onClick={toggleCableAnimationState}
      icon={<i className="fa fa-wave-square fa-stack-1x" />}
      tooltipKey={
        !hasHighVoltageCableLayer
          ? 'Animation works only with high voltage cables'
          : showCableAnimation
            ? 'Disable cable animation'
            : 'Enable cable animation'
      }
      tooltipPlacement="left"
      disabled={!hasHighVoltageCableLayer}
    />
  );
};

export default CableAnimationButton;
