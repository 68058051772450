import React from 'react';
import { useSelector } from 'react-redux';
import {
  isNetworkLoadingTransformersHistoricalLoadingDistributionTabActiveSelector,
  isNetworkLoadingTransformersVoltageTabActiveSelector,
  isRouteActiveSelectorFactory,
} from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsTransformers from './TabsTransformers';
import TableFilters from './TableFilters';
import { Routes, PaginationType } from 'constants/index';

const ViewTransformers: React.FC = () => {
  const isScenariosTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NetworkLoading));
  const isHistoricalLoadingDistributionTabActive = useSelector(
    isNetworkLoadingTransformersHistoricalLoadingDistributionTabActiveSelector
  );
  const isVoltageTabActive = useSelector(isNetworkLoadingTransformersVoltageTabActiveSelector);
  const isFutureMeanTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoading}${Routes.HASH.FutureMean}`)
  );
  const isFutureOverloadedTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoading}${Routes.HASH.FutureOverloaded}`)
  );

  const type = PaginationType.TRANSFORMERS;
  return (
    <DataTableTemplate>
      <TableFilters
        key={type}
        type={type}
        hideScenariosYear={isFutureMeanTabActive || isFutureOverloadedTabActive}
        showFilterSolved={isScenariosTabActive}
        showVoltageSide={isScenariosTabActive || isHistoricalLoadingDistributionTabActive || isVoltageTabActive}
        showVoltageDisplay={isScenariosTabActive}
        showBICalculation={isScenariosTabActive}
        showPercentile={isScenariosTabActive || isHistoricalLoadingDistributionTabActive || isVoltageTabActive}
        showFlex={!isFutureMeanTabActive && !isFutureOverloadedTabActive}
      />
      <TabsTransformers type={type} />
    </DataTableTemplate>
  );
};

export default ViewTransformers;
