import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewIssues, ViewCustomersConnected, ViewMeters, ViewStatistics } from 'components/DataQuality';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PageAppExceptions } from 'pages';
import { Routes, PermissionsTypes } from 'constants/index';

const PageDataQuality: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isDataQualityEnabled}>
    <Switch>
      <Route exact path={Routes.DataQuality} component={ViewIssues} />
      <Route exact path={Routes.DataQualityCustomersConnected} component={ViewCustomersConnected} />
      <Route exact path={Routes.DataQualityMeters} component={ViewMeters} />
      <Route exact path={Routes.DataQualityStatistics} component={ViewStatistics} />
      <Route path="*" component={PageAppExceptions} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageDataQuality;
