import React, { useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction, setDataQualityWarningAction } from 'modules/map';
import {
  settingsSelector,
  dataQualityWarningSelector,
  mapStateDataQualityFiltersSelector,
} from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import Section from 'components/Map/common/Section';

const ForecastInvestmentDataQuality: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const { getIntl } = useLocale();
  const warning = useSelector(dataQualityWarningSelector);
  const mapStateDataQualityFilters = useSelector(mapStateDataQualityFiltersSelector);

  const hideWarnings = useCallback(() => {
    dispatch(setDataQualityWarningAction(null));
  }, [dispatch]);

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>, value: string | number) => {
    if (!mapStateDataQualityFilters) return;
    const checked = e.target.checked;
    const prev = mapStateDataQualityFilters.list;
    dispatch(
      mapStateAction({
        dataQualityFilters: {
          ...mapStateDataQualityFilters,
          list: checked ? [...prev, value] : prev.filter(i => i !== value),
        },
      })
    );
  };

  return (
    <CollapseGroup
      id="data_quality"
      dataMarker="panel_data_quality"
      titleKey={settings.themeTitles?.dq!}
      disabled={settings.isDataQualityThemeDisabled}
      stateLoc="theme"
    >
      <>
        {mapStateDataQualityFilters && (
          <Section className="mt-1" title={getIntl('Issue type')}>
            {mapStateDataQualityFilters?.initList.map(i => (
              <div key={i.id} className="position-relative">
                <ControlBox
                  type="checkbox"
                  id={i.id + ''}
                  name={i.name ?? ''}
                  checked={mapStateDataQualityFilters.list.includes(i.id)}
                  labelKey={i.name}
                  onChange={e => onFilterChange(e, i.id)}
                  disabled={false}
                />
                <i
                  className={classNames(
                    'legend-icon',
                    (i.id as string).includes('meshed') ? 'meshed_structure' : 'island_networks'
                  )}
                />
              </div>
            ))}
          </Section>
        )}
        {warning && (
          <>
            <Section className="mt-3" title={getIntl('Currently shown issue')}>
              <StyledWarningDescription>{warning.description}</StyledWarningDescription>
            </Section>
            <StyledWarning>
              <StyledButton className="text-center" onClick={hideWarnings}>
                {getIntl('Show all issues')}
              </StyledButton>
            </StyledWarning>
          </>
        )}
      </>
    </CollapseGroup>
  );
};

const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

const StyledWarningDescription = styled.div`
  color: var(--map-text-color-secondary);
`;

const StyledButton = styled.button`
  all: unset;
  color: #fff;
  background-color: var(--map-active-color-light);
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 4px;

  &:hover {
    background-color: var(--map-background-color);
  }
`;

export default ForecastInvestmentDataQuality;
