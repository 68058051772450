import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getGlobalDevelopmentChartData } from 'modules/newLoad';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import Chart from 'components/_charts/Chart';
import { IDsState } from './ViewAnalysisTool';

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  yAxisTitle: string;
}

type Hash = Record<string, { name: string; data: Type.GlobalDevelopmentChartDataItem[] }> | null;

interface Props {
  isDERPhaseInProfile: boolean;
  ids: IDsState[];
  setLoading: React.Dispatch<boolean>;
}

const ChartDERPhaseInProfile: React.FC<Props> = ({ isDERPhaseInProfile, ids, setLoading }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const [itemsHashStore, setItemsHashStore] = useState<Hash>(null);
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const getSeriesName = useCallback(
    ({ portfolioId, scenarioId }: Omit<IDsState, 'flex'>) => {
      const portfolioOptionLabel = portfolioOptions!.find(option => option.value === portfolioId)?.label;
      const scenarioOptionLabel = scenarioOptionsHash?.[portfolioId!]!.find(
        option => option.value === scenarioId
      )?.label;
      return `${portfolioOptionLabel} - ${scenarioOptionLabel}`;
    },
    [portfolioOptions, scenarioOptionsHash]
  );

  const getStoreKey = useCallback(
    ({ portfolioId, scenarioId }: Omit<IDsState, 'flex'>) => `${portfolioId}_${scenarioId}`,
    []
  );

  const seriesItems = useMemo(() => {
    const items = ids.map(item => itemsHashStore?.[getStoreKey(item)]).filter(Boolean);
    return items.length === ids.length ? items : null;
  }, [ids, itemsHashStore, getStoreKey]);

  const emptyDataIds = useMemo(
    () => ids.filter(item => !itemsHashStore?.[getStoreKey(item)]),
    [ids, itemsHashStore, getStoreKey]
  );

  useEffect(() => {
    if (!isDERPhaseInProfile || !emptyDataIds.length) return;
    setLoading(true);
    Promise.all(
      emptyDataIds.map(({ portfolioId, scenarioId }) => {
        return dispatch(getGlobalDevelopmentChartData({ portfolioId, scenarioId })).then(action => ({
          [getStoreKey({ portfolioId, scenarioId })]: {
            name: getSeriesName({ portfolioId, scenarioId }),
            data: action.payload.ts_data,
          },
        }));
      })
    )
      .then(results => {
        setItemsHashStore(prev => ({ ...prev, ...results.reduce((acc, item) => ({ ...acc, ...item })) }));
      })
      .finally(() => setLoading(false));
  }, [isDERPhaseInProfile, emptyDataIds, dispatch, getSeriesName, getStoreKey, setLoading]);

  useEffect(() => {
    if (!isDERPhaseInProfile || !seriesItems) return;

    const series = seriesItems.flatMap(item => {
      const seriesHash = item!.data.reduce(
        (acc: Record<string, any>, dataItem) => {
          const elem = [dataItem.year, dataItem.n_ders];
          const description = dataItem.description;
          if (!description) return acc;
          if (!acc[description]) {
            acc[description] = {
              name: `<div>${getIntl(dataItem.description)}</div><small>${item?.name}</small>`,
              data: [elem],
            };
          } else {
            acc[description].data.push(elem);
          }
          return acc;
        },
        {} as Record<string, Shared.SeriesOptionsType>
      );
      return Object.values(seriesHash || {});
    });

    setChartData({ series, title: 'Phase in DERs in scenario', yAxisTitle: 'Number' });
  }, [isDERPhaseInProfile, seriesItems, getIntl]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: { zoomType: 'x' },
      title: { text: getIntl(chartData?.title || '') },
      yAxis: {
        title: { text: getIntl(chartData?.yAxisTitle || '') },
      },
      series: chartData?.series,
      legend: {
        useHTML: true,
      },
    }),
    [chartData, getIntl]
  ) as Highcharts.Options;

  if (!isDERPhaseInProfile) return null;
  return (
    <Chart
      options={chartData ? options : null}
      dataMarker="chart_analysis_der_phase_in_profile"
      height="calc(100vh - 355px)"
    />
  );
};

export default ChartDERPhaseInProfile;
