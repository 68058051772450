import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { SelectYear } from 'components/_common';
import { DataTableFilterKeys, PaginationType } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  paginationType: Type.PaginationType;
  isClearable?: boolean;
  yearFilterConfig?: { range: number[]; isLoading: boolean };
  setValues: (values: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const YearFilter: React.FC<Props> = ({
  inDataTableHeaderFilters = false,
  values,
  paginationType,
  yearFilterConfig = {},
  setValues,
  submitForm,
  ...props
}) => {
  const handleSelectChange = useCallback(
    async (value: Type.SelectOption) => {
      const nextValues = { ...values, [DataTableFilterKeys.YEAR]: value?.value || null };
      if (paginationType === PaginationType.EMPLOYEES) {
        nextValues[DataTableFilterKeys.EMPLOYEES_IDS] = null;
      }
      await setValues(nextValues);
      if (inDataTableHeaderFilters) submitForm();
    },
    [setValues, values, paginationType, inDataTableHeaderFilters, submitForm]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.YEAR)) return null;

  const Select = (
    <SelectYear
      labelKey={inDataTableHeaderFilters ? '' : undefined}
      {...props}
      value={values[DataTableFilterKeys.YEAR] || null}
      {...yearFilterConfig}
      onChange={handleSelectChange}
      variant="small"
      isSearchable={false}
    />
  );

  return inDataTableHeaderFilters ? <Col sm={2}>{Select}</Col> : <Form.Group>{Select}</Form.Group>;
};

export default YearFilter;
