import React, { useState, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { patchAssetModelAction } from 'modules/assetModels';
import { Checkbox } from 'components/_common';

interface Props {
  modelId: number;
  replacementOption: boolean;
}

const ReplacementOption: React.FC<Props> = memo(({ modelId, replacementOption }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleChecklistCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const itemId: number = Number(event.currentTarget.getAttribute('data-index'));
      const { checked: replacementOption } = event.currentTarget;
      dispatch(patchAssetModelAction(itemId, { replacementOption })).finally(() => setLoading(false));
    },
    [dispatch]
  );

  return (
    <Checkbox
      dataIndex={modelId}
      className="icheck-primary d-inline ml-2 position-relative"
      checked={Boolean(replacementOption)}
      onChange={handleChecklistCheckboxClick}
      disabled={loading}
    />
  );
});

export default ReplacementOption;
