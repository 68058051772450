import { Layer, Color, Position } from '@deck.gl/core/typed';
import { MapboxLayer } from '@deck.gl/mapbox/typed';
import { Feature, LineString, Position as GeoJSONPosition } from 'geojson';
import { _throttle } from '@utiligize/shared/utils';
import AnimatedPathLayer from './AnimatedPathLayer';
import { getLayer, paintLayer } from 'utils/map';
import { MapParams } from 'constants/index';

type AnimatedExtraProps = { type: typeof Layer; initOpacity: mapboxgl.Expression };
type AnimatedHandlers = { updateData: () => void; handleZoom: () => void };
type AnimationDataItem = Feature<
  LineString,
  { color: Color; colorString: string; left: boolean; right: boolean; sign: number }
>;
type AnimatedPathLayerExtraProps = AnimatedExtraProps & AnimatedHandlers;
type AnimationLayer = AnimatedPathLayer<AnimationDataItem, AnimatedPathLayerExtraProps>;

const dataEvents = ['moveend', 'zoomend', 'data'] as unknown as (keyof mapboxgl.MapLayerEventType)[];
const inactiveColor = 'rgba(145,143,134,1)';

const getAnimationLayerId = (layerId: string) => `${layerId}__cable_animation`;

const createAnimatedMapboxLayer = (map: Map.MapboxMap, layerId: string) =>
  new MapboxLayer<AnimationLayer>({
    id: getAnimationLayerId(layerId),
    updateData: createAnimationDataUpdater(map, layerId),
    handleZoom: createAnimationZoomHandler(map, layerId, 8.7),
    initOpacity: getInitOpacity(layerId),
    type: AnimatedPathLayer,
    getWidth: 3,
    widthScale: 1,
    widthUnits: 'pixels',
    widthMinPixels: 3,
    capRounded: true,
    jointRounded: true,
    getPath: f => {
      if (f.properties.sign === -1) return f.geometry.coordinates.reverse() as Position[];
      if (f.properties.sign === 0) return [] as Position[];
      return f.geometry.coordinates as Position[];
    },
    getColor: f => f.properties.color,
    fadeTrail: true,
    trailLength: 300,
    animationSpeed: 250,
    enableAnimation: true,
  });

const createAnimationDataUpdater = (map: Map.MapboxMap, layerId: string) =>
  _throttle(
    () => {
      const animationLayerId = getAnimationLayerId(layerId);
      const animationLayer = getAnimationLayer(map, animationLayerId);
      if (!animationLayer) return;
      const renderedFeatures = map.queryRenderedFeatures(undefined, { layers: [layerId] });
      const data = renderedFeaturesToAnimationData(renderedFeatures);
      animationLayer.setProps({ id: animationLayerId, data });
    },
    500,
    { trailing: true }
  );

const createAnimationZoomHandler = (map: Map.MapboxMap, layerId: string, minZoom: number) =>
  _throttle(
    () => {
      const zoom = map.getZoom();
      const layer = getLayer(layerId);
      const layerMinZoom = layer?.style?.minzoom ?? MapParams.maxZoom;
      const isVisible = zoom > Math.max(minZoom, layerMinZoom);
      const animationLayerId = getAnimationLayerId(layerId);
      const animationLayer = getAnimationLayer(map, animationLayerId);
      if (!animationLayer) return;
      const previousVisibleState = animationLayer.props.visible ?? false;
      if (isVisible === previousVisibleState) return;
      const eventAction = (isVisible ? map.on : map.off).bind(map);
      paintLayer(map, layerId, 'line-opacity', isVisible ? 0.5 : animationLayer.props.initOpacity);
      dataEvents.forEach(i => eventAction(i, layerId, animationLayer.props.updateData!));
      animationLayer.setProps({ id: animationLayerId, visible: isVisible });
    },
    500,
    { trailing: true }
  );

const getAnimationLayer = (map: Map.MapboxMap, animationLayerId: string) => {
  if (!Boolean(map.getStyle())) return null;
  const animationMapboxLayer = map.getLayer(animationLayerId) as any;
  const animationLayer = animationMapboxLayer?.implementation as MapboxLayer<AnimationLayer> | null;
  return animationLayer;
};

const renderedFeaturesToAnimationData = (features: mapboxgl.MapboxGeoJSONFeature[]): AnimationDataItem[] => {
  const data = features
    .map(feature => {
      const f = (feature as any).toJSON() as AnimationDataItem;
      const isMultiLine = feature.geometry.type === 'MultiLineString';
      const coordinates = isMultiLine ? f.geometry.coordinates : [f.geometry.coordinates];
      const [color, colorString] = getLineColor(feature);
      const features = coordinates
        .map(c => {
          const feature = { ...f };
          feature.properties.color = color;
          feature.properties.colorString = colorString;
          feature.geometry.coordinates = c as GeoJSONPosition[];
          return feature as AnimationDataItem;
        })
        .filter(f => f.properties.colorString !== inactiveColor);
      return features;
    })
    .flat();

  const groupedById = data.reduce(
    (acc, item) => {
      const key = Number(item.id);
      if (!acc[key]) acc[key] = [];
      acc[key].push(item);
      return acc;
    },
    {} as Record<number, AnimationDataItem[]>
  );

  const merged = Object.values(groupedById)
    .map(items => {
      const itemsCopy = JSON.parse(JSON.stringify(items)) as AnimationDataItem[];
      while (1) {
        const item = itemsCopy.find(i => !i.properties.left || !i.properties.right);
        if (!item) break;
        mergeCoords(item, itemsCopy, 'left');
        mergeCoords(item, itemsCopy, 'right');
      }
      return itemsCopy;
    })
    .flat();

  return merged;
};

const isCoordEqual = (a: GeoJSONPosition, b: GeoJSONPosition) => {
  return a[0] === b[0] && a[1] === b[1];
};

const getLineBounds = (line: AnimationDataItem) => {
  const coords = line.geometry.coordinates;
  const first = coords[0];
  const last = coords[coords.length - 1];
  return { first, last };
};

const mergeCoords = (line: AnimationDataItem, items: AnimationDataItem[], side: 'left' | 'right') => {
  const { first, last } = getLineBounds(line);
  const sideIndex = items.findIndex(i =>
    isCoordEqual(side === 'left' ? first : last, getLineBounds(i)[side === 'left' ? 'last' : 'first'])
  );
  if (sideIndex !== -1) {
    const lineCoords = line.geometry.coordinates;
    const sideCoords = items[sideIndex].geometry.coordinates;
    if (side === 'left') {
      lineCoords.unshift(...sideCoords.slice(0, -1));
    } else {
      lineCoords.push(...sideCoords.slice(1));
    }
    items.splice(sideIndex, 1);
  } else {
    line.properties[side] = true;
  }
};

const getLineColor = (feature: mapboxgl.MapboxGeoJSONFeature): [Color, string] => {
  const paint = feature.layer.paint as any;
  const colorObject = paint?.['line-color'];
  const colorArray = colorObject.toArray();
  const colorString = colorObject.toString();
  colorArray[3] = 255 / colorArray[3];
  return [colorArray, colorString];
};

const getInitOpacity = (layerId: string): mapboxgl.Expression => {
  const paint = getLayer(layerId)?.style?.paint as any;
  const initOpacity = paint?.['line-opacity'] ?? 1;
  return initOpacity;
};

export const startAnimation = (map: Map.MapboxMap, layerId: string) => {
  if (!map.getLayer(layerId)) return stopAnimation(map, layerId);
  if (getAnimationLayer(map, getAnimationLayerId(layerId))) return;

  const animationLayer = createAnimatedMapboxLayer(map, layerId);
  const layers = map.getStyle().layers ?? [];
  const layerIndex = layers.findIndex(i => i.id === layerId);
  const layerAfterIndex = layerIndex > 0 ? layerIndex + 1 : layerIndex;
  const layerAfterId = layers[layerAfterIndex]?.id;
  map.addLayer(animationLayer as unknown as mapboxgl.CustomLayerInterface, layerAfterId);
  map.on('zoomend', animationLayer.props.handleZoom!);
  animationLayer.deck?.setProps({ getCursor: () => map.__cursor! });
  animationLayer.props.handleZoom!();
};

export const stopAnimation = (map: Map.MapboxMap, layerId: string) => {
  const animationLayerId = getAnimationLayerId(layerId);
  const animationLayer = getAnimationLayer(map, animationLayerId);
  if (!animationLayer) return;
  paintLayer(map, layerId, 'line-opacity', animationLayer.props.initOpacity);
  dataEvents.forEach(i => map.off(i, layerId, animationLayer.props.updateData!));
  map.off('zoomend', animationLayer.props.handleZoom!);
  map.removeLayer(animationLayerId);
};
