import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { fetchEmployeesAction } from 'modules/employees';
import { employeesSelector } from 'modules/employees/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  disabled?: boolean;
  isMulti?: true | undefined;
  isClearable?: true | undefined;
  labelKey?: string;
  errorKey?: string;
  ids: number[];
  setIds: (value: number[]) => void;
  onBlur?: () => void;
  useOriginalChangeHandler?: boolean;
}

const SelectEmployee: React.FC<Props> = ({
  disabled = false,
  isMulti,
  labelKey = 'Employees',
  ids = [],
  setIds,
  useOriginalChangeHandler = false,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const employees = useSelector(employeesSelector);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.EMPLOYEES));
  const year = filters?.year;

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchEmployeesAction({ skipFilters: true, skipPagination: true })).then(() => setIsLoading(false));
  }, [dispatch, year]);

  const { options, value } = useMemo(
    () =>
      employees.reduce(
        (acc: any, employee: Employees.Item) => {
          const item = { value: employee.ssoUserId, label: `${employee.fullName} - ${employee.email}` };
          if (ids.includes(employee.ssoUserId)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [ids, employees]
  );

  const handleSelectChange = useCallback(
    (employees: Type.SelectOption[]) => {
      setIds(isMulti ? employees?.map(i => i.value) || [] : [(employees as any)?.value]);
    },
    [isMulti, setIds]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      isDisabled={isLoading || disabled}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={useOriginalChangeHandler ? setIds : handleSelectChange}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholderKey="Select employee"
    />
  );
};

export default SelectEmployee;
